import InventoryIcon from "@mui/icons-material/Inventory";
import GroupsIcon from "@mui/icons-material/Groups";
import CategoryIcon from "@mui/icons-material/Category";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import ProductionQuantityLimitsRoundedIcon from "@mui/icons-material/ProductionQuantityLimitsRounded";
import CreateNewFolderRoundedIcon from "@mui/icons-material/CreateNewFolderRounded";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import DevicesIcon from "@mui/icons-material/Devices";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PeopleIcon from "@mui/icons-material/People";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import WarehouseRoundedIcon from "@mui/icons-material/WarehouseRounded";
import WidgetsIcon from "@mui/icons-material/Widgets";
import MemoryIcon from "@mui/icons-material/Memory";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import PendingIcon from "@mui/icons-material/Pending";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import GroupIcon from "@mui/icons-material/Group";
import AddchartIcon from "@mui/icons-material/Addchart";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import PostAddIcon from "@mui/icons-material/PostAdd";
import RedeemIcon from "@mui/icons-material/Redeem";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import OutboundIcon from "@mui/icons-material/Outbound";
import RecyclingIcon from "@mui/icons-material/Recycling";
import StoreIcon from "@mui/icons-material/Store";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import DeveloperModeOutlinedIcon from "@mui/icons-material/DeveloperModeOutlined";
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import SubtitlesOutlinedIcon from "@mui/icons-material/SubtitlesOutlined";
import GroupWorkOutlinedIcon from "@mui/icons-material/GroupWorkOutlined";
import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import LocalPostOfficeOutlinedIcon from "@mui/icons-material/LocalPostOfficeOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import HistoryIcon from "@mui/icons-material/History";
import DescriptionIcon from "@mui/icons-material/Description";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import { Person, RecyclingRounded } from "@mui/icons-material";
import ReportIcon from "@mui/icons-material/Report";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";

import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import BentoIcon from "@mui/icons-material/Bento";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import MoreIcon from "@mui/icons-material/More";

const SIDE_MENU_DATA = [
  {
    name: "Dashboard",
    path: "/dashboard",
    renderName: [],
    subMenus: [],
    icon: <DashboardRoundedIcon color="primary" fontSize="small" />,
  },
  {
    name: "Roles and Permissions",
    path: "/role-permissions",
    renderName: ["roles and permission"],
    subMenus: [],
    icon: <PersonRoundedIcon color="primary" fontSize="small" />,
  },
  {
    name: "Create User",
    path: "/create-user",
    renderName: ["Create User"],
    subMenus: [],

    icon: <AccountBoxIcon color="primary" fontSize="small" />,
  },
  {
    name: "Inbound Process",
    path: "",
    renderName: ["inbound process"],
    icon: <AddBusinessIcon color="primary" fontSize="small" />,
    subMenus: [
      {
        name: "New Items",
        renderName: "inbound process",
        path: "/inbound-process/item/new",
        icon: (
          <ProductionQuantityLimitsRoundedIcon
            color="primary"
            fontSize="small"
          />
        ),
      },
      {
        name: "Used Items",
        renderName: "inbound process",
        path: "/inbound-process/item/used",
        icon: (
          <ProductionQuantityLimitsRoundedIcon
            color="primary"
            fontSize="small"
          />
        ),
      },
      {
        name: "Faulty Items",
        renderName: "inbound process",
        path: "/inbound-process/item/faulty",
        icon: (
          <ProductionQuantityLimitsRoundedIcon
            color="primary"
            fontSize="small"
          />
        ),
      },
    ],
    // icon: <InventoryIcon color="primary" fontSize="small" />,
  },
  {
    name: "Inventory",
    path: "/inventory",
    renderName: ["inventory"],
    subMenus: [],
    icon: <PlaylistAddCheckCircleIcon color="primary" fontSize="small" />,
  },

  {
    name: "Bulk Update",
    path: "/bulk-update",
    renderName: ["bulk update"],
    subMenus: [],
    icon: <MoreIcon color="primary" fontSize="small" />,
  },
  {
    name: "Bulk Update Entry/Item",
    path: "/bulk-update-entry/item",
    renderName: ["Bulk Update Entry/Item"],
    subMenus: [],
    icon: <DynamicFeedIcon color="primary" fontSize="small" />,
  },

  // {
  //   name: "Shipment",
  //   path: "/shipment",
  //   renderName: ["shipment"],
  //   icon: <LocalShippingIcon color="primary" fontSize="small" />,
  //   subMenus: [],
  // },
  {
    name: "RMA View And Edit",
    path: "/rma",
    renderName: ["item history"],
    subMenus: [],
    icon: <PersonRoundedIcon color="primary" fontSize="small" />,
  },

  {
    name: "All Item History",
    path: "/item-history",
    renderName: ["item history"],
    subMenus: [],
    icon: <HistoryIcon color="primary" fontSize="small" />,
  },

  {
    name: "Master",
    path: "",
    renderName: [
      "employee",
      "warehouse",
      "item description",
      "stock type",
      "order type",
      "item category",
      "storage code",
      "pallet code",
      "brand",
      "condition code",
      "damage code",
      "workflow code",
      "activity code",
      "grading scale",
      "owner code",
      "owner group",
      // "family",
      "sub-family",
      "courier mode",
      "courier partner",
      "packaging",
      "directory",
      "update status",
      "custom fields",
      "item history config",
    ],
    // icon: <AccountBoxIcon color="primary" fontSize="small" />,
    subMenus: [
      {
        name: "Employee",
        path: "/master/employee",
        icon: <GroupIcon color="primary" fontSize="small" />,
        renderName: "employee",
      },
      {
        name: "Warehouse",
        path: "/master/warehouse",
        icon: <WarehouseIcon color="primary" fontSize="small" />,
        renderName: "warehouse",
      },
      {
        name: "Order Type",
        path: "/master/order-type",
        icon: <BentoIcon color="primary" fontSize="small" />,
        renderName: "order type",
      },
      {
        name: "Item Description",
        path: "/master/item-description",
        icon: <DescriptionIcon color="primary" fontSize="small" />,
        renderName: "item description",
      },
      {
        name: "Brand",
        path: "/master/brand",
        icon: <WorkspacePremiumOutlinedIcon color="primary" fontSize="small" />,
        renderName: "brand",
      },
      {
        name: "Stock Type",
        path: "/master/stock-type",
        icon: <WidgetsIcon color="primary" fontSize="small" />,
        renderName: "stock type",
      },

      {
        name: "Storage Code",
        path: "/master/codes/storage",
        icon: <MemoryIcon color="primary" fontSize="small" />,
        renderName: "storage code",
      },
      {
        name: "Pallet Code",
        path: "/master/codes/pallet",
        icon: <ColorLensIcon color="primary" fontSize="small" />,
        renderName: "pallet code",
      },
      {
        name: "Condition Code",
        path: "/master/codes/condition",
        icon: <DeveloperModeOutlinedIcon color="primary" fontSize="small" />,
        renderName: "condition code",
      },
      {
        name: "Damage Code",
        path: "/master/codes/damage",
        icon: <BrokenImageIcon color="primary" fontSize="small" />,
        renderName: "damage code",
      },
      {
        name: "Workflow Code",
        path: "/master/codes/workflow",
        icon: <PendingIcon color="primary" fontSize="small" />,
        renderName: "workflow code",
      },
      {
        name: "Activity Code",
        path: "/master/codes/activity",
        icon: <LocalActivityIcon color="primary" fontSize="small" />,
        renderName: "activity code",
      },
      {
        name: "Grading Scale",
        path: "/master/grading-scale",
        icon: <GradeOutlinedIcon color="primary" fontSize="small" />,
        renderName: "grading scale",
      },

      {
        name: "Owner Group",
        path: "/master/owner-group",
        icon: <GroupWorkOutlinedIcon color="primary" fontSize="small" />,
        renderName: "owner group",
      },
      {
        name: "Owner Code",
        path: "/master/owner-code",
        icon: <SubtitlesOutlinedIcon color="primary" fontSize="small" />,
        renderName: "owner code",
      },

      {
        name: "Sub-family",
        path: "/master/sub-family",
        icon: <GroupIcon color="primary" fontSize="small" />,
        renderName: "sub-family",
      },
      {
        name: "Update Status",
        path: "/master/update-status",
        icon: <EqualizerOutlinedIcon color="primary" fontSize="small" />,
        renderName: "update status",
      },
      {
        name: "Courier Partners",
        path: "/master/courier-partners",
        icon: <Person2OutlinedIcon color="primary" fontSize="small" />,
        renderName: "courier partner",
      },
      {
        name: "Courier Mode",
        path: "/master/courier-mode",
        icon: <LocalPostOfficeOutlinedIcon color="primary" fontSize="small" />,
        renderName: "courier mode",
      },
      {
        name: "Packaging",
        path: "/master/packaging",
        icon: <BusinessCenterOutlinedIcon color="primary" fontSize="small" />,
        renderName: "packaging",
      },
      {
        name: "Directory",
        path: "/master/directory",
        icon: <StoreMallDirectoryIcon color="primary" fontSize="small" />,
        renderName: "directory",
      },

      {
        name: "Item History Config",
        path: "/master/item-history-config",
        icon: <CheckBoxIcon color="primary" fontSize="small" />,
        renderName: "item history config",
      },
    ],
    icon: <InventoryIcon color="primary" fontSize="small" />,
  },
  {
    name: "ON HOLD",
    path: "/freeze-items",
    renderName: ["freeze items"],
    subMenus: [],

    icon: <AcUnitIcon color="primary" fontSize="small" />,
  },
  {
    name: "Bundling",
    path: "",
    renderName: ["bundle type", "bundle", "unbundle"],

    icon: <BusinessCenterIcon color="primary" fontSize="small" />,

    subMenus: [
      {
        name: "Bundle Type",
        path: "/bundling/bundle-type",
        icon: <ListAltOutlinedIcon color="primary" fontSize="small" />,
        renderName: "bundle type",
      },
      {
        name: "Add Bundle",
        path: "/bundling/add",
        icon: <PostAddIcon color="primary" fontSize="small" />,
        renderName: "bundle",
      },
      {
        name: "Bundles",
        path: "/bundling/un-bundle",
        icon: <IndeterminateCheckBoxIcon color="primary" fontSize="small" />,
        renderName: "unbundle",
      },
    ],
    // icon: <InventoryIcon color="primary" fontSize="small" />,
  },

  {
    name: "Outbound Process",
    path: "",
    renderName: ["pick-list", "create-dc", "outbound", "reverse-dc"],
    icon: <OutboundIcon color="primary" fontSize="small" />,
    subMenus: [
      {
        name: "Pick List",
        path: "/outbound/pick-list",
        icon: <GradingOutlinedIcon color="primary" fontSize="small" />,
        renderName: "pick-list",
      },

      {
        name: "Create DC",
        path: "/outbound/create-dc",
        icon: <ReceiptIcon color="primary" fontSize="small" />,
        renderName: "create-dc",
      },

      {
        name: "Outbound",
        path: "/outbound",
        icon: <OutboundIcon color="primary" fontSize="small" />,
        renderName: "outbound",
      },

      {
        name: "Reverse DC",
        path: "/outbound/reverse-dc",
        icon: <ReceiptLongIcon color="primary" fontSize="small" />,
        renderName: "reverse-dc",
      },
    ],
    // icon: <InventoryIcon color="primary" fontSize="small" />,
  },
  {
    name: "Shipment",
    path: "/shipment",
    renderName: ["shipment"],
    icon: <LocalShippingIcon color="primary" fontSize="small" />,
    subMenus: [],
  },

  {
    name: "Reports",
    path: "/reports",
    renderName: ["reports"],
    icon: <ReportIcon color="primary" fontSize="small" />,
    subMenus: [],
  },
  {
    name: "Recyle Bin",
    path: "/recycle-bin",
    renderName: ["recycle bin"],
    icon: <RecyclingRounded color="primary" fontSize="small" />,
    subMenus: [],
  },
];

export default {
  SIDE_MENU_DATA,
};
