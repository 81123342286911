import { Grid } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";

import { get } from "../services/apiMethods";
import URLS from "../services/urlConstants";
import { LoadingButton } from "@mui/lab";
import { downloadExcel } from "../utils/utilities";
import ShowComponent from "../components/ShowComponent";
import WarehouseContext from "../context/WarehouseContext";
import { FileDownload } from "@mui/icons-material";
import { toast } from "react-toastify";
import dayjs from "dayjs";

export default function TotalStockReport() {
  const {
    selectedWarehouse,
    selectedOwnerCode,
    selectedOwnerGroup,
    allWarehouses,
    allOwnerCodes,
  } = useContext(WarehouseContext);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const downloadAllItems = async () => {
    try {
      if (!selectedWarehouse.length)
        return toast.info("Please select a warehouse!");
      setLoading(true);
      const { data } = await get(URLS.items.downloadTotalStockWithHistory, {
        params: {
          warehouses: selectedWarehouse,
          ownerCodes: selectedOwnerCode,
          ownerGroups: selectedOwnerGroup,
        },
        responseType: "blob",
      });
      downloadExcel(
        data,
        `${selectedWarehouse
          ?.map((w) => allWarehouses.find((wh) => wh._id === w)?.code)
          ?.join(",")}_${selectedOwnerCode
          .map((o) => allOwnerCodes.find((oc) => oc._id == o)?.name)
          ?.join(",")}_Inventory Report_${dayjs(new Date(Date.now())).format(
          "DD/MM/YYYY"
        )}`
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleDownloadReportOrderWise = async () => {
    try {
      if (!selectedWarehouse.length)
        return toast.info("Please select a warehouse!");
      setDownloading(true);
      const { data } = await get(
        URLS.items.downloadTotalStockOrderWiseWithHistory,
        {
          params: {
            warehouses: selectedWarehouse,
            ownerCodes: selectedOwnerCode,
            ownerGroups: selectedOwnerGroup,
          },
          responseType: "blob",
        }
      );
      downloadExcel(
        data,
        `${selectedWarehouse
          ?.map((w) => allWarehouses.find((wh) => wh._id === w)?.code)
          ?.join(",")}_${selectedOwnerCode
          .map((o) => allOwnerCodes.find((oc) => oc._id == o)?.name)
          ?.join(",")}_Inventory Report_${dayjs(new Date(Date.now())).format(
          "DD/MM/YYYY"
        )}`
      );
      setDownloading(false);
    } catch (error) {
      console.log(error);
      setDownloading(false);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "flex-start" },
          alignItems: "center",
          flexDirection: { xs: "row", md: "row" },
          flexWrap: "wrap",
          gap: 2,
          m: 1,
        }}
      >
        <ShowComponent module={"Reports"} action={"view"}>
          <LoadingButton
            onClick={downloadAllItems}
            loading={loading}
            variant="contained"
            size="small"
            startIcon={<FileDownload />}
          >
            Download Excel
          </LoadingButton>
        </ShowComponent>
        <ShowComponent module={"Reports"} action={"view"}>
          <LoadingButton
            onClick={handleDownloadReportOrderWise}
            loading={downloading}
            variant="contained"
            size="small"
            startIcon={<FileDownload />}
          >
            Download Excel - Customer
          </LoadingButton>
        </ShowComponent>
      </Grid>
    </>
  );
}
