export const PROPERTIES_MAPPING = {
  Item: [
    { label: "ITEM_CATEGORY", value: "itemCategory" },
    { label: "STOCK_TYPE", value: "stockType" },
    { label: "MSN", value: "msn" },
    { label: "SUB_FAMILY", value: "subFamily" },
    { label: "ITEM_DESCRIPTION", value: "itemDescription" },
    { label: "ITEM_CODE", value: "itemCode" },
    { label: "ACTIVITY_CODE", value: "activityCode" },
    { label: "MANUFACTURER_PART_NUMBER", value: "manufacturerPartNumber" },
    { label: "BRAND_NAME", value: "brandName" },
    { label: "STORAGE_CODE", value: "storageCode" },
    { label: "PALLET_CODE", value: "palletCode" },
    { label: "CONDITION_CODE", value: "conditionCode" },
    { label: "GRADING_SCALE", value: "gradingScale" },
    { label: "PACKAGING", value: "packaging" },
    { label: "CONFIGURATION_OS", value: "configurationOs" },
    { label: "DAMAGE_CODE", value: "damageCode" },
    { label: "REMARKS", value: "remarks" },
    { label: "GMS_REFERENCE_CODE", value: "gmsReferenceCode" },
    { label: "IN_STOCK", value: "inStock" },
    { label: "IN_BUNDLE", value: "inBundle" },
    { label: "IS_SAVED", value: "isSaved" },
    { label: "EXPIRY_DATE", value: "expiryDate" },
  ],
  InboundEntry: [
    { label: "ENTRY_MENU", value: "entryMenu" },
    { label: "INCOMING_DATE", value: "incomingDate" },
    { label: "RECEIVED_DATE", value: "receivedDate" },
    { label: "WAREHOUSE_NAME", value: "warehouseName" },
    { label: "EWAY_BILL_NUMBER", value: "eWayBillNumber" },
    { label: "INCOMING_INVOICE_NUMBER", value: "incomingInvoiceNumber" },
    { label: "DC_NUMBER", value: "dcNumber" },
    { label: "PO_NUMBER", value: "poNumber" },
    { label: "NUMBER_OF_BOXES", value: "numberOfBoxes" },
    { label: "SKU", value: "sku" },
    { label: "OWNER_GROUP", value: "ownerGroup" },
    { label: "OWNER_CODE", value: "ownerCode" },
    { label: "OWNER_CATEGORY", value: "ownerCategory" },
    { label: "WORKFLOW_CODE", value: "workflowCode" },
    { label: "REMARKS", value: "remarks" },
  ],
  Outbound: [
    { label: "PACKAGING", value: "packaging" },
    { label: "COURIER_MODE", value: "courierMode" },
    { label: "ACTUAL_WEIGHT", value: "actualWeight" },
    { label: "VOLUMETRIC_SIZE", value: "volumetricSize" },
    { label: "VOLUMETRIC", value: "volumetricSize" },
    { label: "VOLUMETRIC_WEIGHT", value: "volumetricWeight" },
    { label: "VALUE", value: "value" },
    { label: "DC_NUMBER", value: "dcNo" },
    { label: "COURIER_PARTNER", value: "courierPartner" },
    { label: "AWB_NUMBER", value: "awbNo" },
    { label: "EWAY_BILL_NUMBER", value: "ewayBillNumber" },
    { label: "PINCODE", value: "pincode" },
    { label: "BUNDLED_ITEM", value: "bundledItem" },
    { label: "INDIVIDUAL_ITEM", value: "individualItem" },
    { label: "LATEST_UPDATE_STATUS", value: "latestUpdateStatus" },
    { label: "LATEST_UPDATE_STATUS_DATE", value: "latestUpdateDate" },
    { label: "SHIPPING_REMARKS", value: "shippingRemarks" },
  ],
};

export const GROUP_BY_MAPPINGS = {
  Item: [
    { label: "ITEM_CATEGORY", value: "itemCategory" },
    { label: "STOCK_TYPE", value: "stockType" },
    { label: "ORDER_TYPE", value: "orderType" },
    { label: "SUB_FAMILY", value: "subFamily" },
    { label: "ITEM_DESCRIPTION", value: "itemDescription" },
    { label: "ACTIVITY_CODE", value: "activityCode" },
    { label: "BRAND_NAME", value: "brandName" },
    { label: "STORAGE_CODE", value: "storageCode" },
    { label: "PALLET_CODE", value: "palletCode" },
    { label: "CONDITION_CODE", value: "conditionCode" },
    { label: "GRADING_SCALE", value: "gradingScale" },
    { label: "PACKAGING", value: "packaging" },
    { label: "DAMAGE_CODE", value: "damageCode" },
    { label: "WAREHOUSE", value: "warehouse" },
    { label: "OWNER_CODE", value: "ownerCode" },
    { label: "OWNER_GROUP", value: "ownerGroup" },
    { label: "GMS_REFERENCE_CODE", value: "gmsReferenceCode" },
    { label: "IN_STOCK", value: "inStock" },
    { label: "IN_BUNDLE", value: "inBundle" },
    { label: "IS_SAVED", value: "isSaved" },
    { label: "FREEZED", value: "freezed" },
  ],
  InboundEntry: [
    { label: "ENTRY_MENU", value: "entryMenu" },
    { label: "INBOUND_DATE", value: "inboundDate" },
    { label: "WAREHOUSE_CODE", value: "warehouseCode" },
    { label: "OWNER_GROUP", value: "ownerGroup" },
    { label: "OWNER_CODE", value: "ownerCode" },
    { label: "WORKFLOW_CODE", value: "workflowCode" },
  ],
  Outbound: [
    { label: "PACKAGING", value: "packaging" },
    { label: "DISPATCHED_AT", value: "dispatchedAt" },
    { label: "SHIPPED_DATE", value: "shippedDate" },
    { label: "COURIER_MODE", value: "courierMode" },
    { label: "COURIER_PARTNER", value: "courierPartner" },
    { label: "AWB_NUMBER", value: "awbNo" },
    { label: "PINCODE", value: "pincode" },
    { label: "UPDATE_STATUS", value: "latestUpdateStatus" },
  ],
};

export const GROUP_DATA_MAPPING = [
  { label: "Item", value: "Item" },
  { label: "Inbound Entry", value: "InboundEntry" },
  { label: "Outbound", value: "Outbound" },
];
