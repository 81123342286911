import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import NoTableData from "./NoTableData";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { PuffLoader } from "react-spinners";
import image from "../../assets/images/deleteicon.png";
import ShowComponent from "../ShowComponent";
import { LoadingButton } from "@mui/lab";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { ROWS_PER_PAGE } from "../../utils/utilities";
import CircularProgress from "@mui/material/CircularProgress";
import { Add } from "@mui/icons-material";
import CustomInput from "../SecondaryForms/CustomInput";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  borderRadius: "10px",

  boxShadow: 24,
  p: 2,
};

export function getDateInDDMMYYYY(utcDateString) {
  // Create a Date object from the UTC string
  const date = new Date(utcDateString);

  // Extract day, month, and year
  const day = String(date.getUTCDate()).padStart(2, "0"); // Ensure 2 digits
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1
  const year = date.getUTCFullYear();

  // Return formatted date
  return `${day}/${month}/${year}`;
}

const getDataValue = (data, k) => {
  if (typeof data[k.key] === "boolean") {
    return data[k.key] === true ? "Yes" : "No";
  }
  if (k.path) {
    if (Object.keys(data[k.path] || {}).length > 0) {
      return data[k.path][k.key] || "NA";
    }
  }

  if (k.isDate) {
    return data[k.key]
      ? `${getDateInDDMMYYYY(data[k.key])} ${
          new Date(data[k.key])
            .toLocaleString("en-IN", {
              timeZone: "Asia/Kolkata",
            })
            .split(" ")[1]
        }`
      : "NA";
  } else {
    return data[k.key] || "NA";
  }
};

// const getDataValue = (data, k) => {
//   if (typeof data[k.key] === "boolean") {
//     return data[k.key] === true ? "Yes" : "No";
//   }
//   if (k.path) {
//     if (Object.keys(data[k.path] || {}).length > 0) {
//       return data[k.path][k.key] || "NA";
//     }
//   }

//   if (k.isDate) {
//     if (!data[k.key]) return "NA";

//     const originalDate = new Date(data[k.key]);
//     const utcDay = originalDate.getUTCDate(); // Day in UTC
//     const istDate = new Date(
//       originalDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
//     );
//     const istDay = istDate.getDate(); // Day in IST

//     if (utcDay !== istDay) {
//       // If the day changes due to timezone shift, return the original UTC date-time as-is
//       return originalDate.toISOString().replace("T", " ").slice(0, 19); // e.g., "2025-02-07 20:11:03"
//     } else {
//       // If the day doesn't change, format it to IST
//       return originalDate.toLocaleString("en-IN", {
//         timeZone: "Asia/Kolkata",
//       });
//     }
//   } else {
//     return data[k.key] || "NA";
//   }
// };

export default function CustomTable({
  bodyData = [],
  bodyDataModal = "",
  tableKeys = [],
  actions = ["edit", "delete", "view", "switch", "unbundle", "download"],
  onEditClick = () => {},
  onDeleteClick = () => {},
  onViewClick = () => {},
  onUnBundleClick = () => {},
  onToggleSwitch = () => {},
  onDownload = () => {},
  toggleStatus = false,
  isDataReady = true,
  module = "",
  formFields = [],
  deleteLoading,
  downloadLoading,
  disableDeleteFunction = () => {},
  deleteCanBeDisabled = false,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [reasonForDelete, setReasonForDelete] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleting, setDeleteing] = useState(false);

  const handleDeleteClick = (data) => {
    setSelectedItem(data);
    setOpen(true);
  };

  const handleSubmit = async () => {
    try {
      setDeleteing(true);
      if (!reasonForDelete) {
        toast.error("Reason for deletion is required field");
        setDeleteing(false);
        return;
      }
      await onDeleteClick(selectedItem._id, reasonForDelete);
      setOpen(false);
      setReasonForDelete("");
      setDeleteing(false);
    } catch (error) {
      console.log(error);
      setDeleteing(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setReasonForDelete("");
  };

  useEffect(() => {
    setPage(0);
  }, [bodyData]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.primary.dark
                  : theme.palette.primary.light,
            }}
          >
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              {tableKeys.map((r, i) => (
                <TableCell key={i} align="center">
                  {r.name}
                </TableCell>
              ))}
              {formFields?.length
                ? formFields?.map((f, index) => (
                    <TableCell
                      key={f.inputLabel + index.toString()}
                      align="center"
                    >
                      {f.inputLabel}
                    </TableCell>
                  ))
                : null}
              {actions.length ? (
                <TableCell align="center">Action</TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {bodyData.length && isDataReady
              ? bodyData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => (
                    <TableRow key={data._id}>
                      <TableCell align="center">
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      {tableKeys.map((k, i) => (
                        <TableCell key={i} align="center">
                          {getDataValue(data, k)}
                        </TableCell>
                      ))}

                      {formFields?.length
                        ? formFields?.map((f, index) => (
                            <TableCell
                              key={f.inputLabel + index.toString()}
                              align="center"
                            >
                              {data.meta?.find(
                                (fi) => fi.inputLabel === f.inputLabel
                              )?.value || ""}
                            </TableCell>
                          ))
                        : null}
                      <TableCell align="center">
                        {actions.includes("edit") && (
                          <ShowComponent module={module} action={"update"}>
                            <Tooltip title="Edit">
                              <IconButton onClick={() => onEditClick(data)}>
                                <EditIcon color="primary" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>
                        )}

                        {actions.includes("view") && (
                          <ShowComponent module={module} action={"view"}>
                            <Tooltip title="View">
                              <IconButton onClick={() => onViewClick(data)}>
                                <RemoveRedEyeRoundedIcon
                                  fontSize="small"
                                  color="primary"
                                />
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>
                        )}

                        {actions.includes("delete") && (
                          <ShowComponent module={module} action={"delete"}>
                            <Tooltip title="Delete">
                              <IconButton
                                disabled={
                                  deleteCanBeDisabled &&
                                  !disableDeleteFunction(data)
                                }
                                onClick={() => handleDeleteClick(data)}
                              >
                                <DeleteIcon
                                  fontSize="small"
                                  color={
                                    deleteCanBeDisabled &&
                                    !disableDeleteFunction(data)
                                      ? "disabled"
                                      : "error"
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>
                        )}

                        {actions.includes("download") && (
                          <ShowComponent module={module} action={"view"}>
                            <Tooltip title="Download">
                              <IconButton
                                onClick={() => onDownload(data)}
                                disabled={!!downloadLoading[data._id]}
                              >
                                {downloadLoading[data._id] ? (
                                  <CircularProgress size={20} />
                                ) : (
                                  <DownloadRoundedIcon fontSize="small" />
                                )}
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>
                        )}

                        {actions.includes("unbundle") && (
                          <ShowComponent module={module} action={"delete"}>
                            <Tooltip title="Unbundle">
                              <Button
                                size="small"
                                variant="contained"
                                onClick={() => onUnBundleClick(data)}
                              >
                                UnBundle
                              </Button>
                            </Tooltip>
                          </ShowComponent>
                        )}
                        {actions.includes("switch") && (
                          <ShowComponent module={module} action={"update"}>
                            <Tooltip title="Active Status">
                              <IconButton size="small">
                                <Switch
                                  checked={data.active || false}
                                  onChange={() => onToggleSwitch(data)}
                                />
                              </IconButton>
                            </Tooltip>
                          </ShowComponent>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
              : null}
          </TableBody>
        </Table>
        {!isDataReady ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "20px",
            }}
          >
            <PuffLoader size={30} color="#14532d" />
          </Box>
        ) : null}
        {isDataReady ? (
          <NoTableData dataPresent={bodyData.length} title={bodyDataModal} />
        ) : null}
        <TablePagination
          component="div"
          count={bodyData ? (bodyData.length ? bodyData.length : 0) : 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE}
        />
      </TableContainer>

      {/* === Delete modal === */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box sx={{ textAlign: "center", margin: "10px auto" }}>
            <img src={image} width={50} height={50} />
          </Box>

          <Typography
            textAlign="center"
            sx={{ fontSize: "18px", fontWeight: 700 }}
          >
            Delete Confirmation
          </Typography>
          <Typography sx={{ my: 2, textAlign: "center" }}>
            Proceeding with the deletion of this data may result in information
            inconsistency across other documents and is irreversible. Are you
            certain you wish to delete this item?
          </Typography>

          <CustomInput
            name="reason"
            value={reasonForDelete}
            onChange={(e) => setReasonForDelete(e.target.value)}
            label="Reason For Deletion"
            multiline
            required={true}
            rows={3}
          />

          <Box
            mt={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <Button
              size="small"
              color="error"
              variant="contained"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <LoadingButton
              color="primary"
              size="small"
              variant="contained"
              onClick={handleSubmit}
              loading={deleting}
            >
              Delete
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

// import {
//   Box,
//   Button,
//   IconButton,
//   Modal,
//   Paper,
//   Switch,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TablePagination,
//   TableRow,
//   Tooltip,
//   Typography,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import NoTableData from "./NoTableData";
// import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
// import { PuffLoader } from "react-spinners";
// import image from "../../assets/images/deleteicon.png";
// import ShowComponent from "../ShowComponent";
// import { LoadingButton } from "@mui/lab";
// import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
// import { ROWS_PER_PAGE } from "../../utils/utilities";
// import CircularProgress from "@mui/material/CircularProgress";
// import CustomInput from "../SecondaryForms/CustomInput";
// import { toast } from "react-toastify";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 350,
//   bgcolor: "background.paper",
//   borderRadius: "10px",
//   boxShadow: 24,
//   p: 2,
// };

// const getDataValue = (data, k) => {
//   if (typeof data[k.key] === "boolean") {
//     return data[k.key] === true ? "Yes" : "No";
//   }
//   if (k.path) {
//     if (Object.keys(data[k.path] || {}).length > 0) {
//       return data[k.path][k.key] || "NA";
//     }
//   }
//   if (k.isDate) {
//     return data[k.key]
//       ? new Date(data[k.key]).toLocaleString("en-IN", {
//           timeZone: "Asia/Kolkata",
//         })
//       : "NA";
//   } else {
//     return data[k.key] || "NA";
//   }
// };

// export default function CustomTable({
//   bodyData = [],
//   bodyDataModal = "",
//   tableKeys = [],
//   actions = ["edit", "delete", "view", "switch", "unbundle", "download"],
//   onEditClick = () => {},
//   onDeleteClick = () => {},
//   onViewClick = () => {},
//   onUnBundleClick = () => {},
//   onToggleSwitch = () => {},
//   onDownload = () => {},
//   toggleStatus = false,
//   isDataReady = true,
//   module = "",
//   formFields = [],
//   deleteLoading,
//   downloadLoading,
//   disableDeleteFunction = () => {},
//   deleteCanBeDisabled = false,
// }) {
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(10);
//   const [reasonForDelete, setReasonForDelete] = useState("");
//   const [open, setOpen] = useState(false);
//   const [selectedItem, setSelectedItem] = useState(null);
//   const [deleting, setDeleteing] = useState(false);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleDeleteClick = (data) => {
//     setSelectedItem(data);
//     setOpen(true);
//   };

//   const handleSubmit = async () => {
//     try {
//       setDeleteing(true);
//       if (!reasonForDelete) {
//         toast.error("Reason for deletion is required field");
//         setDeleteing(false);
//         return;
//       }
//       await onDeleteClick(selectedItem._id, reasonForDelete);
//       setOpen(false);
//       setReasonForDelete("");
//       setDeleteing(false);
//     } catch (error) {
//       console.log(error);
//       setDeleteing(false);
//     }
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setReasonForDelete("");
//   };

//   useEffect(() => {
//     setPage(0);
//   }, [bodyData]);

//   return (
//     <>
//       <TableContainer
//         component={Paper}
//         sx={{
//           border: "1px solid #d3d3d3", // Excel-like outer border
//           boxShadow: "none", // Remove shadow for a flatter look
//         }}
//       >
//         <Table
//           sx={{
//             borderCollapse: "collapse", // Ensure borders don't overlap
//             "& .MuiTableCell-root": {
//               border: "1px solid rgba(211, 211, 211, 0.27)", // Grid-like borders for all cells
//               padding: "8px", // Reduced padding for compact look
//               fontSize: "14px", // Consistent font size like Excel
//               fontFamily: "Calibri, Arial, sans-serif", // Excel-like font
//             },
//           }}
//         >
//           <TableHead
//             sx={{
//               backgroundColor: (theme) =>
//                 theme.palette.mode === "dark"
//                   ? theme.palette.primary.dark
//                   : theme.palette.primary.light,
//             }}
//           >
//             <TableRow>
//               <TableCell align="center">S.No</TableCell>
//               {tableKeys.map((r, i) => (
//                 <TableCell key={i} align="center">
//                   {r.name}
//                 </TableCell>
//               ))}
//               {formFields?.length
//                 ? formFields?.map((f, index) => (
//                     <TableCell
//                       key={f.inputLabel + index.toString()}
//                       align="center"
//                     >
//                       {f.inputLabel}
//                     </TableCell>
//                   ))
//                 : null}
//               {actions.length ? (
//                 <TableCell align="center">Action</TableCell>
//               ) : null}
//             </TableRow>
//           </TableHead>
//           <TableBody
//             sx={{
//               "& .MuiTableRow-root:hover": {
//                 backgroundColor: "#f5f5f5", // Light hover effect like Excel
//               },
//             }}
//           >
//             {bodyData.length && isDataReady
//               ? bodyData
//                   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                   .map((data, index) => (
//                     <TableRow key={data._id}>
//                       <TableCell align="center">
//                         {page * rowsPerPage + index + 1}
//                       </TableCell>
//                       {tableKeys.map((k, i) => (
//                         <TableCell key={i} align="center">
//                           {getDataValue(data, k)}
//                         </TableCell>
//                       ))}
//                       {formFields?.length
//                         ? formFields?.map((f, index) => (
//                             <TableCell
//                               key={f.inputLabel + index.toString()}
//                               align="center"
//                             >
//                               {data.meta?.find(
//                                 (fi) => fi.inputLabel === f.inputLabel
//                               )?.value || ""}
//                             </TableCell>
//                           ))
//                         : null}
//                       <TableCell align="center">
//                         {actions.includes("edit") && (
//                           <ShowComponent module={module} action={"update"}>
//                             <Tooltip title="Edit">
//                               <IconButton onClick={() => onEditClick(data)}>
//                                 <EditIcon color="primary" fontSize="small" />
//                               </IconButton>
//                             </Tooltip>
//                           </ShowComponent>
//                         )}
//                         {actions.includes("view") && (
//                           <ShowComponent module={module} action={"view"}>
//                             <Tooltip title="View">
//                               <IconButton onClick={() => onViewClick(data)}>
//                                 <RemoveRedEyeRoundedIcon
//                                   fontSize="small"
//                                   color="primary"
//                                 />
//                               </IconButton>
//                             </Tooltip>
//                           </ShowComponent>
//                         )}
//                         {actions.includes("delete") && (
//                           <ShowComponent module={module} action={"delete"}>
//                             <Tooltip title="Delete">
//                               <IconButton
//                                 disabled={
//                                   deleteCanBeDisabled &&
//                                   !disableDeleteFunction(data)
//                                 }
//                                 onClick={() => handleDeleteClick(data)}
//                               >
//                                 <DeleteIcon
//                                   fontSize="small"
//                                   color={
//                                     deleteCanBeDisabled &&
//                                     !disableDeleteFunction(data)
//                                       ? "disabled"
//                                       : "error"
//                                   }
//                                 />
//                               </IconButton>
//                             </Tooltip>
//                           </ShowComponent>
//                         )}
//                         {actions.includes("download") && (
//                           <ShowComponent module={module} action={"view"}>
//                             <Tooltip title="Download">
//                               <IconButton
//                                 onClick={() => onDownload(data)}
//                                 disabled={!!downloadLoading[data._id]}
//                               >
//                                 {downloadLoading[data._id] ? (
//                                   <CircularProgress size={20} />
//                                 ) : (
//                                   <DownloadRoundedIcon fontSize="small" />
//                                 )}
//                               </IconButton>
//                             </Tooltip>
//                           </ShowComponent>
//                         )}
//                         {actions.includes("unbundle") && (
//                           <ShowComponent module={module} action={"delete"}>
//                             <Tooltip title="Unbundle">
//                               <Button
//                                 size="small"
//                                 variant="contained"
//                                 onClick={() => onUnBundleClick(data)}
//                               >
//                                 UnBundle
//                               </Button>
//                             </Tooltip>
//                           </ShowComponent>
//                         )}
//                         {actions.includes("switch") && (
//                           <ShowComponent module={module} action={"update"}>
//                             <Tooltip title="Active Status">
//                               <IconButton size="small">
//                                 <Switch
//                                   checked={data.active || false}
//                                   onChange={() => onToggleSwitch(data)}
//                                 />
//                               </IconButton>
//                             </Tooltip>
//                           </ShowComponent>
//                         )}
//                       </TableCell>
//                     </TableRow>
//                   ))
//               : null}
//           </TableBody>
//         </Table>
//         {!isDataReady ? (
//           <Box
//             sx={{
//               width: "100%",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               paddingTop: "20px",
//             }}
//           >
//             <PuffLoader size={30} color="#14532d" />
//           </Box>
//         ) : null}
//         {isDataReady ? (
//           <NoTableData dataPresent={bodyData.length} title={bodyDataModal} />
//         ) : null}
//         <TablePagination
//           component="div"
//           count={bodyData ? (bodyData.length ? bodyData.length : 0) : 0}
//           page={page}
//           onPageChange={handleChangePage}
//           rowsPerPage={rowsPerPage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//           rowsPerPageOptions={ROWS_PER_PAGE}
//           sx={{
//             borderTop: "1px solid #d3d3d3", // Border above pagination
//             "& .MuiTablePagination-toolbar": {
//               fontFamily: "Calibri, Arial, sans-serif",
//               fontSize: "14px",
//             },
//           }}
//         />
//       </TableContainer>

//       {/* === Delete modal === */}
//       <Modal open={open} onClose={handleClose}>
//         <Box sx={style}>
//           <Box sx={{ textAlign: "center", margin: "10px auto" }}>
//             <img src={image} width={50} height={50} />
//           </Box>
//           <Typography
//             textAlign="center"
//             sx={{ fontSize: "18px", fontWeight: 700 }}
//           >
//             Delete Confirmation
//           </Typography>
//           <Typography sx={{ my: 2, textAlign: "center" }}>
//             Proceeding with the deletion of this data may result in information
//             inconsistency across other documents and is irreversible. Are you
//             certain you wish to delete this item?
//           </Typography>
//           <CustomInput
//             name="reason"
//             value={reasonForDelete}
//             onChange={(e) => setReasonForDelete(e.target.value)}
//             label="Reason For Deletion"
//             multiline
//             required={true}
//             rows={3}
//           />
//           <Box
//             mt={3}
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               gap: "15px",
//             }}
//           >
//             <Button
//               size="small"
//               color="error"
//               variant="contained"
//               onClick={handleClose}
//             >
//               Cancel
//             </Button>
//             <LoadingButton
//               color="primary"
//               size="small"
//               variant="contained"
//               onClick={handleSubmit}
//               loading={deleting}
//             >
//               Delete
//             </LoadingButton>
//           </Box>
//         </Box>
//       </Modal>
//     </>
//   );
// }
