import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import image from "../assets/images/deleteicon.png";
import { LoadingButton } from "@mui/lab";
import CustomInput from "./SecondaryForms/CustomInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  borderRadius: "10px",

  boxShadow: 24,
  p: 2,
};

export default function DeleteModal({
  open = false,
  onDeleteClick = () => {},
  onClose = () => {},
  description = "Are you sure you want to delete this item?",
  isLoading = false,
  reasonForDelete,
  setReasonForDelete = () => {},
}) {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Box sx={{ textAlign: "center", margin: "10px auto" }}>
          <img src={image} width={50} height={50} />
        </Box>

        <Typography
          textAlign="center"
          sx={{ fontSize: "18px", fontWeight: 700 }}
        >
          Delete Confirmation
        </Typography>
        <Typography sx={{ my: 2, textAlign: "center" }}>
          {description}
        </Typography>
        <CustomInput
          name="reason"
          value={reasonForDelete}
          onChange={(e) => setReasonForDelete(e.target.value)}
          label="Reason For Deletion"
          multiline
          rows={3}
          required={true}
        />

        <Box
          mt={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Button
            size="small"
            color="error"
            variant="contained"
            onClick={onClose}
          >
            Cancel
          </Button>
          <LoadingButton
            color="primary"
            size="small"
            variant="contained"
            onClick={onDeleteClick}
            loading={isLoading}
          >
            Delete
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}
