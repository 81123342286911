import React, { useContext, useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import FormDialog from "../../forms/FormDialog";
import { Grid, Stack } from "@mui/material";
import { useFormik } from "formik";
import FormSelect from "../../forms/FormSelect";
import FormInput from "../../forms/FormInput";
import WarehouseContext from "../../context/WarehouseContext";
import { del, get, post, put } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";
import DashBoardPageHeaderPage from "../../components/DashBoard/DashBoardHeaderPage";
import TabList from "../../components/Tabs/TabList";
import TabPanel from "../../components/Tabs/TabPanel";
import { directoryKeys } from "../../data/TableData/directoryKeys";
import CustomTable from "../../components/Tables/CustomTable";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";
import { addressKeys } from "../../data/TableData/addressKeys";

export default function Directory() {
  const { allWarehouses, selectedWarehouse, selectedOwnerGroup } =
    useContext(WarehouseContext);
  const [value, setSelectValue] = useState(0);

  const [isReady, setIsReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [data, setData] = useState([]);
  const [formFields, setFormFields] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ownerCodes, setOwnerCodes] = useState([]);

  // add adresses
  const [addresses, setAddresses] = useState([]);
  const [openAddAddressModal, setOpenAddAddressModal] = useState(false);
  const [directory, setDirectory] = useState(null);
  const [addressToEdit, setAddressToEdit] = useState(null);
  const [addressLoading, setAddressLoading] = useState(false);
  const [selectedOwnerCode, setSelectedOwnerCode] = useState("");
  const [directories, setDirectories] = useState([]);

  useEffect(() => {
    if (selectedOwnerCode) {
      setDirectories(
        data
          .filter((d) => d.ownerCode?._id === selectedOwnerCode)
          ?.map((d) => ({ ...d, label: d.name, value: d._id }))
      );
    }
  }, [selectedOwnerCode, data]);

  useEffect(() => {
    setAddresses(directories.find((d) => d._id === directory)?.addresses || []);
  }, [directory, directories]);

  const handleTabChange = (e, newValue) => {
    setSelectValue(newValue);
  };

  const getOwnerCodes = async () => {
    try {
      const { data } = await get(URLS.ownerCode.getAllOwnerCodes, {
        params: {
          warehouses: selectedWarehouse,
          ownerGroups: selectedOwnerGroup,
        },
      });

      setOwnerCodes(
        data.result?.map((d) => ({
          label: `${d.name}-${d.ownerGroup?.name}-${d.ownerGroup?.warehouse?.code}`,
          value: d._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    try {
      const { data } = await get(URLS.directory.list, {
        params: {
          ownerCode: { $in: ownerCodes.map((o) => o.value) },
        },
      });

      setData(
        data.result?.map((d) => ({
          ...d,
          ownerCodeName: `${d.ownerCode.name}-${d.ownerCode?.ownerGroup?.name}-${d.ownerCode?.ownerGroup?.warehouse?.code}`,
          totalAddresses: d.addresses?.length?.toString(),
        }))
      );
    } catch (error) {
      console.log(error);
    }
    setTimeout(() => {
      setIsReady(true);
    }, 300);
  };

  useEffect(() => {
    getOwnerCodes();
  }, [selectedWarehouse, selectedOwnerGroup]);

  useEffect(() => {
    getData();
  }, [ownerCodes]);

  // create || update actions
  const handleCreateOrUpdate = async (values) => {
    try {
      const payload = {
        ...values,
      };

      setLoading(true);
      if (dataToEdit) {
        const { data } = await put(
          URLS.directory.update + "/" + dataToEdit._id,
          payload
        );
      } else {
        const { data } = await post(URLS.directory.create, payload);
      }

      getData();
      handleModalClose();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      ownerCode: dataToEdit?.ownerCode?._id || "",
      name: dataToEdit?.name || "",
    },
    onSubmit: handleCreateOrUpdate,
    enableReinitialize: true,
  });

  // create || update actions
  const handleCreateOrUpdateAddress = async (values) => {
    try {
      const payload = {
        ...values,
      };

      setLoading(true);
      if (addressToEdit) {
        const { data } = await put(
          URLS.directory.updateAddress + "/" + directory,
          { ...payload, _id: addressToEdit._id }
        );
      } else {
        const { data } = await post(URLS.directory.addAddress, {
          ...payload,
          directoryId: directory,
        });
      }

      getData();
      handleModalClose();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const addressFormik = useFormik({
    initialValues: {
      name: addressToEdit?.name || "",
      pinCode: addressToEdit?.pinCode || "",
      address: addressToEdit?.address || "",
      contactNumber: addressToEdit?.contactNumber || "",
    },
    onSubmit: handleCreateOrUpdateAddress,
    enableReinitialize: true,
  });

  // modal actions
  const openDataAddOrUpdateModal = () => {
    if (value === 1) {
      setOpenAddAddressModal(true);
    } else {
      setOpen(true);
    }
  };

  const handleModalClose = () => {
    if (value === 0) {
      setOpen(false);
      setTimeout(() => {
        setDataToEdit(null);
        formik.resetForm();
      }, 200);
    } else {
      setOpenAddAddressModal(false);
      setTimeout(() => {
        setAddressToEdit(null);
        addressFormik.resetForm();
      }, 200);
    }
  };

  const handleSetDataToEdit = (data) => {
    if (value === 0) {
      setDataToEdit(data);
      setOpen(true);
    } else {
      setAddressToEdit(data);
      setOpenAddAddressModal(true);
    }
  };

  const handleDelete = async (id, reason) => {
    try {
      const res = await del(URLS.directory.deleteDirectory + "/" + id, {
        params: {
          reasonForDelete: reason,
        },
      });
      getData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveAddress = async (id, reason) => {
    try {
      const res = await del(URLS.directory.removeAddress + "/" + directory, {
        params: {
          addressId: id,
          reasonForDelete: reason,
        },
      });
      getData();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <DashBoardPageHeaderPage title="Address Directory" />
      <PageHeader
        module="Directory"
        modelName="Directory"
        showAdd={
          value === 1 ? (selectedOwnerCode && directory ? true : false) : true
        }
        showAddField={false}
        title={value === 1 ? "Add Addresses" : "Address Directory"}
        onAddClick={openDataAddOrUpdateModal}
      />
      <TabList
        onChange={handleTabChange}
        value={value}
        labels={["Directories", "Add Addresses"]}
      />

      <TabPanel index={0} value={value}>
        <CustomTable
          actions={["edit", "delete"]}
          bodyDataModal="directory"
          isDataReady={isReady}
          bodyData={data}
          tableKeys={directoryKeys}
          onDeleteClick={handleDelete}
          onEditClick={handleSetDataToEdit}
          module="Directory"
          formFields={[]}
        />
      </TabPanel>
      <TabPanel index={1} value={value}>
        <Grid
          container
          spacing={2}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          mb={1}
        >
          <Grid item xs={12} md={3}>
            <CustomSelect
              options={ownerCodes}
              value={selectedOwnerCode}
              label="Select Owner Code"
              name="selectedOwnerCode"
              onChange={(e) => setSelectedOwnerCode(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <CustomSelect
              options={directories}
              value={directory}
              label="Select Diectory Name"
              name="directoryName"
              onChange={(e) => setDirectory(e.target.value)}
            />
          </Grid>
        </Grid>

        <CustomTable
          actions={["edit", "delete"]}
          bodyDataModal="directory"
          isDataReady={isReady}
          bodyData={addresses}
          tableKeys={addressKeys}
          onDeleteClick={handleRemoveAddress}
          onEditClick={handleSetDataToEdit}
          module="Directory"
          formFields={[]}
        />
      </TabPanel>

      {/* Directory */}
      <FormDialog
        open={open}
        onClose={handleModalClose}
        submitButtonTitle={dataToEdit ? "Update" : "Add"}
        formik={formik}
        formTitle={dataToEdit ? "Update Directory" : "Add Directory"}
        adding={loading}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <FormSelect
              required={true}
              name="ownerCode"
              formik={formik}
              label="Owner Code"
              options={ownerCodes}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="name"
              type="text"
              formik={formik}
              label="Name"
            />
          </Grid>
        </Grid>
      </FormDialog>

      {/* Address */}
      <FormDialog
        open={openAddAddressModal}
        onClose={handleModalClose}
        submitButtonTitle={addressToEdit ? "Update" : "Add"}
        formik={addressFormik}
        formTitle={addressToEdit ? "Update Address" : "Add Address"}
        adding={addressLoading}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="name"
              formik={addressFormik}
              label="Name"
              type="text"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="pinCode"
              type="text"
              formik={addressFormik}
              label="Pin Code"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="contactNumber"
              type="text"
              formik={addressFormik}
              label="Contact Number"
              multipleContactNumbers={true}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormInput
              required={true}
              name="address"
              type="text"
              formik={addressFormik}
              label="Address"
              multiline={true}
              rows={3}
            />
          </Grid>
        </Grid>
      </FormDialog>
    </>
  );
}
