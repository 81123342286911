import React, { useContext, useEffect, useState } from "react";
import DashBoardPageHeaderPage from "../components/DashBoard/DashBoardHeaderPage";
import {
  Stack,
  Table,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  TableRow,
  Typography,
  TablePagination,
  Box,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  Button,
  Divider,
  Tooltip,
  DialogContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { get, put, post } from "../services/apiMethods";
import URLS from "../services/urlConstants";
import WarehouseContext from "../context/WarehouseContext";
import { downloadExcel, hasAllValues, ROWS_PER_PAGE } from "../utils/utilities";
import { Download } from "@mui/icons-material";
import CustomSelectVirtualized from "../components/SecondaryForms/CustomSelectVirtualized";
import dayjs from "dayjs";
import CustomSelect from "../components/SecondaryForms/CustomSelect";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomInput from "../components/SecondaryForms/CustomInput";
import ShowComponent from "../components/ShowComponent";
import ViewOrUpdateItemFiles from "../components/ViewOrUpdateItemFiles";
import { PuffLoader } from "react-spinners";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { Close } from "@mui/icons-material";
import { toast } from "react-toastify";

const Value = styled(Typography)(() => ({
  fontSize: "14px",
}));

const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    width: "600px",
    maxWidth: "100%",
    color: "#000",
  },
});

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "200px",
}));

const getModifiedStyles = (isModified) => ({
  fontWeight: isModified ? "bold" : "",
  fontSize: isModified ? "12px" : "",
  backgroundColor: isModified ? "#8c8a50" : "",
  color: isModified ? "white" : "",
});

export default function ItemHistory() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { selectedWarehouse, selectedOwnerCode, selectedOwnerGroup } =
    useContext(WarehouseContext);
  const [msn, setMsn] = useState("");
  const [allMsns, setAllMsns] = useState([]);
  const [formData, setFormData] = useState([]);
  const [itemFormFields, setItemFormFields] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const [stockTypes, setStockTypes] = useState([]);
  const [subFamilies, setSubFamilies] = useState([]);
  const [storageCodes, setStorageCodes] = useState([]);
  const [palletCodes, setPalletCodes] = useState([]);
  const [currentConditionCodes, setCurrentConditionCodes] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [damageCode, setDamageCode] = useState([]);
  const [activityCodes, setActivityCodes] = useState([]);
  const [packaging, setPackaging] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itemDescription, setItemDescription] = useState([]);
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [isModifiedImage, setIsModifiedImage] = useState();
  const [orderTypeData, setOrderTypeData] = useState([]);
  const theme = useTheme();
  const [itemWarehose, setItemWarehouse] = useState(null);
  let fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleOpen = (images) => {
    setImages(images.value);
    setIsModifiedImage(images?.isModified);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getItemFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.list, {
        params: {
          collectionName: "item",
          warehouse: itemWarehose,
        },
      });
      setItemFormFields(data.result ? data.result[0]?.fields : []);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMsnChange = (e) => {
    setMsn(e.target.value);
  };

  const handleFindHistory = async (e) => {
    if (e) {
      e.preventDefault();
    }
    try {
      setLoading(true);
      const { data } = await get(URLS.itemHistory.read + "/" + msn);

      setFormData(data.result.detailedHistory || []);

      if (data.result) {
        const newUpdateData = data.result.itemDetails;

        if (newUpdateData) {
          setUpdateData({
            _id: newUpdateData?._id,
            warehouse: newUpdateData.warehouse?._id,
            expiryDate: newUpdateData.expiryDate,
            stockType: newUpdateData.stockType?._id,
            orderType: newUpdateData.orderType?._id,
            msn: newUpdateData.msn,
            subFamily: newUpdateData.subFamily?._id,
            itemDescription: newUpdateData.itemDescription,
            itemCode: newUpdateData.itemCode,
            manufacturerPartNumber: newUpdateData.manufacturerPartNumber,
            brandName: newUpdateData.brandName?._id,
            storageCode: newUpdateData.storageCode?._id,
            palletCode: newUpdateData.palletCode?._id,
            conditionCode: newUpdateData.conditionCode?._id,
            damageCode: newUpdateData.damageCode
              ? newUpdateData.damageCode?._id
              : null,
            activityCode: newUpdateData.activityCode
              ? newUpdateData.activityCode?._id
              : null,
            packaging: newUpdateData.packaging
              ? newUpdateData.packaging?._id
              : null,
            configurationOs: newUpdateData.configurationOs,
            warehouseRemarks: newUpdateData.warehouseRemarks,
            oemCaseNumber: newUpdateData.oemCaseNumber,
            rmaActivityWhRemarks1: newUpdateData.rmaActivityWhRemarks1,
            rmaActivityWhRemarks2: newUpdateData.rmaActivityWhRemarks2,

            oemSupportRemarks: newUpdateData.oemSupportRemarks,
            images: newUpdateData.images,
            meta: itemFormFields?.map((f) => ({
              ...f,
              value:
                newUpdateData.meta?.find((fi) => fi.inputLabel === f.inputLabel)
                  ?.value || "",
            })),
          });
          setItemWarehouse(newUpdateData.warehouse?._id);
        }
      } else {
        setUpdateData(null);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (msn) {
      handleFindHistory();
    }
  }, [msn, itemFormFields]);

  const getData = async () => {
    try {
      const stockPromise = get(URLS.stockType.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const subFamilyPromise = get(URLS.subFamily.list, {
        params: { warehouse: { $in: selectedWarehouse } },
      });
      const itemDescriptionPromise = get(
        URLS.itemDescription.getItemDescription,
        {
          params: { search: { warehouse: { $in: selectedWarehouse } } },
        }
      );
      const brandNamePromise = get(URLS.brand.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const storageCodePromise = get(URLS.storageCode.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const palletCodeCodePromise = get(URLS.palletCode.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const activityCodePromise = get(URLS.currentActivityCode.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const conditionCodePromise = get(URLS.conditionCode.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const damageCodePromise = get(URLS.damageCode.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const packagingPromise = get(URLS.packaging.list, {
        params: { warehouse: { $in: selectedWarehouse } },
      });

      const orderTypeDataPromise = get(URLS.orderType.list, {
        params: { warehouse: { $in: selectedWarehouse } },
      });

      const [
        stockTypeData,
        subFamilyData,
        itemDescriptionData,
        brandData,
        storageCodeData,
        palletCodeData,
        activityCodeData,
        conditionCodeData,
        damageCodeData,
        packagingData,
        orderTypeData,
      ] = await Promise.all([
        stockPromise,
        subFamilyPromise,
        itemDescriptionPromise,
        brandNamePromise,
        storageCodePromise,
        palletCodeCodePromise,
        activityCodePromise,
        conditionCodePromise,
        damageCodePromise,
        packagingPromise,
        orderTypeDataPromise,
      ]);

      setStockTypes(
        stockTypeData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );

      setSubFamilies(
        subFamilyData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setItemDescription(
        itemDescriptionData.data.result.map((s) => ({
          ...s,
          label: s.description,
          value: s.description,
        }))
      );
      setBrandData(
        brandData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setStorageCodes(
        storageCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setPalletCodes(
        palletCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setActivityCodes(
        activityCodeData.data.result.map((s) => ({
          ...s,
          label: `${s.code}-${s.description}`,
          value: s._id,
        }))
      );
      setCurrentConditionCodes(
        conditionCodeData.data.result.map((s) => ({
          ...s,
          label: `${s.code}-${s.description}`,
          value: s._id,
        }))
      );
      setDamageCode(
        damageCodeData.data.result.map((s) => ({
          ...s,
          label: `${s.code}-${s.description}`,
          value: s._id,
        }))
      );

      setPackaging(
        packagingData.data.result.map((s) => ({
          ...s,
          label: s.title,
          value: s._id,
        }))
      );
      setOrderTypeData(
        orderTypeData?.data?.result.map((o) => ({
          ...o,
          label: o.name,
          value: o._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [selectedWarehouse]);

  const getAllItems = async () => {
    try {
      const { data } = await get(URLS.items.getItemsForHistory, {
        params: {
          warehouses: selectedWarehouse,
          ownerCodes: selectedOwnerCode,
          ownerGroups: selectedOwnerGroup,
          search: { itemCategory: "faulty" },
        },
      });

      setAllMsns(data.result.map((d) => ({ label: d.msn, value: d._id })));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setFormData([]);
    setUpdateData(null);
    getAllItems();
  }, [selectedWarehouse, selectedOwnerCode, selectedOwnerGroup]);

  useEffect(() => {
    if (itemWarehose) getItemFormDetails();
  }, [itemWarehose]);

  const handleDownloadHistory = async () => {
    try {
      if (!msn) return;
      setDownloading(true);
      const { data } = await post(
        URLS.itemHistory.downloadHistory,
        { itemIds: [msn] },
        {
          responseType: "blob",
        }
      );
      setDownloading(false);

      downloadExcel(data, `Item-history-${msn}.xlsx`);
    } catch (error) {
      console.log(error);
      setDownloading(false);
    }
  };

  const formatedData = (value) => {
    if (typeof value === "boolean") {
      return value ? "True" : "False";
    } else if (typeof value === "string") {
      if (!value.trim()) {
        return "NA";
      } else if (!isNaN(Date.parse(value)) && isNaN(value)) {
        return new Date(value).toLocaleString("en-IN", {
          timeZone: "Asia/Kolkata",
        });
      } else {
        return value;
      }
    } else if (value === null || value === undefined) {
      return "NA";
    } else {
      return value;
    }
  };

  const handleChange = (e) => {
    let name, value;

    if (e && e.target) {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = "expiryDate";
      const updatedValue = e
        ? e
            .hour(dayjs().hour())
            .minute(dayjs().minute())
            .second(dayjs().second())
        : null;
      value = updatedValue;
    }
    setUpdateData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdate = async (id, fd) => {
    try {
      setLoadingSave(true);
      const payload = {
        ...fd,
      };

      let meta = payload.meta;
      if (meta && meta.length) {
        for (let f of meta) {
          if (f.isRequired && !String(f.value)?.trim()) {
            toast.error(`Please fill the required field: ${f.inputLabel}`);
            return setLoadingSave(false);
          }
        }
      }

      const { data } = await put(URLS.items.update + "/" + id, {
        ...payload,
        itemCategory: payload?.itemCategory?.toLowerCase(),
      });
      handleFindHistory();
      setLoadingSave(false);
    } catch (error) {
      console.log(error);
      setLoadingSave(false);
    }
  };

  const handleCustomInputChangeItem = (e) => {
    const { name, value } = e.target;

    setUpdateData((prev) => ({
      ...prev,
      meta: prev.meta.map((m) => (m.inputLabel === name ? { ...m, value } : m)),
    }));
  };

  const onUpdateItem = (item) => {
    handleFindHistory();
    setUpdateData((prev) => ({
      ...prev,
      _id: item._id,
      expiryDate: item.expiryDate,
      warehouse: item.warehouse?._id,
      stockType: item.stockType._id,
      orderType: item.orderType?._id,
      msn: item.msn,
      subFamily: item.subFamily._id,
      itemDescription: item.itemDescription,
      itemCode: item.itemCode,
      manufacturerPartNumber: item.manufacturerPartNumber,
      brandName: item.brandName._id,
      storageCode: item.storageCode._id,
      palletCode: item.palletCode._id,
      conditionCode: item.conditionCode._id,
      damageCode: item.damageCode ? item.damageCode._id : null,
      activityCode: item.activityCode ? item.activityCode._id : null,
      packaging: item.packaging ? item.packaging._id : null,
      configurationOs: item.configurationOs,
      images: item.images,
      oemCaseNumber: item.oemCaseNumber,
      rmaActivityWhRemarks1: item.rmaActivityWhRemarks1,
      rmaActivityWhRemarks2: item.rmaActivityWhRemarks2,

      meta: itemFormFields?.map((f) => ({
        ...f,
        value:
          item.meta?.find((fi) => fi.inputLabel === f.inputLabel)?.value || "",
      })),
    }));
  };

  console.log(updateData, "update data");
  return (
    <>
      <DashBoardPageHeaderPage title="Items History" />

      <Grid
        container
        component="form"
        onSubmit={handleFindHistory}
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Grid item xs={12} md={3}>
          <CustomSelectVirtualized
            onChange={handleMsnChange}
            label="MSN"
            value={msn}
            required={true}
            options={allMsns}
          />
        </Grid>
        {msn ? (
          <Grid item xs={12} md={3} mt={1} alignSelf={"center"}>
            <LoadingButton
              loading={downloading}
              size="small"
              onClick={handleDownloadHistory}
              variant="contained"
              startIcon={<Download fontSize="small" />}
            >
              History
            </LoadingButton>
          </Grid>
        ) : null}
      </Grid>
      {loading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <PuffLoader size={30} color="#14532d" />
        </Box>
      ) : null}

      {!updateData ? null : (
        <>
          {" "}
          <Typography component="h1" sx={{ fontWeight: "bold" }}>
            Item Details
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.primary.dark
                      : theme.palette.primary.light,
                }}
              >
                <TableRow>
                  <TableCell align="center" sx={{ minWidth: "80px" }}>
                    S.No
                  </TableCell>
                  <StyledTableCell align="center">Expiry date</StyledTableCell>
                  <StyledTableCell align="center">Stock type</StyledTableCell>
                  <StyledTableCell align="center">Order type</StyledTableCell>
                  <StyledTableCell align="center">MSN</StyledTableCell>
                  <StyledTableCell align="center">
                    Sub-family name
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Item description
                  </StyledTableCell>
                  <StyledTableCell align="center">Item code</StyledTableCell>
                  <StyledTableCell align="center">
                    Manufacturer part number
                  </StyledTableCell>
                  <StyledTableCell align="center">Brand name</StyledTableCell>
                  <StyledTableCell align="center">Storage code</StyledTableCell>
                  <StyledTableCell align="center">Pallet code</StyledTableCell>
                  <StyledTableCell align="center">
                    Current condition code
                  </StyledTableCell>
                  <StyledTableCell align="center">Damage code</StyledTableCell>
                  <StyledTableCell align="center">
                    Current activity code
                  </StyledTableCell>
                  <StyledTableCell align="center">Packaging</StyledTableCell>
                  <StyledTableCell align="center">
                    Configuration OS
                  </StyledTableCell>
                  {itemFormFields?.map((i, index) => (
                    <StyledTableCell align="center" key={index}>
                      {i.inputLabel}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell align="center">
                    OEM_CASE_NUMBER
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    OEM_SUPPORT_REMARKS
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    RMA_ACTIVITY_WH_REMARKS
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    RMA_ACTIVITY_WH_REMARKS1
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    RMA_ACTIVITY_WH_REMARKS2
                  </StyledTableCell>

                  <TableCell align="center" sx={{ minWidth: "80px" }}>
                    Image
                  </TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell align="center" sx={{ minWidth: "80px" }}>
                    1
                  </TableCell>
                  <StyledTableCell align="center">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          "& .MuiInputBase-input": {
                            height: "8px",
                          },
                          marginTop: "16px",
                          width: "100%",
                        }}
                        name="expiryDate"
                        variant="outlined"
                        size="small"
                        label="Expiry Date"
                        slotProps={{
                          textField: {
                            InputLabelProps: { shrink: true },
                          },
                        }}
                        views={["year", "month", "day"]}
                        format="DD-MM-YYYY"
                        value={
                          updateData.expiryDate
                            ? dayjs(updateData.expiryDate)
                            : null
                        }
                        onChange={(e) => handleChange(e)}
                      />
                    </LocalizationProvider>
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <CustomSelect
                      options={stockTypes.filter(
                        (s) => s.warehouse?._id == updateData.warehouse
                      )}
                      name="stockType"
                      label="Stock Type"
                      value={updateData.stockType}
                      required={true}
                      onChange={(e) => handleChange(e)}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <CustomSelect
                      options={orderTypeData.filter(
                        (s) => s.warehouse?._id == updateData.warehouse
                      )}
                      name="orderType"
                      label="Order Type"
                      value={updateData.orderType}
                      required={true}
                      onChange={(e) => handleChange(e)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      name="msn"
                      value={updateData.msn}
                      label="MSN"
                      required={true}
                      disabled={updateData.msn}
                      onChange={(e) => handleChange(e)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      onChange={(e) => handleChange(e)}
                      options={subFamilies.filter(
                        (s) => s.warehouse?._id == updateData.warehouse
                      )}
                      name="subFamily"
                      label="Sub-Family Name"
                      value={updateData.subFamily}
                      required={true}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      name="itemDescription"
                      value={updateData.itemDescription}
                      options={itemDescription.filter(
                        (s) => s.warehouse === updateData.warehouse
                      )}
                      label="Item Description"
                      required={true}
                      onChange={(e) => handleChange(e)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      name="itemCode"
                      value={updateData.itemCode}
                      label="Item Code"
                      required={true}
                      onChange={(e) => handleChange(e)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      name="manufacturerPartNumber"
                      value={updateData.manufacturerPartNumber}
                      label="Manufacturer Part Number"
                      required={true}
                      onChange={(e) => handleChange(e)}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <CustomSelect
                      options={brandData.filter(
                        (s) => s.warehouse?._id == updateData.warehouse
                      )}
                      name="brandName"
                      label="Brand Name"
                      value={updateData.brandName}
                      required={true}
                      onChange={(e) => handleChange(e)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      options={storageCodes.filter(
                        (s) => s.warehouse?._id == updateData.warehouse
                      )}
                      name="storageCode"
                      label="Storage Code"
                      value={updateData.storageCode}
                      required={true}
                      onChange={(e) => handleChange(e)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      options={palletCodes.filter(
                        (s) => s.warehouse?._id == updateData.warehouse
                      )}
                      name="palletCode"
                      label="Pallet Code"
                      value={updateData.palletCode}
                      required={true}
                      onChange={(e) => handleChange(e)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      options={currentConditionCodes.filter(
                        (s) => s.warehouse?._id == updateData.warehouse
                      )}
                      name="conditionCode"
                      label="Current Condition Code"
                      value={updateData.conditionCode}
                      required={true}
                      onChange={(e) => handleChange(e)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      options={damageCode.filter(
                        (s) => s.warehouse?._id == updateData.warehouse
                      )}
                      name="damageCode"
                      label="Damage Code"
                      value={updateData.damageCode}
                      required={true}
                      onChange={(e) => handleChange(e)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      options={activityCodes.filter(
                        (s) => s.warehouse?._id == updateData.warehouse
                      )}
                      name="activityCode"
                      label="Activity Code"
                      value={updateData.activityCode}
                      required={true}
                      onChange={(e) => handleChange(e)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      options={packaging.filter(
                        (s) => s.warehouse?._id == updateData.warehouse
                      )}
                      name="packaging"
                      label="Packaging"
                      value={updateData.packaging}
                      required={true}
                      onChange={(e) => handleChange(e)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      name="configurationOs"
                      value={updateData.configurationOs}
                      label="Configuration OS"
                      onChange={(e) => handleChange(e)}
                    />
                  </StyledTableCell>
                  {itemFormFields?.map((f, index) => (
                    <StyledTableCell align="center" key={index}>
                      <CustomInput
                        onChange={(e) => handleCustomInputChangeItem(e)}
                        name={f.inputLabel}
                        value={
                          updateData.meta?.find(
                            (ff) => ff.inputLabel === f.inputLabel
                          )?.value || ""
                        }
                        required={f.isRequired}
                        label={f.inputLabel}
                        type={f.inputType}
                      />
                    </StyledTableCell>
                  ))}

                  <StyledTableCell align="center">
                    <CustomInput
                      name="oemCaseNumber"
                      value={updateData.oemCaseNumber}
                      label="OEM_CASE_NUMBER"
                      required={false}
                      onChange={(e) => handleChange(e)}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <CustomInput
                      name="oemSupportRemarks"
                      value={updateData.oemSupportRemarks}
                      label="OEM_SUPPORT_REMARKS"
                      required={false}
                      onChange={(e) => handleChange(e)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      name="warehouseRemarks"
                      value={updateData.warehouseRemarks}
                      label="RMA_ACTIVITY_WH_REMARKS"
                      required={false}
                      onChange={(e) => handleChange(e)}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <CustomInput
                      name="rmaActivityWhRemarks1"
                      value={updateData.rmaActivityWhRemarks1}
                      label="RMA_ACTIVITY_WH_REMARKS1"
                      required={false}
                      onChange={(e) => handleChange(e)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      name="rmaActivityWhRemarks2"
                      value={updateData.rmaActivityWhRemarks2}
                      label="RMA_ACTIVITY_WH_REMARKS2"
                      required={false}
                      onChange={(e) => handleChange(e)}
                    />
                  </StyledTableCell>

                  <TableCell align="center" sx={{ minWidth: "80px" }}>
                    <ShowComponent module={"Item History"} action={"view"}>
                      <ViewOrUpdateItemFiles
                        key={updateData._id}
                        module={"Item History"}
                        item={updateData}
                        onUpdate={onUpdateItem}
                      />
                    </ShowComponent>
                  </TableCell>
                  <TableCell align="center">
                    <ShowComponent module={"Item History"} action={"update"}>
                      <Box
                        display="flex"
                        sx={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <LoadingButton
                          loading={loadingSave}
                          size="small"
                          variant="contained"
                          sx={{ width: 200, marginRight: "10px" }}
                          onClick={() =>
                            handleUpdate(updateData._id, updateData)
                          }
                        >
                          Save
                        </LoadingButton>
                      </Box>
                    </ShowComponent>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {formData.length ? (
        <>
          <Typography component="h1" sx={{ fontWeight: "bold", mt: 3 }}>
            Item History Details
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.primary.dark
                      : theme.palette.primary.light,
                }}
              >
                <TableRow>
                  <TableCell sx={{ minWidth: "80px" }} align="center">
                    S.No
                  </TableCell>
                  <StyledTableCell align="center">
                    GMS reference code
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Item category
                  </StyledTableCell>

                  <StyledTableCell align="center">Expiry date</StyledTableCell>
                  <StyledTableCell align="center">Stock type</StyledTableCell>
                  <StyledTableCell align="center">Order type</StyledTableCell>
                  <StyledTableCell align="center">MSN</StyledTableCell>
                  <StyledTableCell align="center">
                    Sub-family name
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Item description
                  </StyledTableCell>
                  <StyledTableCell align="center">Item code</StyledTableCell>
                  <StyledTableCell align="center">
                    Manufacturer part number
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    Activity code
                  </StyledTableCell>

                  <StyledTableCell align="center">Damage code</StyledTableCell>

                  <StyledTableCell align="center">
                    Grading scale
                  </StyledTableCell>

                  <StyledTableCell align="center">Brand name</StyledTableCell>
                  <StyledTableCell align="center">Storage code</StyledTableCell>
                  <StyledTableCell align="center">Pallet code</StyledTableCell>

                  <StyledTableCell align="center">Packaging</StyledTableCell>
                  <StyledTableCell align="center">
                    Current condition code
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Configuration OS
                  </StyledTableCell>

                  <StyledTableCell align="center">On Hold</StyledTableCell>
                  <StyledTableCell align="center">
                    On Hold remarks
                  </StyledTableCell>

                  <StyledTableCell align="center">In bundle</StyledTableCell>

                  <StyledTableCell align="center">In stock</StyledTableCell>

                  <StyledTableCell align="center">In saved</StyledTableCell>

                  {itemFormFields?.map((i, index) => (
                    <StyledTableCell align="center" key={index}>
                      {i.inputLabel}
                    </StyledTableCell>
                  ))}

                  <StyledTableCell align="center">
                    {" "}
                    OEM_CASE_NUMBER
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {" "}
                    OEM_SUPPORT_REMARKS
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    RMA_ACTIVITY_WH_REMARKS
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    RMA_ACTIVITY_WH_REMARKS1
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    RMA_ACTIVITY_WH_REMARKS2
                  </StyledTableCell>

                  <StyledTableCell align="center">Image</StyledTableCell>
                  <StyledTableCell align="center">Updated At</StyledTableCell>
                  <StyledTableCell align="center">Updated By</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => (
                    <TableRow key={i}>
                      <TableCell
                        align="center"
                        sx={{
                          minWidth: "80px",
                        }}
                      >
                        {page * rowsPerPage + 1 + i}
                      </TableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.gmsReferenceCode?.isModified)}
                      >
                        {row?.gmsReferenceCode?.value || ""}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.itemCategory?.isModified)}
                      >
                        {row?.itemCategory?.value.toUpperCase()}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.expiryDate?.isModified)}
                      >
                        {!row?.expiryDate?.value?.includes("Previous")
                          ? row.expiryDate?.value !== "null" &&
                            row.expiryDate?.value !== null
                            ? dayjs(row.expiryDate?.value).format("DD/MM/YYYY")
                            : "N/A"
                          : row.expiryDate.value}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.stockType?.isModified)}
                      >
                        {row?.stockType?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.orderType?.isModified)}
                      >
                        {row?.orderType?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.msn?.isModified)}
                      >
                        {row?.msn?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.subFamily?.isModified)}
                      >
                        {row?.subFamily?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.itemDescription?.isModified)}
                      >
                        {row?.itemDescription?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.itemCode?.isModified)}
                      >
                        {row?.itemCode?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(
                          row.manufacturerPartNumber?.isModified
                        )}
                      >
                        {row?.manufacturerPartNumber?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.activityCode?.isModified)}
                      >
                        {row?.activityCode?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.damageCode?.isModified)}
                      >
                        {row?.damageCode?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.gradingScale?.isModified)}
                      >
                        {row?.gradingScale?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.brandName?.isModified)}
                      >
                        {row?.brandName?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.storageCode?.isModified)}
                      >
                        {row?.storageCode?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.palletCode?.isModified)}
                      >
                        {row?.palletCode?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.packaging?.isModified)}
                      >
                        {row?.packaging?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.conditionCode?.isModified)}
                      >
                        {row?.conditionCode?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.configurationOs?.isModified)}
                      >
                        {row?.configurationOs?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.freezed?.isModified)}
                      >
                        {row?.freezed?.value?.toString()}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.freezeRemarks?.isModified)}
                      >
                        {row?.freezeRemarks?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.inBundle?.isModified)}
                      >
                        {row?.inBundle?.value?.toString()}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.inStock?.isModified)}
                      >
                        {row?.inStock?.value?.toString()}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.isSaved?.isModified)}
                      >
                        {row?.isSaved?.value?.toString()}
                      </StyledTableCell>

                      {itemFormFields?.map((f, index) => {
                        const value = row[f.inputLabel];

                        return (
                          <StyledTableCell
                            align="center"
                            key={index?.toString() + f.inputLabel}
                            sx={getModifiedStyles(value?.isModified)}
                          >
                            {typeof value === "object"
                              ? value?.value || "N/A"
                              : value || "N/A"}
                          </StyledTableCell>
                        );
                      })}

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.oemCaseNumber?.isModified)}
                      >
                        {row?.oemCaseNumber?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(
                          row.oemSupportRemarks?.isModified
                        )}
                      >
                        {row?.oemSupportRemarks?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(row.warehouseRemarks?.isModified)}
                      >
                        {row?.warehouseRemarks?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(
                          row.rmaActivityWhRemarks1?.isModified
                        )}
                      >
                        {row?.rmaActivityWhRemarks1?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        sx={getModifiedStyles(
                          row.rmaActivityWhRemarks2?.isModified
                        )}
                      >
                        {row?.rmaActivityWhRemarks2?.value || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <Tooltip title="View">
                          <IconButton
                            size="small"
                            onClick={() => handleOpen(row?.images)}
                          >
                            <RemoveRedEyeRoundedIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {row.updatedAt}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {row.updatedBy}
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : null}

      {formData.length ? (
        <TablePagination
          fullWidth
          component="div"
          count={formData ? (formData.length ? formData.length : 0) : 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE}
        />
      ) : null}

      <CustomDialog fullScreen={fullScreen} open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            fontSize: "15px",
          }}
        >
          View Images
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 12,
            top: 8,
          }}
        >
          <Close />
        </IconButton>

        <Divider sx={{ mb: 2 }} />

        <DialogContent>
          <Typography mb={2} fontWeight={600}>
            Is the image modified?: {isModifiedImage ? "Yes" : "No"}
          </Typography>
          <Grid container spacing={1}>
            {images.length === 0 ? (
              <Grid item xs={12}>
                <Typography align="center">
                  No data available at the moment
                </Typography>
              </Grid>
            ) : (
              images.map((image, index) => (
                <Grid item xs={12} sm={12} md={12} key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "8px",
                      borderRadius: "5px",
                      marginTop: "5px",
                      background: "#5fa5f661",
                      cursor: "pointer",
                    }}
                    onClick={() => window.open(image, "_blank")}
                  >
                    <Typography>{`File ${index + 1}`}</Typography>
                    <Stack direction="row" spacing={2}>
                      <IconButton onClick={() => window.open(image, "_blank")}>
                        <RemoveRedEyeRoundedIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                  </Box>
                </Grid>
              ))
            )}
          </Grid>

          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button
              size="small"
              onClick={handleClose}
              variant="contained"
              color="error"
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </CustomDialog>
    </>
  );
}
