// import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
// import {
//   FormControl,
//   Select,
//   MenuItem,
//   InputLabel,
//   ListSubheader,
//   TextField,
//   InputAdornment,
//   Checkbox,
//   ListItemIcon,
// } from "@mui/material";
// import ThemeModeContext from "../context/ThemeModeContext";
// import SearchIcon from "@mui/icons-material/Search";

// const containsText = (text, searchText) =>
//   text?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1;

// const FormSelect = ({
//   label,
//   name,
//   options,
//   formik,
//   disabled = false,
//   multiple = false,
//   required = false,
//   ...rest
// }) => {
//   const inputRef = useRef(undefined);
//   const { setFieldValue, touched, errors } = formik;

//   const [searchText, setSearchText] = useState("");
//   const [blurred, setBlurred] = useState(false);

//   const { isDarkMode } = useContext(ThemeModeContext);

//   useEffect(() => {
//     setSearchText("");
//   }, [blurred]);

//   const displayedOptions = useMemo(
//     () => options.filter((option) => containsText(option.label, searchText)),
//     [searchText, options, blurred]
//   );

//   const handleChange = (event) => {
//     const { value } = event.target;

//     // Directly set the value for multiple or single selection
//     setFieldValue(name, value);
//   };

//   const labelColor =
//     disabled && !isDarkMode
//       ? "gray"
//       : !disabled && isDarkMode
//       ? "white"
//       : !disabled && !isDarkMode
//       ? "black"
//       : disabled && isDarkMode
//       ? "gray"
//       : "inherit";

//   return (
//     <FormControl
//       margin="normal"
//       required={required}
//       variant="outlined"
//       size="small"
//       fullWidth
//     >
//       <InputLabel
//         sx={{ color: labelColor }}
//         required={required}
//         shrink={true}
//         id={name}
//       >
//         {label}
//       </InputLabel>

//       <Select
//         notched
//         required={required}
//         disabled={disabled}
//         onChange={handleChange}
//         value={
//           formik.values[name] !== undefined
//             ? formik.values[name]
//             : multiple
//             ? []
//             : ""
//         }
//         multiple={multiple}
//         label={label}
//         labelId={name}
//         error={touched[name] && errors[name]}
//         MenuProps={{ autoFocus: false }}
//         onBlur={() => {
//           // Only reset search text if no option is being clicked
//           setTimeout(() => setBlurred(true), 100);
//         }}
//         onFocus={() => setBlurred(false)}
//         {...rest}
//         renderValue={(selected) => {
//           if (Array.isArray(selected)) {
//             // Multiple selection
//             return options
//               .filter((option) => selected.includes(option.value))
//               .map((option) => option.label)
//               .join(", ");
//           }
//           // Single selection
//           return (
//             options.find((option) => option.value === selected)?.label || ""
//           );
//         }}
//       >
//         <ListSubheader>
//           <TextField
//             size="small"
//             autoFocus
//             placeholder="Type to search..."
//             fullWidth
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   <SearchIcon />
//                 </InputAdornment>
//               ),
//             }}
//             value={searchText}
//             onChange={(e) => setSearchText(e.target.value)}
//             onKeyDown={(e) => {
//               if (e.key !== "Escape") {
//                 e.stopPropagation();
//               }
//             }}
//           />
//         </ListSubheader>

//         {displayedOptions.map((s, i) => (
//           <MenuItem key={`${s.value + i}`} value={s.value}>
//             <ListItemIcon>
//               <Checkbox
//                 size="small"
//                 checked={
//                   multiple
//                     ? Array.isArray(formik.values[name]) &&
//                       formik.values[name].includes(s.value)
//                     : formik.values[name] === s.value
//                 }
//               />
//             </ListItemIcon>

//             {s.label}
//           </MenuItem>
//         ))}
//       </Select>

//       {touched[name] && errors[name] && (
//         <div style={{ color: "red" }}>{errors[name]}</div>
//       )}
//     </FormControl>
//   );
// };

// export default FormSelect;

import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListSubheader,
  TextField,
  InputAdornment,
  Checkbox,
  ListItemIcon,
} from "@mui/material";
import ThemeModeContext from "../context/ThemeModeContext";
import SearchIcon from "@mui/icons-material/Search";

const containsText = (text, searchText) =>
  text?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1;

const FormSelect = ({
  label,
  name,
  options,
  formik,
  disabled = false,
  multiple = false,
  required = false,
  disableUnselect = false, // New prop
  ...rest
}) => {
  const inputRef = useRef(undefined);
  const { setFieldValue, touched, errors } = formik;

  const [searchText, setSearchText] = useState("");
  const [blurred, setBlurred] = useState(false);

  const { isDarkMode } = useContext(ThemeModeContext);

  useEffect(() => {
    setSearchText("");
  }, [blurred]);

  const displayedOptions = useMemo(
    () => options.filter((option) => containsText(option.label, searchText)),
    [searchText, options, blurred]
  );

  // const handleChange = (event) => {
  //   const { value } = event.target;

  //   if (disableUnselect) {
  //     if (multiple) {
  //       const previouslySelected = formik.values[name] || [];
  //       // Prevent unselecting an already selected option

  //       if (!value.length) {
  //         return setFieldValue(name, value);
  //       }
  //       if (
  //         value.length < previouslySelected.length &&
  //         previouslySelected.some((item) => !value.includes(item))
  //       ) {
  //         return;
  //       }
  //     } else {
  //       // Prevent unselecting in single selection mode
  //       if (formik.values[name] && value === "") {
  //         return;
  //       }
  //     }
  //   }

  //   // Set the value as usual
  //   setFieldValue(name, value);
  // };

  const handleChange = (event) => {
    const { value } = event.target;

    if (disableUnselect) {
      if (multiple) {
        const previouslySelected = formik.values[name] || [];

        if (previouslySelected.length > value.length) {
          return;
        }
      } else {
        if (formik.values[name] && value === "") {
          return;
        }
      }
    }

    setFieldValue(name, value);
  };

  const labelColor =
    disabled && !isDarkMode
      ? "gray"
      : !disabled && isDarkMode
      ? "white"
      : !disabled && !isDarkMode
      ? "black"
      : disabled && isDarkMode
      ? "gray"
      : "inherit";

  return (
    <FormControl
      margin="normal"
      required={required}
      variant="outlined"
      size="small"
      fullWidth
    >
      <InputLabel
        sx={{ color: labelColor }}
        required={required}
        shrink={true}
        id={name}
      >
        {label}
      </InputLabel>

      <Select
        notched
        required={required}
        disabled={disabled}
        onChange={handleChange}
        value={
          formik.values[name] !== undefined
            ? formik.values[name]
            : multiple
            ? []
            : ""
        }
        multiple={multiple}
        label={label}
        labelId={name}
        error={touched[name] && errors[name]}
        MenuProps={{ autoFocus: false }}
        onBlur={() => {
          setTimeout(() => setBlurred(true), 100);
        }}
        onFocus={() => setBlurred(false)}
        {...rest}
        renderValue={(selected) => {
          if (Array.isArray(selected)) {
            return options
              .filter((option) => selected.includes(option.value))
              .map((option) => option.label)
              .join(", ");
          }
          return (
            options.find((option) => option.value === selected)?.label || ""
          );
        }}
      >
        <ListSubheader>
          <TextField
            size="small"
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>

        {displayedOptions.map((s, i) => (
          <MenuItem key={`${s.value + i}`} value={s.value}>
            <ListItemIcon>
              <Checkbox
                size="small"
                checked={
                  multiple
                    ? Array.isArray(formik.values[name]) &&
                      formik.values[name].includes(s.value)
                    : formik.values[name] === s.value
                }
              />
            </ListItemIcon>

            {s.label}
          </MenuItem>
        ))}
      </Select>

      {touched[name] && errors[name] && (
        <div style={{ color: "red" }}>{errors[name]}</div>
      )}
    </FormControl>
  );
};

export default FormSelect;
