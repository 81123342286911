import React, { useContext, useRef, useState } from "react";
import PageHeader from "../../components/PageHeader";
import TabList from "../../components/Tabs/TabList";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import {
  downloadExcel,
  generateRandomNumber,
  getDateRange,
  hasAllValues,
} from "../../utils/utilities";
import TabPanel from "../../components/Tabs/TabPanel";
import dayjs from "dayjs";
import FormInput from "../../forms/FormInput";
import FormDatePicker from "../../forms/FormDatePicker";
import FormSelect from "../../forms/FormSelect";
import { useEffect } from "react";
import CustomTable from "../../components/Tables/CustomTable";
import { pickListProcessKeys } from "../../data/TableData/pickListProcess";
import CustomInput from "../../components/SecondaryForms/CustomInput";
import { del, get, post, put } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";
import FileSelect from "../../forms/FileSelect";
import { LoadingButton } from "@mui/lab";
import UpdatePickList from "./UpdatePickList";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";

import { toast } from "react-toastify";
import DashBoardPageHeaderPage from "../../components/DashBoard/DashBoardHeaderPage";
import WarehouseContext from "../../context/WarehouseContext";
import { ArrowDropDown, Refresh } from "@mui/icons-material";
import { debounce } from "lodash";

import CustomSelectVirtualized from "../../components/SecondaryForms/CustomSelectVirtualized";
import { PuffLoader } from "react-spinners";
import CustomSearch from "../../components/Tables/CustomSearch";
import ClearIcon from "@mui/icons-material/Clear";

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "200px",
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",

  p: 4,
};

const categoryType = [
  { label: "New Only", value: "new" },
  {
    label: "Used Only",
    value: "used",
  },
  {
    label: "Bundled",
    value: "bundled",
  },
];

export default function PickList() {
  const {
    selectedWarehouse,
    selectedOwnerCode,
    selectedOwnerGroup,
    allWarehouses,
  } = useContext(WarehouseContext);
  const [value, setSelectValue] = useState(0);
  const handleTabChange = (e, newValue) => setSelectValue(newValue);

  const [entryDone, setEntryDone] = useState(false);
  const [formData, setFormData] = useState([]);
  const [formFields, setFormFields] = useState(null);

  const [warehouseData, setWarehouseData] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [subFamilies, setSubFamilies] = useState([]);
  const [storageCodes, setStorageCodes] = useState([]);
  const [palletCodes, setPalletCodes] = useState([]);
  const [addingPickist, setAddingPickList] = useState(false);
  const [entryFiles, setEntryFiles] = useState([]);
  const [pickLists, setPickLists] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [entryToEdit, setEntryToEdit] = useState([]);
  const [brands, setBrands] = useState([]);
  const [inStockItems, setInStockItems] = useState([]);
  const [inStockBundles, setInStockBundles] = useState([]);

  const [ownerData, setOwnerData] = useState([]);
  const [ownerCodes, setOwnerCodes] = useState([]);
  const [loadingData, setLoadingData] = useState([]);
  const [loadingDownload, setLoadingDownload] = useState([]);
  const [pufferLoading, setPufferLoading] = useState(false);

  const [fetchingData, setFetchingData] = useState([]);
  const [freezeLoading, setFreezeLoading] = useState(false);
  const [freezedItemDetails, setFreezedItemDetails] = useState({
    item: null,
    index: 0,
    name: "",
    value: "",
  });

  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");

  const [freezeDialog, setFreezeDialog] = useState({
    open: false,
    data: [
      {
        msn: "",
        itemDescription: "",
        newRemarks: "",
        freezeRemarks: "",
      },
    ],
    updating: false,
  });

  const getAllPickList = async () => {
    try {
      setPufferLoading(true);
      const { data } = await get(URLS.pickList.list, {
        params: {
          warehouses: selectedWarehouse,
          ownerCodes: selectedOwnerCode,
          onwerGroups: selectedOwnerGroup,
        },
      });

      let res = data.result
        .map((d) => ({
          ...d,
          createDate: d.createdAt,
          createdAt: new Date(d.createdAt).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
          }),
        }))
        .sort((a, b) => new Date(b.createDate) - new Date(a.createDate));

      setPickLists(res);
      setFilteredData(res);

      setPufferLoading(false);
    } catch (error) {
      console.log(error);
      setPufferLoading(false);
    }
  };

  useEffect(() => {
    if (value === 1) {
      getAllPickList();
    }
  }, [value, selectedWarehouse, selectedOwnerCode]);

  const handleSubmit = async (values) => {
    setAddingPickList(true);

    if (!values.bundle) {
      if (!hasAllValues(values, ["bundle"])) return setAddingPickList(false);
    } else if (!values.sku) {
      if (!hasAllValues(values, ["sku"])) return setAddingPickList(false);
    }

    for (let item of formData) {
      if (
        (item.category !== "Bundled" &&
          !hasAllValues(item, ["bundleReferenceNumber"])) ||
        (item.category === "Bundled" && !hasAllValues(item, []))
      )
        return setAddingPickList(false);
    }

    let payload = {
      ...values,
      individualItems: formData
        .filter((f) => f.category !== "Bundled")
        .map((v) => v._id),
      bundledItems: formData
        .filter((f) => f.category === "Bundled")
        .map((v) => v._id),
      meta: formFields || [],
      pickListDate: dayjs(values.pickListDate).format("YYYY-MM-DD HH:mm:ss"),
    };

    try {
      const newFormData = new FormData();
      newFormData.append("pickListData", JSON.stringify(payload));

      entryFiles.forEach((file) => newFormData.append("files", file));

      const { data } = await post(URLS.pickList.create, newFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      getAllPickList();
      entryFormik.resetForm();
      entryFormik.setFieldValue(
        "gmsPickListCode",
        `GMS${generateRandomNumber(10)}`
      );

      setSelectValue(1);
      setFormData([]);
      setAddingPickList(false);
      setEntryDone(false);
    } catch (error) {
      console.log(error);
      setAddingPickList(false);
    }
  };

  const entryFormik = useFormik({
    initialValues: {
      gmsPickListCode: `GMS${generateRandomNumber(10)}`,
      pickListDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      warehouseCode: "",
      warehouseName: "",
      bundle: "",
      sku: "",
      ownerGroup: "",
      ownerCode: "",
      ownerCategory: "NA",
      remarks: "NA",
    },
    onSubmit: handleSubmit,
    validateOnBlur: false,
  });

  const debouceSubmit = debounce(entryFormik.handleSubmit, 1000);

  useEffect(() => {
    let formData = [];

    if (
      !entryFormik.values.ownerGroup ||
      !entryFormik.values.ownerCode ||
      !entryFormik.values.warehouseCode
    )
      return;
    if (entryFormik.values.sku || entryFormik.values.bundle) {
      if (Number(entryFormik.values.sku) > inStockItems.length) {
        toast.info(
          "Selected number of sku is greater than number of sku in stock"
        );
      } else if (Number(entryFormik.values.bundle) > inStockBundles.length) {
        toast.info(
          "Selected number of bundles is greater than number of bundles in stock"
        );
      } else {
        for (let i = 0; i < entryFormik.values.sku; i++) {
          let newForm = {
            _id: "",
            category: "",
            stockType: "",
            msn: "",
            bundleReferenceNumber: "",
            subFamilyName: "",
            itemDescription: "",
            itemCode: "",
            manufacturerPartNumber: "",
            brandName: "",
            storageCode: "",
            palletCode: "",
          };
          formData.push(newForm);
        }

        for (let i = 0; i < entryFormik.values.bundle; i++) {
          let newForm = {
            category: "Bundled",
            stockType: "",
            msn: "",
            bundleReferenceNumber: "",
            subFamilyName: "",
            itemDescription: "",
            itemCode: "",
            manufacturerPartNumber: "",
            brandName: "",
            storageCode: "",
            palletCode: "",
          };
          formData.push(newForm);
        }
      }
    }

    setFormData(formData);
  }, [
    entryFormik.values.sku,
    entryFormik.values.bundle,
    entryFormik.values.warehouseCode,
    entryFormik.values.ownerGroup,
    entryFormik.values.ownerCode,
  ]);

  const getItems = async () => {
    try {
      setFetchingData((prev) => [...prev, "items"]); // Use functional update
      const { data } = await get(URLS.items.list, {
        params: {
          search: {
            inStock: true,
            inBundle: false,
            warehouse: entryFormik.values.warehouseCode,
            ownerGroup: entryFormik.values.ownerGroup,
            ownerCode: entryFormik.values.ownerCode,
            itemCategory: { $ne: "faulty" },
          },
        },
      });

      setInStockItems(data.result.map((i) => ({ label: i.msn, value: i.msn })));
      setFetchingData((prev) => prev.filter((f) => f !== "items"));
    } catch (error) {
      setFetchingData((prev) => prev.filter((f) => f !== "items"));
      console.log(error);
    }
  };

  const getBundles = async () => {
    try {
      setFetchingData((prev) => [...prev, "bundles"]); // Use functional update
      const { data } = await get(URLS.bundle.list, {
        params: {
          search: {
            inStock: true,
            warehouse: entryFormik.values.warehouseCode,
            ownerCode: entryFormik.values.ownerCode,
            ownerGroup: entryFormik.values.ownerGroup,
          },
        },
      });

      setInStockBundles(
        data.result
          ?.filter(
            (b) => b.items.length > 0 && !b.items.some((i) => !i.inStock)
          )
          .map((d) => ({
            label: d.bundleReferenceNumber,
            value: d.bundleReferenceNumber,
          }))
      );
      setFetchingData((prev) => prev.filter((f) => f !== "bundles"));
    } catch (error) {
      setFetchingData((prev) => prev.filter((f) => f !== "bundles"));
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      entryFormik.values.warehouseCode &&
      entryFormik.values.ownerCode &&
      entryFormik.values.ownerGroup
    ) {
      getItems();
      getBundles();
    }
  }, [
    entryFormik.values.warehouseCode,
    entryFormik.values.ownerCode,
    entryFormik.values.ownerGroup,
  ]);

  //customform
  const getFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: {
          collectionName: "pickList",
          warehouse: entryFormik.values.warehouseCode,
        },
      });
      setFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };

  // master data
  const getData = async () => {
    try {
      const stockPromise = get(URLS.stockType.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const subFamilyPromise = get(URLS.subFamily.list, {
        params: { warehouse: entryFormik.values.warehouseCode },
      });
      const storageCodePromise = get(URLS.storageCode.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const palletCodeCodePromise = get(URLS.palletCode.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const brandDataPromise = get(URLS.brand.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });

      const ownerDataPromise = get(URLS.ownerGroup.list, {
        params: { warehouse: entryFormik.values.warehouseCode },
      });

      const [
        stockTypeData,
        subFamilyData,
        storageCodeData,
        palletCodeData,
        brandData,
        ownerData,
      ] = await Promise.all([
        stockPromise,
        subFamilyPromise,
        storageCodePromise,
        palletCodeCodePromise,
        brandDataPromise,
        ownerDataPromise,
      ]);
      entryFormik.setFieldValue("ownerGroup", "");

      setOwnerData(
        ownerData?.data?.result.map((l) => ({
          ...l,
          label: l.name,
          value: l._id,
        }))
      );
      entryFormik.setFieldValue("ownerGroup", "");
      if (ownerData?.data?.result?.length === 1) {
        entryFormik.setFieldValue(
          "ownerGroup",
          ownerData?.data?.result[0]?._id
        );
      }

      setStockTypes(
        stockTypeData?.data?.result?.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );

      setBrands(
        brandData?.data?.result?.map((b) => ({
          ...b,
          label: b.name,
          value: b._id,
        }))
      );

      setSubFamilies(
        subFamilyData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setStorageCodes(
        storageCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setPalletCodes(
        palletCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  //warehouseData
  const getWarehouseDetails = async () => {
    try {
      const { data } = await get(URLS.warehouse.list);
      setWarehouseData(
        data?.result.map((w) => ({
          ...w,
          label: w.code,
          value: w._id,
        }))
      );
      if (allWarehouses?.length === 1) {
        entryFormik.setFieldValue("warehouseCode", data.result[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getWarehouseDetails();
  }, []);

  useEffect(() => {
    const selectedWarehouse = warehouseData.find(
      (w) => w.value === entryFormik.values.warehouseCode
    );
    entryFormik.setFieldValue("warehouseName", selectedWarehouse?.name);
  }, [entryFormik.values.warehouseCode, warehouseData]);

  const getOwnerCodes = async () => {
    try {
      entryFormik.setFieldValue("ownerCode", "");
      const { data } = await get(URLS.ownerCode.list, {
        params: { ownerGroup: entryFormik.values.ownerGroup },
      });

      setOwnerCodes(
        data?.result.map((c) => ({
          ...c,
          label: c.name,
          value: c._id,
        }))
      );

      if (data.result?.length === 1) {
        entryFormik.setFieldValue("ownerCode", data.result[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (entryFormik.values.warehouseCode) {
      getData();
    }
  }, [entryFormik.values.warehouseCode]);

  useEffect(() => {
    if (entryFormik.values.ownerGroup) {
      entryFormik.setFieldValue("ownerCode", "");
      getOwnerCodes();
    } else {
      setOwnerCodes([]);
      entryFormik.setFieldValue("ownerCode", "");
    }
  }, [entryFormik.values.ownerGroup, value]);

  useEffect(() => {
    if (entryFormik.values.warehouseCode) {
      getFormDetails();
    }
  }, [entryFormik.values.warehouseCode]);

  const handleOnFieldAdd = () => {
    if (entryFormik.values.warehouseCode) {
      getFormDetails();
    }
  };

  const handleChange = async (e, index) => {
    const { name, value } = e.target;

    if (formData.filter((fd) => fd[name] === value).length)
      return toast.error("Cannot add an item multiple times");

    if (name === "msn") {
      setLoadingData([...loadingData, index]);
      try {
        const { data } = await get(URLS.items.list, {
          params: {
            search: {
              inStock: true,
              inBundle: false,
              msn: value,
            },
          },
        });

        if (data.result.length) {
          let item = data.result[0];
          if (item.freezed) {
            setFreezedItemDetails({ item, index, name, value });
            setFreezeDialog((prev) => ({ ...prev, open: true, data: item }));
            return setLoadingData(loadingData.filter((d) => d !== index));
          }
          setFormData(
            formData.map((f, i) => (i == index ? { ...f, [name]: value } : f))
          );
          setInStockItems(inStockItems.filter((i) => i.value !== item.msn));
          setFormData(
            formData.map((f, i) =>
              i == index
                ? {
                    ...f,
                    _id: item._id,
                    category: item.itemCategory,
                    stockType: item.stockType.name,
                    msn: item.msn,
                    bundleReferenceNumber: "",
                    subFamilyName: item.subFamily.name,
                    itemDescription: item.itemDescription,
                    itemCode: item.itemCode,
                    manufacturerPartNumber: item.manufacturerPartNumber,
                    brandName: item.brandName.name,
                    storageCode: item.storageCode.code,
                    palletCode: item.palletCode.code,
                  }
                : f
            )
          );
        } else {
          // setFormData(
          //   formData.map((f, i) =>
          //     i == index
          //       ? {
          //           _id: "",
          //           category: "",
          //           stockType: "",
          //           msn: value,
          //           bundleReferenceNumber: "",
          //           subFamilyName: "",
          //           itemDescription: "",
          //           itemCode: "",
          //           manufacturerPartNumber: "",
          //           brandName: "",
          //           storageCode: "",
          //           palletCode: "",
          //         }
          //       : f
          //   )
          // );
        }

        setLoadingData(loadingData.filter((d) => d !== index));
      } catch (error) {
        setLoadingData(loadingData.filter((d) => d !== index));

        console.log(error);
      }
    } else if (name === "bundleReferenceNumber" && value) {
      try {
        setLoadingData([...loadingData, index]);

        const { data } = await get(URLS.bundle.list, {
          params: { search: { bundleReferenceNumber: value, inStock: true } },
        });

        if (data.result.find((b) => b.bundleReferenceNumber === value)) {
          let bundle = data.result[0];
          setInStockBundles(
            inStockBundles.filter(
              (i) => i.value !== bundle.bundleReferenceNumber
            )
          );
          setFormData(
            formData.map((f, i) =>
              i == index
                ? {
                    ...f,
                    _id: bundle._id,
                    stockType: bundle.stockType.name,
                    msn: bundle.items.map((i) => i.msn).join(","),
                    bundleReferenceNumber: bundle.bundleReferenceNumber,
                    subFamilyName: bundle.items
                      .map(
                        (i) =>
                          subFamilies.find((s) => s._id == i.subFamily._id)
                            ?.name
                      )
                      .join(","),
                    itemDescription: bundle.items
                      .map((i) => i.itemDescription)
                      .join(","),
                    itemCode: bundle.items.map((i) => i.itemCode).join(","),
                    manufacturerPartNumber: bundle.items
                      .map((i) => i.manufacturerPartNumber)
                      .join(","),
                    brandName: bundle.items
                      .map(
                        (i) =>
                          brands.find((b) => b._id == i.brandName._id)?.name
                      )
                      .join(","),
                    storageCode: bundle.storageCode.code,
                    palletCode: bundle.palletCode.code,
                  }
                : f
            )
          );
        } else {
          // setFormData(
          //   formData.map((f, i) =>
          //     i == index
          //       ? {
          //           category: "Bundled",
          //           stockType: "",
          //           msn: "",
          //           bundleReferenceNumber: value,
          //           subFamilyName: "",
          //           itemDescription: "",
          //           itemCode: "",
          //           manufacturerPartNumber: "",
          //           brandName: "",
          //           storageCode: "",
          //           palletCode: "",
          //         }
          //       : f
          //   )
          // );
        }
        setLoadingData(loadingData.filter((d) => d !== index));
      } catch (error) {
        setLoadingData(loadingData.filter((d) => d !== index));

        console.log(error);
      }
    }
  };

  const handleRefreshValue = async (e, index) => {
    const { name, value } = e.target;

    setFormData(
      formData.map((f, i) =>
        i === index
          ? { category: name === "msn" ? "" : "Bundled", [name]: "" }
          : f
      )
    );

    if (name === "msn") {
      setInStockItems([...inStockItems, { label: value, value }]);
    }

    if (name === "bundleReferenceNumber") {
      setInStockBundles([...inStockBundles, { label: value, value }]);
    }
  };

  const handleCustomInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields(
      formFields?.map((f) => (f.inputLabel === name ? { ...f, value } : f))
    );
  };

  const handleEditClick = (data) => {
    setEntryToEdit(data);
    setSelectValue(2);
    console.log(data);
  };

  const handleDelete = async (id, reason) => {
    try {
      const res = await del(URLS.pickList.delete + "/" + id, {
        params: {
          reasonForDelete: reason,
        },
      });
      getAllPickList();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownload = async (pickListdata) => {
    try {
      setLoadingDownload((prev) => ({
        ...prev,
        [pickListdata._id]: true,
      }));
      const { data } = await get(
        URLS.pickList.downloadPickList + "/" + pickListdata._id,
        {
          responseType: "blob",
        }
      );

      downloadExcel(data, `Picklist-${pickListdata?.gmsPickListCode}.xlsx`);
      setLoadingDownload((prev) => ({
        ...prev,
        [pickListdata._id]: false,
      }));
    } catch (error) {
      console.log(error);
      setLoadingDownload((prev) => ({
        ...prev,
        [pickListdata._id]: false,
      }));
    }
  };

  const conditiontoDelete = (data) => {
    if (data.used) return false;
    return true;
  };
  const handleClose = () => {
    setFreezeDialog({ ...freezeDialog, open: false });
  };

  const handleUpdate = async (id, row) => {
    try {
      if (!row.newRemarks) return toast.error("Please mention the remarks!");
      setFreezeLoading(true);
      const payload = {
        freezeRemarks: row.newRemarks,
        freezed: false,
      };

      const { data } = await put(
        URLS.items.toggleFreezeStatus + "/" + id,
        payload
      );
      setFreezeDialog((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          ...data.result,
        },
      }));

      let { item, index, name, value } = freezedItemDetails;

      setFormData(
        formData.map((f, i) => (i == index ? { ...f, [name]: value } : f))
      );
      setInStockItems(inStockItems.filter((i) => i.value !== item?.msn));

      setFormData(
        formData.map((f, i) =>
          i == index
            ? {
                ...f,
                _id: item._id,
                category: item.itemCategory,
                stockType: item.stockType.name,
                msn: item.msn,
                bundleReferenceNumber: "",
                subFamilyName: item.subFamily.name,
                itemDescription: item.itemDescription,
                itemCode: item.itemCode,
                manufacturerPartNumber: item.manufacturerPartNumber,
                brandName: item.brandName.name,
                storageCode: item.storageCode.code,
                palletCode: item.palletCode.code,
              }
            : f
        )
      );

      setFreezedItemDetails({ item: null, index: 0, name: "", value: "" });

      handleClose();
      setFreezeLoading(false);
    } catch (error) {
      console.error(error);
      setFreezeLoading(false);
    }
  };

  const handleChangeRemarks = (e) => {
    const { name, value } = e.target;

    setFreezeDialog((prev) => ({
      ...prev,
      data: { ...prev.data, [name]: value },
    }));
  };

  const searchFormik = useFormik({
    initialValues: {
      fromDate: null,
      toDate: null,
    },
  });

  const handleFind = () => {
    const { endOfDay, startOfDay } = getDateRange(
      searchFormik.values.fromDate,
      searchFormik.values.toDate
    );

    let newList = pickLists.filter(
      (p) =>
        dayjs(new Date(p.createDate)).diff(new Date(startOfDay), "s") > 0 &&
        dayjs(new Date(p.createDate)).diff(new Date(endOfDay), "s") < 0
    );
    setFilteredData(newList);
  };

  const resetFilter = () => {
    setSearch("");
    setFilteredData(pickLists);
    searchFormik.resetForm();
  };

  useEffect(() => {
    if (search?.trim()?.length) {
      setFilteredData(
        pickLists.filter((d) =>
          d.gmsPickListCode?.toLowerCase()?.includes(search.toLowerCase())
        )
      );
    } else {
      setFilteredData(pickLists);
    }
  }, [search]);

  return (
    <>
      {value === 1 && <DashBoardPageHeaderPage title="Pick List" />}
      <PageHeader
        title=""
        showAdd={false}
        collection="Pick List"
        modelName="pickList"
        onFieldAdd={handleOnFieldAdd}
        showAddField={!entryDone}
        module="Pick-list"
      />
      <TabList
        onChange={handleTabChange}
        value={value}
        labels={
          entryToEdit && value === 2
            ? ["Add", "Entries", "View"]
            : ["Add", "Entries"]
        }
      />
      <TabPanel index={0} value={value}>
        {!entryDone ? (
          <>
            <Box component="form">
              <Grid rowSpacing={1} columnSpacing={2} container>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="gmsPickListCode"
                    label="Gms Picklist code"
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormDatePicker
                    formik={entryFormik}
                    label="Pick List Date"
                    name="pickListDate"
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormSelect
                    formik={entryFormik}
                    label="Warehouse code"
                    name="warehouseCode"
                    options={warehouseData}
                    required={true}
                    // onChange={(e) => {
                    //   const selectedValue = e.target.value;
                    //   const selectedWarehouse = warehouseData.find(
                    //     (warehouse) => warehouse._id === selectedValue
                    //   );
                    //   if (selectedWarehouse) {
                    //     entryFormik.setFieldValue(
                    //       "warehouseCode",
                    //       selectedWarehouse._id
                    //     );
                    //     entryFormik.setFieldValue(
                    //       "warehouseName",
                    //       selectedWarehouse.name
                    //     );
                    //   }
                    // }}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="warehouseName"
                    label="Warehouse name"
                    required={true}
                    disabled
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormSelect
                    formik={entryFormik}
                    name="ownerGroup"
                    label="Owner group"
                    options={ownerData}
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormSelect
                    type="text"
                    formik={entryFormik}
                    name="ownerCode"
                    label="Owner code"
                    options={ownerCodes}
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <Stack direction={"column"}>
                    <FormInput
                      type="text"
                      formik={entryFormik}
                      name="bundle"
                      label={`Bundle (${inStockBundles.length})`}
                      placeholder="Enter Bundle"
                      disabled={fetchingData.includes("bundles")}
                    />
                    {fetchingData.includes("bundles") ? (
                      <Typography sx={{ mb: 3 }}>
                        Fetching bundles.....
                      </Typography>
                    ) : null}
                  </Stack>
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <Stack direction={"column"}>
                    <FormInput
                      type="text"
                      formik={entryFormik}
                      name="sku"
                      label={`SKU (${inStockItems.length})`}
                      placeholder="Enter SKU"
                      disabled={fetchingData.includes("items")}
                    />
                    {fetchingData.includes("items") ? (
                      <Typography sx={{ mb: 1 }}>
                        Fetching items.....
                      </Typography>
                    ) : null}
                  </Stack>
                </Grid>

                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="ownerCategory"
                    label="Owner category"
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="remarks"
                    label="Pick List Remarks"
                    required={true}
                  />
                </Grid>

                {formFields
                  ? formFields?.map((f, i) => (
                      <Grid key={i} item xs={12} sm={6} md={4}>
                        <CustomInput
                          onChange={handleCustomInputChange}
                          name={f.inputLabel}
                          value={f.value}
                          required={f.isRequired}
                          label={f.inputLabel}
                          type={f.inputType}
                        />
                      </Grid>
                    ))
                  : null}
                <Grid xs={12} sm={6} md={4} item>
                  <FileSelect
                    selectedFiles={entryFiles}
                    setSelectedFiles={setEntryFiles}
                    name="entryFiles"
                  />
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  margin: "20px 0",
                }}
              >
                <Button
                  onClick={() => {
                    if (!entryFormik.values.bundle) {
                      if (!hasAllValues(entryFormik.values, ["bundle"])) return;
                    } else if (!entryFormik.values.sku) {
                      if (!hasAllValues(entryFormik.values, ["sku"])) return;
                    }

                    setEntryDone(true);
                  }}
                  variant="contained"
                  size="small"
                >
                  Next
                </Button>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark"
                        ? theme.palette.primary.dark
                        : theme.palette.primary.light,
                  }}
                >
                  <TableRow>
                    <TableCell align="center" sx={{ minWidth: "80px" }}>
                      S.No
                    </TableCell>
                    <StyledTableCell align="center">Category</StyledTableCell>
                    <StyledTableCell align="center">MSN</StyledTableCell>

                    {
                      <StyledTableCell align="center">
                        Bundle code refrence no
                      </StyledTableCell>
                    }
                    <StyledTableCell align="center">Stock Type</StyledTableCell>

                    <StyledTableCell align="center">
                      Sub-family name
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Item description
                    </StyledTableCell>
                    <StyledTableCell align="center">Item code</StyledTableCell>
                    <StyledTableCell align="center">
                      Manufacturer part number
                    </StyledTableCell>
                    <StyledTableCell align="center">Brand name</StyledTableCell>
                    <StyledTableCell align="center">
                      Storage code
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Pallet code
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formData.map((v, i) =>
                    loadingData.includes(i) ? (
                      <PuffLoader size={20} />
                    ) : (
                      <TableRow key={i}>
                        <TableCell align="center" sx={{ minWidth: "80px" }}>
                          {i + 1}
                        </TableCell>
                        <StyledTableCell align="center">
                          <Typography>{v.category?.toUpperCase()} </Typography>
                        </StyledTableCell>
                        {v.category !== "Bundled" ? (
                          <StyledTableCell align="center">
                            {v.msn ? (
                              <Button
                                onClick={(e) =>
                                  handleRefreshValue(
                                    { target: { name: "msn", value: v.msn } },
                                    i
                                  )
                                }
                                endIcon={<Refresh fontSize="large" />}
                                variant="outlined"
                                size="small"
                                fullWidth
                                sx={{
                                  textAlign: "center",
                                  textTransform: "none",
                                  height: "40px",
                                }}
                              >
                                {v.msn}
                              </Button>
                            ) : (
                              <CustomSelectVirtualized
                                options={inStockItems}
                                disabled={
                                  v.category && v.category === "Bundled"
                                    ? true
                                    : false
                                }
                                name="msn"
                                value={v.msn}
                                label="MSN"
                                required={true}
                                onChange={(e) => handleChange(e, i)}
                              />
                            )}
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell align="center">
                            {v.msn}
                          </StyledTableCell>
                        )}
                        {v.category === "Bundled" ? (
                          <StyledTableCell>
                            {v.bundleReferenceNumber ? (
                              <Button
                                onClick={(e) =>
                                  handleRefreshValue(
                                    {
                                      target: {
                                        name: "bundleReferenceNumber",
                                        value: v.bundleReferenceNumber,
                                      },
                                    },
                                    i
                                  )
                                }
                                endIcon={<Refresh fontSize="large" />}
                                variant="outlined"
                                size="small"
                                fullWidth
                                sx={{
                                  textAlign: "center",
                                  textTransform: "none",
                                  height: "40px",
                                }}
                              >
                                {v.bundleReferenceNumber}
                              </Button>
                            ) : (
                              <CustomSelect
                                options={inStockBundles}
                                disabled={
                                  v.category !== "Bundled" ? true : false
                                }
                                name="bundleReferenceNumber"
                                value={v.bundleReferenceNumber}
                                label="Bundle code refrence no"
                                required={true}
                                onChange={(e) => handleChange(e, i)}
                              />
                            )}
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell></StyledTableCell>
                        )}
                        <StyledTableCell align="center">
                          <Typography>{v.stockType} </Typography>
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Typography>{v.subFamilyName} </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography>{v.itemDescription} </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography>{v.itemCode} </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography>{v.manufacturerPartNumber} </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography>{v.brandName} </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography>{v.storageCode} </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography>{v.palletCode} </Typography>
                        </StyledTableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                my: 2,
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Stack sx={{ alignSelf: "flex-end" }} direction="row" spacing={2}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => setEntryDone(false)}
                >
                  Back
                </Button>
                <LoadingButton
                  loading={addingPickist}
                  size="small"
                  variant="contained"
                  onClick={debouceSubmit}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Box>

            <Modal
              open={freezeDialog.open}
              title={"Release"}
              onClose={handleClose}
            >
              <Box sx={style}>
                <Typography fontWeight={"bold"} textAlign={"left"}>
                  Release
                </Typography>
                <TableContainer component={Paper} sx={{ mb: 2 }}>
                  <Table>
                    <TableHead
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "dark"
                            ? theme.palette.primary.dark
                            : theme.palette.primary.light,
                      }}
                    >
                      <TableRow>
                        <TableCell align="center">MSN</TableCell>
                        <TableCell align="center">Item Description</TableCell>
                        <TableCell align="center">Previous Remarks</TableCell>
                        <TableCell align="center">Current Remarks</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow>
                        <TableCell align="center">
                          {freezeDialog.data.msn}
                        </TableCell>
                        <TableCell align="center">
                          {freezeDialog.data.itemDescription}
                        </TableCell>
                        <TableCell align="center">
                          {freezeDialog.data.freezeRemarks}
                        </TableCell>

                        <TableCell align="center">
                          <CustomInput
                            name="newRemarks"
                            value={freezeDialog.data.newRemarks}
                            label="Remarks"
                            required={true}
                            onChange={(e) => handleChangeRemarks(e)}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid rowSpacing={1} columnSpacing={2} container>
                  <Grid
                    xs={12}
                    md={12}
                    lg={12}
                    item
                    gap={1}
                    pt={3}
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={handleClose}
                    >
                      Close
                    </Button>

                    <LoadingButton
                      variant="contained"
                      loading={freezeLoading}
                      color="primary"
                      size="small"
                      onClick={() =>
                        handleUpdate(freezeDialog.data._id, freezeDialog.data)
                      }
                    >
                      Release
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
          </>
        )}
      </TabPanel>
      <TabPanel index={1} value={value}>
        {pufferLoading ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "60px",
            }}
          >
            <PuffLoader size={40} color="#14532d" />
          </Box>
        ) : (
          <>
            <Grid rowSpacing={1} columnSpacing={2} container mb={2}>
              <Grid xs={12} sm={6} md={3} item>
                <FormDatePicker
                  formik={searchFormik}
                  label="From Date"
                  name="fromDate"
                />
              </Grid>
              <Grid xs={12} sm={6} md={3} item>
                <FormDatePicker
                  formik={searchFormik}
                  label="To Date"
                  name="toDate"
                />
              </Grid>
              <Grid xs={12} sm={6} md={3} item>
                <Button
                  disabled={
                    !searchFormik.values.fromDate || !searchFormik.values.toDate
                  }
                  size="small"
                  onClick={handleFind}
                  variant="contained"
                  sx={{ mt: 2 }}
                >
                  Find
                </Button>

                <Button
                  size="small"
                  variant="outlined"
                  onClick={resetFilter}
                  sx={{ mt: 2, ml: 1 }}
                  startIcon={<ClearIcon fontSize="small" />}
                >
                  Clear Filter
                </Button>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              mb={1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Grid item xs={12} md={6} lg={3}>
                {/* <CustomSearch
                  data={filteredData}
                  setSearch={setSearch}
                  setSearchFilter={setFilteredData}
                  search={search}
                  placeholder="Gms Picklist Code"
                  keys={["gmsPickListCode"]}
                /> */}

                <CustomInput
                  label="Search Picklist"
                  name="search-picklist"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Grid>
            </Grid>

            <CustomTable
              actions={["delete", "view", "download"]}
              bodyDataModal="entries"
              tableKeys={pickListProcessKeys}
              bodyData={filteredData}
              isDataReady={true}
              onDeleteClick={handleDelete}
              onViewClick={handleEditClick}
              onDownload={handleDownload}
              module="Pick-list"
              deleteCanBeDisabled={true}
              disableDeleteFunction={conditiontoDelete}
              downloadLoading={loadingDownload}
            />
          </>
        )}
      </TabPanel>
      <TabPanel index={2} value={value}>
        <UpdatePickList
          dataToEdit={entryToEdit}
          setSelectValue={setSelectValue}
        />
      </TabPanel>
    </>
  );
}
