import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Modal,
  Box,
  Typography,
  TablePagination,
  Grid,
} from "@mui/material";
import URLS from "../services/urlConstants";
import { del, get, put } from "../services/apiMethods";
import PageHeader from "../components/PageHeader";
import { ROWS_PER_PAGE } from "../utils/utilities";
import CustomSelect from "../components/SecondaryForms/CustomSelect";
import { LoadingButton } from "@mui/lab";
import image from "../assets/images/deleteicon.png";
import dayjs from "dayjs";

const modalStyle = (theme) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 800,
  bgcolor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: 24,
  p: 4,
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
};

export default function RecycleBin({ isDarkMode }) {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [collectionNames, setCollectionNames] = React.useState([]);
  const [selectedCollectionName, setSelectedCollectionName] = useState("all");
  const [restoring, setRestoring] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState([]);
  const [reasonForDeletData, setReasonForDeleteData] = useState(null);

  const handleClose = () => {
    setOpenDeleteModal(false);
  };

  const handleOpenDeleteModal = (d) => {
    setSelectedDocument(d);
    setOpenDeleteModal(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (e) => {
    setSelectedCollectionName(e.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (selectedCollectionName === "all") {
      return setFilteredData(data);
    }
    setFilteredData(
      data.filter((d) => d.nameOfCollection === selectedCollectionName)
    );
  }, [selectedCollectionName]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data } = await get(URLS.archivedDoc.list);
      setData(data.result);
      setPage(0);

      if (selectedCollectionName === "all") {
        setFilteredData(data.result);
      } else {
        setFilteredData(
          data.result.filter(
            (d) => d.nameOfCollection === selectedCollectionName
          )
        );
      }
      let cns = [{ label: "All", value: "all" }];
      for (let d of data.result) {
        if (!cns.some((cn) => cn.value === d.nameOfCollection)) {
          cns.push({ label: d.nameOfCollection, value: d.nameOfCollection });
        }
      }
      setCollectionNames(cns);
    } catch (error) {
      console.error("Error fetching archived documents:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleView = (document, row) => {
    setSelectedDocument(document);
    setReasonForDeleteData(row);
    setViewModalOpen(true);
  };

  const handleRestore = async (id) => {
    try {
      setRestoring([...restoring, id]);
      const { data } = await put(URLS.archivedDoc.restore + "/" + id);
      await fetchData();
    } catch (error) {
      console.error("Error restoring document:", error);
    } finally {
      setRestoring(restoring.filter((i) => i !== id));
    }
  };

  const handleDelete = async () => {
    let id = selectedDocument._id;

    try {
      setDeleting([...deleting, id]);
      const { data } = await del(URLS.archivedDoc.delete + "/" + id);
      await fetchData();
    } catch (error) {
      console.error("Error deleting document:", error);
    } finally {
      setDeleting(deleting.filter((i) => i !== id));
      setOpenDeleteModal(false);
      setSelectedDocument(null);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/* Header */}
      <PageHeader
        title="Recycle Bin"
        addButtonDisabled={true}
        showAdd={false}
        showAddField={false}
      />

      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}
      >
        <Grid item xs={12} sm={12} md={3}>
          <CustomSelect
            name="collectionName"
            options={collectionNames}
            value={selectedCollectionName}
            label="Search By Collection Name"
            onChange={handleFilterChange}
          />
        </Grid>
      </Grid>

      {/* Loading State */}
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="60vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        // Table
        <TableContainer component={Paper} sx={{ borderRadius: 2 }}>
          <Table>
            <TableHead
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.primary.dark
                    : theme.palette.primary.light,
              }}
            >
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell align="center">Collection Name</TableCell>
                <TableCell align="center">Reason For Delete</TableCell>
                <TableCell align="center"> Deleted By</TableCell>
                <TableCell align="center"> Deleted At</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={row._id}
                    hover
                    sx={{
                      "&:hover": {
                        backgroundColor: isDarkMode ? "#333" : "#f5f5f5",
                      },
                    }}
                  >
                    <TableCell align="center">
                      {page * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell align="center">{row.nameOfCollection}</TableCell>
                    <TableCell align="center">
                      {row.reasonForDelete || "Not Available"}
                    </TableCell>
                    <TableCell align="center">{row.deletedBy}</TableCell>
                    <TableCell align="center">
                      {new Date(row.deletedAt).toLocaleString("en-IN", {
                        timeZone: "Asia/Kolkata",
                      })}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        size="small"
                        variant="contained"
                        // color="info"
                        onClick={() => handleView(row.document, row)}
                        sx={{ marginRight: 1 }}
                      >
                        View
                      </Button>
                      {/* <LoadingButton
                        loading={restoring.includes(row._id)}
                        size="small"
                        variant="contained"
                        color="success"
                        onClick={() => handleRestore(row._id)}
                        sx={{ marginRight: 1 }}
                      >
                        Restore
                      </LoadingButton> */}
                      {/* <Button
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={() => handleOpenDeleteModal(row)}
                      >
                        Delete Permanently
                      </Button> */}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

          {!filteredData.length && (
            <Typography sx={{ textAlign: "center", my: 2 }}>
              No data found
            </Typography>
          )}

          <TablePagination
            component="div"
            count={filteredData ? filteredData.length : data.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE}
          />
        </TableContainer>
      )}

      {/* View Modal */}
      <Modal
        open={viewModalOpen}
        onClose={() => setViewModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={(theme) => modalStyle(theme)}>
          <Typography id="modal-title" variant="h6" gutterBottom>
            Document Details
          </Typography>
          <Box
            id="modal-description"
            sx={{
              overflowX: "auto",
              maxHeight: "400px",
              backgroundColor: isDarkMode ? "whitesmoke" : "#f9f9f9",
              color: isDarkMode ? "#fff" : "#000",
              padding: 2,
              borderRadius: 1,
            }}
          >
            <pre
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                fontSize: "12px",
              }}
            >
              {selectedDocument
                ? JSON.stringify(selectedDocument, null, 2)
                : "No document selected"}
            </pre>
          </Box>

          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={() => setViewModalOpen(false)}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* === Delete modal === */}
      <Modal open={openDeleteModal} onClose={handleClose}>
        <Box sx={style}>
          <Box sx={{ textAlign: "center", margin: "10px auto" }}>
            <img src={image} width={50} height={50} />
          </Box>

          <Typography
            textAlign="center"
            sx={{ fontSize: "18px", fontWeight: 700 }}
          >
            Delete Confirmation
          </Typography>
          <Typography sx={{ mt: 2, textAlign: "center" }}>
            This action cannot be reversed. Are you sure you want to delete this
            item permanently?.
          </Typography>
          <Box
            my={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <Button
              size="small"
              color="error"
              variant="contained"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <LoadingButton
              color="primary"
              size="small"
              variant="contained"
              onClick={handleDelete}
              loading={deleting.includes(selectedDocument?._id)}
            >
              Delete
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
