import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

// icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashBoardPageHeaderPage from "../components/DashBoard/DashBoardHeaderPage";
import ShipmentReport from "../components/ShipmentReport";
import ItemHistoryReport from "../components/ItemHistoryReport";
import StorageReport from "../components/StorageReport";
import FaultyReport from "../components/FaultyReport";
import ProcessedReport from "../components/ProcessedReport";
import TotalStockReport from "./TotalStockReport";

const REPORTS = [
  "Inventory",
  "Storage",
  "In-Process",
  "Processed",
  "Shipping Report",
  "Item History",
];

export default function Reports() {
  const [selectedMenu, setSelectedMenu] = useState("Inventory");

  const handleSubmit = (menu) => {
    setSelectedMenu(menu);
  };

  return (
    <>
      <DashBoardPageHeaderPage title="Reports" />
      <Grid container sx={{ height: { xs: "45vh", sm: "40vh", md: "80vh" } }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={2}
          pr={2}
          sx={{
            borderRight: "1px solid #e8e0e0",
            position: "sticky",
            top: 0,
            height: "100%",
          }}
        >
          <Box sx={{ mb: 1 }}>
            {REPORTS.map((itemName, i) => (
              <List
                key={i}
                sx={{ width: "100%" }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItem
                  selected={selectedMenu === itemName}
                  disablePadding={true}
                  sx={{
                    borderRadius: "5px",
                    "&:hover": {
                      borderRight: "3px solid #196838",
                    },
                  }}
                  secondaryAction={
                    <ChevronRightIcon color="action" edge="end" />
                  }
                >
                  <ListItemButton onClick={() => handleSubmit(itemName)}>
                    <ListItemText primary={itemName} />
                  </ListItemButton>
                </ListItem>
              </List>
            ))}
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={10} pl={2}>
          {selectedMenu === "Inventory" ? <TotalStockReport /> : null}{" "}
          {selectedMenu === "Storage" ? <StorageReport /> : null}
          {selectedMenu === "In-Process" ? <FaultyReport /> : null}
          {selectedMenu === "Processed" ? <ProcessedReport /> : null}
          {selectedMenu === "Shipping Report" ? <ShipmentReport /> : null}
          {selectedMenu === "Item History" ? <ItemHistoryReport /> : null}
        </Grid>
      </Grid>
    </>
  );
}
