import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
  Divider,
} from "@mui/material";
import dayjs from "dayjs";
import FormInput from "../forms/FormInput";
import FormDatePicker from "../forms/FormDatePicker";
import CustomInput from "../components/SecondaryForms/CustomInput";
import { get, post, put } from "../services/apiMethods";
import URLS from "../services/urlConstants";
import CustomSelect from "../components/SecondaryForms/CustomSelect";
import { LoadingButton } from "@mui/lab";
import { downloadExcel, hasAllValues, ROWS_PER_PAGE } from "../utils/utilities";
import ShowComponent from "../components/ShowComponent";
import WarehouseContext from "../context/WarehouseContext";
import DashBoardPageHeaderPage from "../components/DashBoard/DashBoardHeaderPage";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { Dialog, DialogTitle } from "@mui/material";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import FileSelect from "../forms/FileSelect";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "200px",
}));

export default function Shipment() {
  const [formData, setFormData] = useState([]);
  const [updateStatuses, setUpdateStatuses] = useState([]);
  const [updating, setUpdating] = useState(-1);
  const [downloadingReport, setDownloadingReport] = useState(false);
  const { selectedWarehouse, selectedOwnerCode, selectedOwnerGroup } =
    useContext(WarehouseContext);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [fetchingData, setFetchingData] = useState(false);
  const [bulkUploadLoader, setBulkUploadLoader] = useState(false);
  const [gettingSample, setGettingSample] = useState(false);
  const [file, setFile] = useState([]);
  const [open, setOpen] = useState(false);
  const [totalData, setTotalData] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUpdateOutbound = async (e, form) => {
    e.preventDefault();
    try {
      if (
        !hasAllValues(form, ["delayRemarks", "shippingRemarks", "deliveryDate"])
      )
        return;
      let payload = {
        itemId: form._id,
        shippingRemarks: form.shippingRemarks,
        updateStatus: form.updateStatus,
        deliveryDate: form.deliveryDate
          ? dayjs(form.deliveryDate).format("YYYY-MM-DD HH:mm:ss")
          : null,
        delayRemarks: form.delayRemarks,
      };
      setUpdating(formData.indexOf(form));
      const { data } = await put(
        URLS.outbound.update + "/" + form.entryId,
        payload
      );
      let entry = data.result;
      setUpdating(-1);
    } catch (error) {
      console.log(error);
      setUpdating(-1);
    }
  };

  const getUpdateStatuses = async () => {
    try {
      const { data } = await get(URLS.updateStatus.list);
      setUpdateStatuses(
        data.result.map((s) => ({ ...s, label: s.name, value: s._id }))
      );
    } catch (error) {}
  };

  useEffect(() => {
    getUpdateStatuses();
  }, []);

  const handleFind = async () => {
    try {
      // if (!selectedWarehouse?.length)
      //   return toast.info("Please select a warehouse!");
      setFetchingData(true);
      const values = entryFormik.values;
      let searchFilter = {};
      if (values.name) {
        searchFilter = {
          awbNo: values.name,
        };
        setPage(0);
      } else if (values.fromDate && values.toDate) {
        searchFilter = {
          fromDate: dayjs(values.fromDate).format("YYYY-MM-DD"),
          toDate: dayjs(values.toDate).format("YYYY-MM-DD"),
        };
        setPage(0);
      }

      const { data } = await get(URLS.outbound.getListToUpdateStatus, {
        params: {
          search: searchFilter,
          warehouses: selectedWarehouse,
          ownerGroups: selectedOwnerGroup,
          ownerCodes: selectedOwnerCode,
        },
      });

      let entries = data.result.allOutbounds;
      let totalItems = 0;

      let totalItemsShipped = 0;

      if (entries && entries.length) {
        let forms = [];
        for (let entry of entries) {
          totalItems += entry.items?.length;
          for (let item of entry.items) {
            if (item.individualItem) {
              totalItemsShipped += 1;
            } else if (item.bundledItem) {
              totalItemsShipped += item.bundledItem?.items?.length;
            }

            let newForm = {
              entryId: entry._id,
              _id: item._id,
              courierPartner: item.courierPartner?.name,
              courierMode: item.courierMode?.name,
              awbNo: item.awbNo,
              warehouse: entry.warehouse,
              pincode: item.pincode,
              updateStatus:
                item?.updateStatus[item.updateStatus.length - 1]?.status?._id ||
                "",
              shippingRemarks: item.shippingRemarks || "NA",
              deliveryDate: item.deliveryDate || null,
              delayRemarks: item.delayRemarks || "",
              date: new Date(entry.dispatchedAt).toLocaleDateString(),
              time: new Date(entry.dispatchedAt).toLocaleTimeString(),
            };
            forms.push(newForm);
          }
        }

        setTotalData(totalItemsShipped);
        setFormData(forms);
      } else {
        toast.info("No outbound data found!");
        setTotalData(0);

        setFormData([]);
        setPage(0);
        setRowsPerPage(10);
      }
      setTotalItems(totalItems);
      setTotalData(totalItems);
      setFile([]);
      setFetchingData(false);
    } catch (error) {
      console.log(error);
      setFetchingData(false);
    }
  };

  const entryFormik = useFormik({
    initialValues: {
      name: "",
      fromDate: null,
      toDate: null,
    },
    onSubmit: handleFind,
  });

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setFormData(
      formData.map((fd, i) => (i === index ? { ...fd, [name]: value } : fd))
    );
  };

  const handleClearFilter = () => {
    entryFormik.resetForm();
    setTotalData(0);
  };

  useEffect(() => {
    if (selectedWarehouse) {
      handleFind();
    }
  }, [selectedWarehouse, selectedOwnerGroup, selectedOwnerCode]);

  const downloadSampleFile = async () => {
    try {
      setGettingSample(true);

      const values = entryFormik.values;
      let searchFilter = {};
      if (values.name) {
        searchFilter = {
          awbNo: values.name,
        };
      } else if (values.fromDate && values.toDate) {
        searchFilter = {
          fromDate: dayjs(values.fromDate).format("YYYY-MM-DD"),
          toDate: dayjs(values.toDate).format("YYYY-MM-DD"),
        };
      }

      const { data } = await get(URLS.outbound.getSampleFileForStatusUpdate, {
        params: {
          search: searchFilter,
          warehouses: selectedWarehouse,
          ownerGroups: selectedOwnerGroup,
          ownerCodes: selectedOwnerCode,
        },
        responseType: "blob",
      });

      downloadExcel(data, "Shipment sample file");
      setGettingSample(false);
    } catch (error) {
      console.log(error);
      setGettingSample(false);
    }
  };

  const downloadReport = async () => {
    try {
      setDownloadingReport(true);

      const values = entryFormik.values;
      let searchFilter = {};
      if (values.name) {
        searchFilter = {
          awbNo: values.name,
        };
      } else if (values.fromDate && values.toDate) {
        searchFilter = {
          fromDate: dayjs(values.fromDate).format("YYYY-MM-DD"),
          toDate: dayjs(values.toDate).format("YYYY-MM-DD"),
        };
      }

      const { data } = await get(
        URLS.outbound.downloadShipmentReportWithAllKeys,
        {
          params: {
            search: searchFilter,
            warehouses: selectedWarehouse,
            ownerGroups: selectedOwnerGroup,
            ownerCodes: selectedOwnerCode,
          },
          responseType: "blob",
        }
      );

      downloadExcel(data, "Shipmment_Report");
      setDownloadingReport(false);
    } catch (error) {
      console.log(error);
      setDownloadingReport(false);
    }
  };

  const handledBulkUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    file.forEach((file) => {
      formData.append("file", file);
    });

    try {
      setBulkUploadLoader(true);
      const { data } = await post(URLS.outbound.updateStatusBulk, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setOpen(false);
      handleFind();
      setBulkUploadLoader(false);
    } catch (error) {
      console.error(error);
      setBulkUploadLoader(false);
    }
  };

  const handleOpenBulkClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <DashBoardPageHeaderPage title="Shipments" />
      <Grid rowSpacing={1} columnSpacing={2} container>
        <Grid xs={12} sm={6} md={3} item>
          <FormInput
            type="text"
            formik={entryFormik}
            name="name"
            label="AWB No"
            required={true}
          />
        </Grid>

        <Grid xs={12} sm={6} md={3} item>
          <FormDatePicker
            formik={entryFormik}
            label="From Date"
            name="fromDate"
          />
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <FormDatePicker formik={entryFormik} label="To Date" name="toDate" />
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <LoadingButton
            size="small"
            onClick={entryFormik.handleSubmit}
            variant="contained"
            sx={{ mt: 2 }}
            loading={fetchingData}
          >
            Find
          </LoadingButton>
          {entryFormik.values.name ? (
            <Button
              size="small"
              onClick={handleClearFilter}
              variant="outlined"
              startIcon={<ClearIcon fontSize="small" />}
              sx={{ mt: 2, ml: 1 }}
            >
              Clear Filter
            </Button>
          ) : null}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 1,
        }}
      >
        <Grid
          item
          xs={4}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: 1,
          }}
        >
          <LoadingButton
            loading={downloadingReport}
            onClick={downloadReport}
            variant="contained"
            size="small"
            startIcon={<DownloadRoundedIcon />}
          >
            Shipment Report
          </LoadingButton>
        </Grid>
        <Grid
          item
          xs={8}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          <LoadingButton
            loading={gettingSample}
            onClick={downloadSampleFile}
            variant="contained"
            size="small"
            startIcon={<DownloadRoundedIcon />}
          >
            Get Sample File
          </LoadingButton>
          {formData.length > 0 && (
            <Button
              onClick={handleOpenBulkClick}
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
            >
              Bulk Update
            </Button>
          )}
        </Grid>
      </Grid>

      <TableContainer
        component={Paper}
        sx={{ marginTop: "10px", opacity: fetchingData ? 0.5 : 1 }}
      >
        <Table>
          <TableHead
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.primary.dark
                  : theme.palette.primary.light,
            }}
          >
            <TableRow>
              <TableCell
                colSpan={100}
                sx={{
                  minWidth: "80px",
                  p: "15px 20px",
                  fontSize: "16px",
                  color: "rgb(255 183 77)",
                }}
              >
                Total Items: {totalData}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                sx={{ minWidth: "80px", fontWeight: "bold" }}
              >
                S.No
              </TableCell>
              <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                Courier Partner Name
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                Courier Mode
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                Courier AWB No.
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                Pincode
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                Update Status
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                Remarks
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                Delivery Date
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                Delay Remarks
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                Date
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ fontWeight: "bold" }}>
                Time
              </StyledTableCell>

              <TableCell
                align="center"
                sx={{ minWidth: "80px", fontWeight: "bold" }}
              >
                Action
              </TableCell>
              {/* <TableCell align="center" sx={{ minWidth: "80px" }}>
                Report
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {formData
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((fd, i) => (
                <TableRow key={i}>
                  <TableCell align="center" sx={{ minWidth: "80px" }}>
                    {page * rowsPerPage + 1 + i}
                  </TableCell>
                  <StyledTableCell align="center">
                    <Typography>{fd.courierPartner}</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Typography>{fd.courierMode}</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Typography>{fd.awbNo}</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Typography>{fd.pincode}</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomSelect
                      name="updateStatus"
                      value={fd.updateStatus}
                      label="Update Status"
                      required={true}
                      options={updateStatuses.filter(
                        (u) => u.warehouse._id == fd.warehouse
                      )}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      name="shippingRemarks"
                      value={fd.shippingRemarks}
                      label="Remarks"
                      required={true}
                      onChange={(e) => handleChange(e, i, "repeat")}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          "& .MuiInputBase-input": {
                            height: "8px",
                          },
                          marginTop: "16px",
                          width: "100%",
                        }}
                        name="deliveryDate"
                        variant="outlined"
                        size="small"
                        label="Delivery Date"
                        slotProps={{
                          textField: {
                            InputLabelProps: { fontSize: "90px" },
                          },
                        }}
                        views={["year", "month", "day"]}
                        format="DD-MM-YYYY"
                        value={fd.deliveryDate ? dayjs(fd.deliveryDate) : null}
                        onChange={(e) =>
                          handleChange(
                            { target: { name: "deliveryDate", value: e } },
                            i
                          )
                        }
                      />
                    </LocalizationProvider>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomInput
                      name="delayRemarks"
                      value={fd.delayRemarks}
                      label="Delay Remarks"
                      onChange={(e) => handleChange(e, i)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Typography>{fd.date}</Typography>
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <Typography>
                      {" "}
                      {moment(fd.time, "HH:mm").format("hh:mm A")}{" "}
                    </Typography>
                  </StyledTableCell>

                  <TableCell align="center" sx={{ minWidth: "80px" }}>
                    <ShowComponent module={"Shipment"} action={"update"}>
                      <LoadingButton
                        size="small"
                        loading={updating === i}
                        onClick={(e) => handleUpdateOutbound(e, fd)}
                        variant="contained"
                        sx={{ mt: 1 }}
                      >
                        Save
                      </LoadingButton>
                    </ShowComponent>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!formData.length && (
          <Typography variant="h6" my={2} textAlign={"center"}>
            No data found
          </Typography>
        )}
      </TableContainer>
      <TablePagination
        fullWidth
        component="div"
        count={totalItems}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={ROWS_PER_PAGE}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={false}
        sx={{
          "& .MuiDialog-paper": {
            width: "400px",
            maxWidth: "100%",
          },
        }}
      >
        <DialogTitle>Bulk Update</DialogTitle>
        <Divider />

        <Grid
          rowSpacing={1}
          columnSpacing={2}
          container
          component={"form"}
          onSubmit={handledBulkUpload}
          sx={{ p: 1 }}
        >
          <Grid xs={12} md={12} lg={12} item>
            <FileSelect
              name={"file"}
              selectedFiles={file}
              setSelectedFiles={setFile}
              accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
            />
          </Grid>
          <Grid
            xs={12}
            md={12}
            lg={12}
            item
            sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}
          >
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={handleClose}
            >
              Close
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              type="submit"
              loading={bulkUploadLoader}
            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
