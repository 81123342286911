import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { Grid, Stack } from "@mui/material";

// custom imports
import CustomInput from "../../components/SecondaryForms/CustomInput";
import PageHeader from "../../components/PageHeader";
import FormDialog from "../../forms/FormDialog";
import CustomTable from "../../components/Tables/CustomTable";
import FormInput from "../../forms/FormInput";

// services
import { del, get, post, put } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";

// data

import CustomSelect from "../../components/SecondaryForms/CustomSelect";
import WarehouseContext from "../../context/WarehouseContext";
import { itemDescriptionTableKeys } from "../../data/TableData/itemDescription";
import CustomSearch from "../../components/Tables/CustomSearch";
import BulkUpdateItemDescription from "../../components/BulkUpdateItemDescription";

export default function ItemDescription() {
  const [isReady, setIsReady] = useState(false);
  const [open, setOpen] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [data, setData] = useState([]);
  const [formFields, setFormFields] = useState(null);
  const [loading, setLoading] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const { allWarehouses } = useContext(WarehouseContext);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [openBulkUpdate, setOpenBulkUpdate] = useState(false);

  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    setWarehouses(
      allWarehouses?.map((s) => ({
        ...s,
        label: s.code,
        value: s._id,
      })) || []
    );

    setSelectedWarehouse(allWarehouses[0]?._id || "");
  }, [allWarehouses]);

  const getData = async () => {
    setIsReady(false);
    try {
      const { data } = await get(URLS.itemDescription.list, {
        params: { warehouse: selectedWarehouse },
      });
      setData(data.result);
    } catch (error) {
      console.error(error);
    }
    setTimeout(() => {
      setIsReady(true);
    }, 300);
  };

  const getFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: {
          collectionName: "itemDescription",
          warehouse: selectedWarehouse,
        },
      });
      setFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };

  // get data on page load
  useEffect(() => {
    if (selectedWarehouse) {
      getData();
      getFormDetails();
    } else {
      setIsReady(true);
    }
  }, [selectedWarehouse]);

  const handleOnFieldAdd = () => {
    getData();
    getFormDetails();
  };

  // create || update actions
  const handleCreateOrUpdate = async (values) => {
    try {
      const payload = {
        ...values,
        dimensions: {
          length: values.length,
          width: values.width,
          height: values.height,
        },
        meta: formFields,
      };

      setLoading(true);
      if (dataToEdit) {
        const { data } = await put(
          URLS.itemDescription.update + "/" + dataToEdit._id,
          payload
        );
      } else {
        const { data } = await post(URLS.itemDescription.create, payload);
      }

      getData();
      handleModalClose();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      warehouse: selectedWarehouse,
      priceNew: dataToEdit?.priceNew || "",
      priceUsed: dataToEdit?.priceUsed || "",
      hsn: dataToEdit?.hsn || "",
      description: dataToEdit?.description || "",
      weight: dataToEdit?.weight || "",
      length: dataToEdit?.dimensions?.length || "",
      width: dataToEdit?.dimensions?.width || "",
      height: dataToEdit?.dimensions?.height || "",
    },
    onSubmit: handleCreateOrUpdate,
    enableReinitialize: true,
  });

  // modal actions
  const openDataAddOrUpdateModal = () => setOpen(true);

  const handleModalClose = () => {
    setOpen(false);
    setTimeout(() => {
      setDataToEdit(null);
      setFormFields(
        formFields?.map((f) => ({
          ...f,
          value: "",
        }))
      );
      formik.resetForm();
    }, 200);
  };

  // edit data actions
  const handleCustomInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields(
      formFields?.map((f) => (f.inputLabel === name ? { ...f, value } : f))
    );
  };

  const handleSetDataToEdit = (data) => {
    setReadOnly(false);
    setDataToEdit(data);
    const fields = data?.meta;
    setFormFields(
      formFields?.map((f) => ({
        ...f,
        value:
          fields?.find((fi) => fi.inputLabel === f.inputLabel)?.value || "",
      }))
    );

    setOpen(true);
  };

  const handleShowData = (data) => {
    setReadOnly(true);
    setDataToEdit(data);
    const fields = data?.meta;
    setFormFields(
      formFields?.map((f) => ({
        ...f,
        value:
          fields?.find((fi) => fi.inputLabel === f.inputLabel)?.value || "",
      }))
    );
    setOpen(true);
  };

  const handleDelete = async (id, reason) => {
    try {
      const res = await del(URLS.itemDescription.delete + "/" + id, {
        params: {
          reasonForDelete: reason,
        },
      });
      const filtered = data.filter((item) => item._id !== id);
      setData(filtered);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeWarehouse = (e) => {
    setSelectedWarehouse(e.target.value);
    formik.setFieldValue("warehouse", e.target.value);
  };

  useEffect(() => {
    if (selectedWarehouse) {
      setFilterData(
        data.filter((item) => item.warehouse?._id === selectedWarehouse)
      );
    } else {
      setFilterData(data);
    }
  }, [data, selectedWarehouse]);

  // useEffect(() => {
  //   setSearchFilter(
  //     data.filter((item) =>
  //       item.description.toLowerCase().includes(search.toLowerCase())
  //     )
  //   );
  // }, [data, search]);

  return (
    <>
      <PageHeader
        collection="Item Description"
        onAddClick={openDataAddOrUpdateModal}
        title="Item Description"
        modelName="itemDescription"
        onFieldAdd={handleOnFieldAdd}
        module="Item Description"
        addButtonDisabled={!selectedWarehouse}
      />

      <Grid
        container
        spacing={2}
        my={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Grid item xs={12} md={4} lg={3}>
          <CustomSelect
            required={true}
            name="warehouse"
            onChange={handleChangeWarehouse}
            label="Select Warehouse"
            options={warehouses}
            value={selectedWarehouse}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3} marginTop={2}>
          <CustomSearch
            data={filterData}
            setSearch={setSearch}
            setSearchFilter={setSearchFilter}
            search={search}
            placeholder="Description"
            keys={["description"]}
          />
        </Grid>
        <Grid item>
          <BulkUpdateItemDescription
            openBulkUpdate={openBulkUpdate}
            setOpenBulkUpdate={setOpenBulkUpdate}
            getInboundEntries={getData}
          />
        </Grid>
      </Grid>

      <CustomTable
        actions={["edit", "delete", "view"]}
        bodyDataModal="item description"
        isDataReady={isReady}
        bodyData={search ? searchFilter : filterData}
        tableKeys={itemDescriptionTableKeys}
        onDeleteClick={handleDelete}
        onEditClick={handleSetDataToEdit}
        onViewClick={handleShowData}
        module="Item Description"
        formFields={formFields}
      />

      {/* === Add/update form modal === */}
      <FormDialog
        readOnly={readOnly}
        open={open}
        onClose={handleModalClose}
        submitButtonTitle={dataToEdit ? "Update" : "Add"}
        formik={formik}
        formTitle={dataToEdit ? "Update Item Description" : "Add Description"}
        adding={loading}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="priceNew"
              type="number"
              formik={formik}
              label="Price New"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="priceUsed"
              type="number"
              formik={formik}
              label="Price Used"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInput
              required={true}
              name="hsn"
              type="text"
              formik={formik}
              label="HSN"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormInput
              name="weight"
              type="text"
              formik={formik}
              label="Weight"
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Stack columnGap={1} direction="row">
              <FormInput
                name="length"
                type="text"
                formik={formik}
                label="Length"
                required={true}
              />
              <FormInput
                name="width"
                type="text"
                formik={formik}
                label="Width"
                required={true}
              />
              <FormInput
                name="height"
                type="text"
                formik={formik}
                label="Height"
                required={true}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormInput
              name="description"
              type="text"
              formik={formik}
              label="Description"
              required={true}
              multiline={true}
            />
          </Grid>
          {formFields
            ? formFields?.map((f, i) => (
                <Grid key={i} item xs={12} sm={6}>
                  <CustomInput
                    onChange={handleCustomInputChange}
                    name={f.inputLabel}
                    value={f.value}
                    required={f.isRequired}
                    label={
                      f.inputLabel.charAt(0).toUpperCase() +
                      f.inputLabel.slice(1)
                    }
                    type={f.inputType}
                  />
                </Grid>
              ))
            : null}
        </Grid>
      </FormDialog>
    </>
  );
}
