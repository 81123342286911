import { Box, Button, Divider, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import { Dialog, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FileSelect from "../forms/FileSelect";
import { useFormik } from "formik";
import FormSelect from "../forms/FormSelect";
import URLS from "../services/urlConstants";
import { get, post } from "../services/apiMethods";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { toast } from "react-toastify";
import { downloadExcel } from "../utils/utilities";
import WarehouseContext from "../context/WarehouseContext";

export default function BulkUpdateItemDescription({
  openBulkUpdate = false,
  setOpenBulkUpdate = () => {},
  setInboundEntries = () => {},
  getInboundEntries = () => {},
}) {
  const { allWarehouses } = useContext(WarehouseContext);
  const [file, setFile] = useState([]);
  const [gettingSample, setGettingSample] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [bulkUpdateLoader, setBulkUpdateLoader] = useState(false);
  const [warehouseData, setWarehouseData] = useState([]);
  const [type, setType] = useState("");

  const getWarehouseDetails = async () => {
    try {
      const { data } = await get(URLS.warehouse.list);
      setWarehouseData(
        data?.result.map((w) => ({
          ...w,
          label: w.code,
          value: w._id,
        }))
      );

      if (allWarehouses?.length === 1) {
        entryFormik.setFieldValue("warehouseCode", data.result[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getWarehouseDetails();
  }, []);

  const handleOpenBulkClick = (modalType) => {
    setType(modalType);
    setOpenBulkUpdate(true);
  };
  const handleClose = () => {
    setOpenBulkUpdate(false);
    setFile([]);
    entryFormik.resetForm();
    setShowFile(false);
  };

  const downloadSampleFile = async () => {
    try {
      if (type === "update" && !entryFormik.values.warehouseCode) {
        return toast.error("Please select the warehouse");
      }

      setGettingSample(true);

      if (type === "add") {
        const { data, status } = await get(
          URLS.itemDescription.getBulkAddSampleFile,
          {
            validateStatus: (status) => status < 500,
            responseType: "blob",
          }
        );
        if (status === 200) {
          downloadExcel(data, "Itemdescription add.xlsx");
          setShowFile(true);
        } else {
          const errorText = await new Response(data).text();
          toast.error(JSON.parse(errorText)?.message);
        }
      } else {
        const { data, status } = await get(
          URLS.itemDescription.getItemDescriptionSampleFile,
          {
            params: {
              warehouseId: entryFormik.values.warehouseCode,
            },
            validateStatus: (status) => status < 500,
            responseType: "blob",
          }
        );

        if (status === 200) {
          downloadExcel(data, "Itemdescription update.xlsx");

          setShowFile(true);
        } else {
          const errorText = await new Response(data).text();
          toast.error(JSON.parse(errorText)?.message);
        }
      }

      setGettingSample(false);
    } catch (error) {
      console.log(error);
      setGettingSample(false);
    }
  };

  const entryFormik = useFormik({
    initialValues: {
      warehouseCode: "",
    },
    onSubmit: downloadSampleFile,
    validateOnBlur: false,
  });

  const handledBulkUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    file.forEach((file) => {
      formData.append("file", file);
      formData.append("warehouseId", entryFormik.values.warehouseCode);
    });

    try {
      setBulkUpdateLoader(true);
      if (type === "add") {
        const { data } = await post(
          URLS.itemDescription.bulkAddItemDescription,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (data) {
          setInboundEntries(
            data.result.map((e) => ({ ...e, totalItems: e.length }))
          );
          getInboundEntries();
        }
      } else {
        const { data } = await post(
          URLS.itemDescription.updateItemDescriptionBulk,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (data) {
          setInboundEntries(
            data.result.map((e) => ({ ...e, totalItems: e.length }))
          );
          getInboundEntries();
        }
      }
      entryFormik.resetForm();
      handleClose();
      setBulkUpdateLoader(false);
    } catch (error) {
      console.error(error);
      setBulkUpdateLoader(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => handleOpenBulkClick("add")}
        sx={{ mt: 2, mr: 2 }}
        size="small"
        startIcon={<Add />}
      >
        Bulk Add
      </Button>

      <Button
        variant="contained"
        onClick={() => handleOpenBulkClick("update")}
        sx={{ mt: 2 }}
        size="small"
        startIcon={<Add />}
      >
        Bulk Update
      </Button>

      <Dialog
        open={openBulkUpdate}
        onClose={handleClose}
        maxWidth={false}
        sx={{
          "& .MuiDialog-paper": {
            width: "500px",
            maxWidth: "100%",
          },
        }}
      >
        <DialogTitle>{type === "add" ? "Bulk Add" : "Bulk Update"}</DialogTitle>
        <Divider />

        <Grid
          rowSpacing={0}
          columnSpacing={1}
          container
          component={"form"}
          onSubmit={handledBulkUpload}
          sx={{ p: 1 }}
        >
          {type === "update" && (
            <Grid xs={12} item>
              <FormSelect
                formik={entryFormik}
                label="Warehouse code"
                name="warehouseCode"
                options={warehouseData}
                required={true}
              />
            </Grid>
          )}

          <Grid
            xs={12}
            md={12}
            lg={12}
            item
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {" "}
            <LoadingButton
              loading={gettingSample}
              onClick={downloadSampleFile}
              variant="contained"
              sx={{ mt: 2 }}
              disabled={type === "update" && !entryFormik.values.warehouseCode}
              size="small"
              startIcon={<DownloadRoundedIcon />}
            >
              Get Sample File
            </LoadingButton>
          </Grid>
          {showFile && (
            <>
              {type === "add" && (
                <Grid xs={12} item>
                  <FormSelect
                    formik={entryFormik}
                    label="Warehouse code"
                    name="warehouseCode"
                    options={warehouseData}
                    required={true}
                  />
                </Grid>
              )}
              <Grid xs={12} md={12} lg={12} item>
                <FileSelect
                  name={"file"}
                  disabled={!entryFormik.values}
                  selectedFiles={file}
                  setSelectedFiles={setFile}
                  accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                />
              </Grid>
            </>
          )}

          <Grid
            xs={12}
            md={12}
            lg={12}
            item
            sx={{ display: "flex", justifyContent: "flex-end", gap: 1, mt: 2 }}
          >
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={handleClose}
            >
              Close
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              type="submit"
              disabled={file.length === 0}
              loading={bulkUpdateLoader}
            >
              {type === "add" ? "Submit" : "Update"}
            </LoadingButton>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
