export const activityCodeKeys = [
  {
    name: "Code",
    key: "code",
  },
  {
    name: "Description",
    key: "description",
  },
  {
    name: "Can Be Moved To Stock",
    key: "canBeMovedToStock",
  },
  {
    name: "Show In Processed",
    key: "showInProcessed",
  },
];
