// import React from "react";
// import { TextField, useTheme } from "@mui/material";

// export default function CustomInput({
//   name,
//   label = "default label",
//   required = false,
//   onChange = () => {},
//   value,
//   ...rest
// }) {
//   const theme = useTheme();

//   return (
//     <TextField
//       required={required}
//       id={name}
//       name={name}
//       label={label}
//       placeholder={`Enter ${label}`}
//       fullWidth
//       value={value || ""}
//       onChange={onChange}
//       sx={{
//         mt: 2,
//         borderWidth: 1,
//         borderRadius: theme.shape.borderRadius,
//       }}
//       variant="outlined"
//       InputLabelProps={{
//         shrink: true,
//       }}
//       InputProps={{
//         multiple: true,
//         style: {
//           borderWidth: 1,
//           height: "40px",
//           borderRadius: theme.shape.borderRadius,
//         },
//       }}
//       {...rest}
//     />
//   );
// }

import React, { forwardRef } from "react";
import { TextField, useTheme } from "@mui/material";

const CustomInput = forwardRef(function CustomInput(
  {
    name,
    label = "default label",
    required = false,
    multiline = false,
    rows = 3,
    onChange = () => {},
    value,
    ...rest
  },
  ref
) {
  const theme = useTheme();

  return (
    <TextField
      required={required}
      id={name}
      name={name}
      label={label}
      multiline={multiline}
      rows={multiline ? rows : 1}
      placeholder={`Enter ${label}`}
      fullWidth
      value={value || ""}
      onChange={onChange}
      inputRef={ref} // Attach ref here
      sx={{
        mt: 2,
        borderWidth: 1,
        borderRadius: theme.shape.borderRadius,
      }}
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        multiple: true,
        style: {
          borderWidth: 1,
          height: multiline ? "auto" : "40px",
          borderRadius: theme.shape.borderRadius,
        },
      }}
      {...rest}
    />
  );
});

export default CustomInput;
