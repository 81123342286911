import React, { useContext, useEffect, useState } from "react";
import TabList from "../../components/Tabs/TabList";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";
import URLS from "../../services/urlConstants";
import { get, put, del } from "../../services/apiMethods";
import TabPanel from "../../components/Tabs/TabPanel";
import { unbundleTableKeys } from "../../data/TableData/unbundle";
import CustomTable from "../../components/Tables/CustomTable";
import { LoadingButton } from "@mui/lab";
import { hasAllValues, ROWS_PER_PAGE } from "../../utils/utilities";
import DashBoardPageHeaderPage from "../../components/DashBoard/DashBoardHeaderPage";
import WarehouseContext from "../../context/WarehouseContext";
import { Delete } from "@mui/icons-material";
import DeleteModal from "../../components/DeleteModal";
import { PuffLoader } from "react-spinners";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { Close } from "@mui/icons-material";
import { toast } from "react-toastify";

const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    width: "600px",
    maxWidth: "100%",
    color: "#000",
  },
});

const StyledTableCell = styled(TableCell)(() => ({
  // minWidth: "200px",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: theme.spacing(2),
  position: "fixed",
  bottom: 0,
  right: 0,
  left: 0,
  padding: theme.spacing(2),
  zIndex: 1000,
}));

export default function Unbundle() {
  const { selectedWarehouse, selectedOwnerCode, selectedOwnerGroup } =
    useContext(WarehouseContext);
  const [formData, setFormData] = useState([]);
  const [stockType, setStockType] = useState([]);
  const [storageCode, setStorageCode] = useState([]);
  const [palletCode, setPalletCode] = useState([]);
  const [value, setSelectValue] = useState(0);
  const [bundleData, setBundleData] = useState([]);
  const [showTabs, setShowTabs] = useState(false);
  const [addingSave, setAddingSave] = useState(false);
  const [deletingBundle, setDeletingBundle] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pufferLoading, setPufferLoading] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [reasonForDelete, setReasonForDelete] = useState("");
  const theme = useTheme();
  let fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = (images) => {
    setImages(images);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setReasonForDelete("");
  };

  const getBundleData = async () => {
    try {
      setPufferLoading(true);
      const { data } = await get(URLS.bundle.list, {
        params: {
          warehouses: selectedWarehouse,
          ownerCodes: selectedOwnerCode,
          ownerGroups: selectedOwnerGroup,
          search: { inStock: true },
        },
      });

      setBundleData(
        data.result
          ?.filter((b) => b.items.length)
          .map((b) => ({
            ...b,
            createdAt: new Date(b.createdAt).toLocaleString("en-IN", {
              timeZone: "Asia/Kolkata",
            }),
          })) || []
      );
      setPufferLoading(false);
    } catch (error) {
      console.log(error);
      setPufferLoading(false);
    }
  };
  useEffect(() => {
    const formData = [];

    if (dataToEdit?.items?.length) {
      for (let item of dataToEdit.items) {
        formData.push({
          _id: item._id,
          msn: item.msn,
          itemDescription: item.itemDescription,
          itemCode: item.itemCode,
          newPalletCode: item.palletCode?._id,
          newStockType: item.stockType?._id,
          newStorageCode: item.storageCode?._id,
        });
      }
    }

    setFormData(formData);
  }, [dataToEdit]);

  const getData = async () => {
    try {
      const stockPromise = get(URLS.stockType.list);
      const storageCodePromise = get(URLS.storageCode.list, {
        params: {
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });
      const palletCodePromise = get(URLS.palletCode.list, {
        params: {
          warehouseId: selectedWarehouse,
          ownerCode: selectedOwnerCode,
        },
      });

      const [stockTypeData, storageCodeData, palletCodeData] =
        await Promise.all([
          stockPromise,
          storageCodePromise,
          palletCodePromise,
        ]);

      setStockType(
        stockTypeData?.data?.result?.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );

      setStorageCode(
        storageCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setPalletCode(
        palletCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    getBundleData();
  }, [selectedWarehouse, selectedOwnerCode, selectedOwnerGroup]);

  const handleTabChange = (e, newValue) => {
    setSelectValue(newValue);
    if (newValue === 0) {
      setShowTabs(false);
    }
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    setFormData(
      formData.map((fd, i) => (i === index ? { ...fd, [name]: value } : fd))
    );
  };

  const handleUnbundleClick = (data) => {
    setDataToEdit(data);
    setShowTabs(true);
    setSelectValue(1);
    setPage(0);
  };
  const handlePrevious = () => {
    setShowTabs(false);
    setSelectValue(0);
  };

  const handleUpdate = async (item) => {
    try {
      if (!hasAllValues(item)) return;
      setAddingSave((prv) => ({
        ...prv,
        [item._id]: true,
      }));

      let payload = {
        stockType: item.newStockType,
        palletCode: item.newPalletCode,
        storageCode: item.newStorageCode,
      };

      const { data } = await put(URLS.items.update + "/" + item._id, payload);
      getBundleData();
      // setSelectValue(0);
      // setShowTabs(false);
    } catch (error) {
      console.log(error);
    }
    setAddingSave((prv) => ({
      ...prv,
      [item._id]: false,
    }));
  };

  const handleDeleteBundle = async () => {
    setDeletingBundle(true);
    if (!reasonForDelete) {
      toast.error("Reason for deletion is required field");
      setDeletingBundle(false);
      return;
    }
    try {
      const { data } = await del(URLS.bundle.delete + "/" + dataToEdit._id, {
        params: {
          reasonForDelete: reasonForDelete,
        },
      });
      setOpenDeleteModal(false);
      setDeletingBundle(false);
      setReasonForDelete("");
      getBundleData();
      setSelectValue(0);
      setShowTabs(false);
    } catch (error) {
      console.log(error);
      setDeletingBundle(false);
    }
  };

  return (
    <>
      <DashBoardPageHeaderPage title="All Bundles" />

      {showTabs ? (
        <TabList
          onChange={handleTabChange}
          value={value}
          labels={["List", "View"]}
        />
      ) : (
        <TabList onChange={handleTabChange} value={value} labels={["List"]} />
      )}

      <TabPanel index={0} value={value}>
        {pufferLoading ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "60px",
            }}
          >
            <PuffLoader size={40} color="#14532d" />
          </Box>
        ) : (
          // <CustomTable
          //   actions={["view"]}
          //   bodyData={bundleData}
          //   bodyDataModal="unbundle"
          //   tableKeys={unbundleTableKeys}
          //   onViewClick={handleUnbundleClick}
          //   module="Unbundle"
          // />
          <TableContainer component={Paper}>
            <Table>
              <TableHead
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.primary.dark
                      : theme.palette.primary.light,
                }}
              >
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Bundle Reference code</TableCell>
                  <TableCell align="center">Added On</TableCell>

                  <TableCell align="center">Bundle Type</TableCell>
                  <TableCell align="center">Stock Type</TableCell>
                  <TableCell align="center">Pallet Code</TableCell>
                  <TableCell align="center">Storage Code</TableCell>
                  <TableCell align="center">Images</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bundleData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => (
                    <TableRow key={i}>
                      <TableCell align="center">
                        {" "}
                        {page * rowsPerPage + 1 + i}
                      </TableCell>
                      <TableCell align="center">
                        {row.bundleReferenceNumber}
                      </TableCell>
                      <TableCell align="center">{row.createdAt}</TableCell>
                      <TableCell align="center">
                        {row.bundleType.code}
                      </TableCell>

                      <TableCell align="center">{row.stockType.name}</TableCell>
                      <TableCell align="center">
                        {row.palletCode.code}
                      </TableCell>
                      <TableCell align="center">
                        {row.storageCode.code}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        <Tooltip title="View">
                          <IconButton
                            size="small"
                            onClick={() => handleOpen(row?.images)}
                          >
                            <RemoveRedEyeRoundedIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>

                      <TableCell align="center">
                        {" "}
                        <Tooltip title="View">
                          <IconButton
                            size="small"
                            onClick={() => handleUnbundleClick(row)}
                          >
                            <RemoveRedEyeRoundedIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              fullWidth
              component="div"
              count={bundleData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={ROWS_PER_PAGE}
            />
          </TableContainer>
        )}

        <CustomDialog fullScreen={fullScreen} open={open} onClose={handleClose}>
          <DialogTitle
            sx={{
              fontSize: "15px",
            }}
          >
            View Images
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 12,
              top: 8,
            }}
          >
            <Close />
          </IconButton>

          <Divider sx={{ mb: 2 }} />

          <DialogContent>
            <Grid container spacing={1}>
              {images.length === 0 ? (
                <Grid item xs={12}>
                  <Typography align="center">
                    No data available at the moment
                  </Typography>
                </Grid>
              ) : (
                images.map(
                  (image, index) => (
                    console.log(image, "iiiiii"),
                    (
                      <Grid item xs={12} sm={12} md={12} key={index}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "8px",
                            borderRadius: "5px",
                            marginTop: "5px",
                            background: "#5fa5f661",
                            cursor: "pointer",
                          }}
                          onClick={() => window.open(image, "_blank")}
                        >
                          <Typography>{`File ${index + 1}`}</Typography>
                          <Stack direction="row" spacing={2}>
                            <IconButton
                              onClick={() => window.open(image, "_blank")}
                            >
                              <RemoveRedEyeRoundedIcon fontSize="small" />
                            </IconButton>
                          </Stack>
                        </Box>
                      </Grid>
                    )
                  )
                )
              )}
            </Grid>
            <Box sx={{ textAlign: "right", mt: 2 }}>
              <Button
                size="small"
                onClick={handleClose}
                variant="contained"
                color="error"
              >
                Cancel
              </Button>
            </Box>
          </DialogContent>
        </CustomDialog>
      </TabPanel>

      {showTabs && (
        <TabPanel index={1} value={value}>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid item xs={12} md={9}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handlePrevious}
                >
                  Back
                </Button>
                <TablePagination
                  component="div"
                  count={formData.length}
                  page={page}
                  rowsPerPageOptions={ROWS_PER_PAGE}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table>
              <TableHead
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.primary.dark
                      : theme.palette.primary.light,
                }}
              >
                <TableRow>
                  <TableCell sx={{ minWidth: "80px" }} align="center">
                    S.No
                  </TableCell>
                  <StyledTableCell align="center">MSN</StyledTableCell>

                  <StyledTableCell align="center">
                    Item Description
                  </StyledTableCell>
                  <StyledTableCell align="center">Item Code</StyledTableCell>
                  <StyledTableCell align="center">
                    New Stock Type
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    New Storage code
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    New Pallet code
                  </StyledTableCell>

                  <TableCell sx={{ minWidth: "80px" }} align="center">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ minWidth: "80px" }} align="center">
                      {index + 1}
                    </TableCell>
                    <StyledTableCell align="center">{item.msn}</StyledTableCell>

                    <StyledTableCell align="center">
                      {item.itemDescription}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {item.itemCode}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        options={stockType}
                        name="newStockType"
                        label="New Stock Type"
                        value={item.newStockType}
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        options={storageCode}
                        name="newStorageCode"
                        label="New Storage Code"
                        value={item.newStorageCode}
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <CustomSelect
                        options={palletCode}
                        name="newPalletCode"
                        label="New Pallet Code"
                        value={item.newPalletCode}
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </StyledTableCell>
                    <TableCell sx={{ minWidth: "80px" }} align="center">
                      <LoadingButton
                        loading={addingSave[item._id]}
                        size="small"
                        variant="contained"
                        onClick={() => handleUpdate(item)}
                      >
                        Update
                      </LoadingButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {formData.length ? (
            <Grid container>
              <Grid item xs={12} md={12}>
                <StyledBox
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark"
                        ? "rgba(32,33,32,1)"
                        : theme.palette.grey[100],
                  }}
                >
                  <Button
                    size="small"
                    onClick={() => setOpenDeleteModal(true)}
                    variant="contained"
                    color="error"
                    startIcon={<Delete fontSize="small" />}
                  >
                    Delete
                  </Button>
                </StyledBox>
              </Grid>
            </Grid>
          ) : null}
        </TabPanel>
      )}
      <DeleteModal
        isLoading={deletingBundle}
        open={openDeleteModal}
        reasonForDelete={reasonForDelete}
        setReasonForDelete={setReasonForDelete}
        onClose={handleCloseDeleteModal}
        onDeleteClick={handleDeleteBundle}
        description="Proceeding with the deletion of this data may result in information
        inconsistency across other documents and is irreversible. Are you
        certain you wish to delete this item?"
      />
    </>
  );
}
