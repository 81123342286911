import React, { useContext, useEffect, useState } from "react";
import { get } from "../services/apiMethods";
import URLS from "../services/urlConstants";
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ShowComponent from "./ShowComponent";
import { LoadingButton } from "@mui/lab";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { downloadExcel, separateAtCapital } from "../utils/utilities";
import WarehouseContext from "../context/WarehouseContext";
import FormDatePicker from "../forms/FormDatePicker";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { PuffLoader } from "react-spinners";
import FormInput from "../forms/FormInput";
import { FileDownload, Search } from "@mui/icons-material";
import { toast } from "react-toastify";

export default function ShipmentReport() {
  const {
    selectedWarehouse,
    selectedOwnerCode,
    selectedOwnerGroup,
    allWarehouses,
    allOwnerCodes,
  } = useContext(WarehouseContext);
  const [keys, setKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pufferLoading, setPufferLoading] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);
  const [searchFilter, setSearchFilter] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [downloading, setDownloading] = useState(false);

  const getKeys = async () => {
    try {
      setPufferLoading(true);
      const { data } = await get(URLS.outbound.getAllKeys, {
        params: {
          warehouses: selectedWarehouse,
        },
      });
      const itemKeys = data.result.itemKeys;
      const inboundKeys = data.result.inboundKeys;
      const outboundKeys = data.result.outboundKeys;
      setKeys([...itemKeys, ...inboundKeys, ...outboundKeys]);
      setShowDate(true);
      setPufferLoading(false);
    } catch (error) {
      console.log(error);
      setPufferLoading(false);
    }
  };

  useEffect(() => {
    getKeys();
  }, [selectedWarehouse]);

  const handleToggle = (key) => {
    const updatedItemKeys = keys.map((i) =>
      i.key === key ? { ...i, checked: !i.checked } : i
    );
    setKeys(updatedItemKeys);
    if (searchFilter.length) {
      setSearchFilter(
        searchFilter.map((i) =>
          i.key === key ? { ...i, checked: !i.checked } : i
        )
      );
    }

    const allChecked = updatedItemKeys.every((i) => i.checked);
    setCheckAll(allChecked);
  };

  const handleDownloadReport = async (values) => {
    try {
      if (!selectedWarehouse.length)
        return toast.info("Please select a warehouse!");
      setLoading(true);
      let searchFilter = {};
      if (values.name) {
        searchFilter = {
          awbNo: values.name,
        };
      } else if (values.fromDate && values.toDate) {
        searchFilter = {
          fromDate: dayjs(values.fromDate).format("YYYY-MM-DD"),
          toDate: dayjs(values.toDate).format("YYYY-MM-DD"),
        };
      }
      const { data } = await get(URLS.outbound.downloadShipmentReport, {
        params: {
          keys,
          search: searchFilter,
          warehouses: selectedWarehouse,
          ownerGroups: selectedOwnerGroup,
          ownerCodes: selectedOwnerCode,
        },
        responseType: "blob",
      });
      downloadExcel(
        data,
        `${selectedWarehouse
          ?.map((w) => allWarehouses.find((wh) => wh._id === w)?.code)
          ?.join(",")}_${selectedOwnerCode
          .map((o) => allOwnerCodes.find((oc) => oc._id == o)?.name)
          ?.join(",")}_Shipping Report_${dayjs(new Date(Date.now())).format(
          "DD/MM/YYYY"
        )}`
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const entryFormik = useFormik({
    initialValues: {
      name: "",
      fromDate: null,
      toDate: null,
    },
    onSubmit: handleDownloadReport,
  });

  const handleCheckChange = () => {
    setCheckAll((prev) => {
      const newCheckedState = !prev;
      setKeys(
        keys.map((item) => ({
          ...item,
          checked: newCheckedState,
        }))
      );
      return newCheckedState;
    });
  };

  const handleDownloadReportOrderWise = async () => {
    try {
      if (!selectedWarehouse.length)
        return toast.info("Please select a warehouse!");
      setDownloading(true);
      let searchFilter = {};
      let values = entryFormik.values;
      if (values.name) {
        searchFilter = {
          awbNo: values.name,
        };
      } else if (values.fromDate && values.toDate) {
        searchFilter = {
          fromDate: dayjs(values.fromDate).format("YYYY-MM-DD"),
          toDate: dayjs(values.toDate).format("YYYY-MM-DD"),
        };
      }
      const { data } = await get(
        URLS.outbound.downloadShipmentReportOrderWise,
        {
          params: {
            search: searchFilter,
            warehouses: selectedWarehouse,
            ownerCodes: selectedOwnerCode,
            ownerGroups: selectedOwnerGroup,
          },
          responseType: "blob",
        }
      );
      downloadExcel(
        data,
        `${selectedWarehouse
          ?.map((w) => allWarehouses.find((wh) => wh._id === w)?.code)
          ?.join(",")}_${selectedOwnerCode
          .map((o) => allOwnerCodes.find((oc) => oc._id == o)?.name)
          ?.join(",")}_Shipping Report_${dayjs(new Date(Date.now())).format(
          "DD/MM/YYYY"
        )}`
      );
      setDownloading(false);
    } catch (error) {
      console.log(error);
      setDownloading(false);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    if (value.trim() !== "") {
      if (keys.length > 0) {
        setSearchFilter(
          keys.filter((ele) =>
            ele.label.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
      }
    } else {
      setSearchFilter([]);
    }
  };
  return (
    <>
      {pufferLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "60px",
          }}
        >
          <PuffLoader size={40} color="#14532d" />
        </Box>
      ) : (
        <>
          {showDate && (
            <Grid rowSpacing={1} columnSpacing={2} container>
              <Grid xs={12} sm={6} md={3} item>
                <FormInput
                  type="text"
                  formik={entryFormik}
                  name="name"
                  label="AWB No"
                  required={true}
                />
              </Grid>
              <Grid xs={12} sm={6} md={3} item>
                <FormDatePicker
                  formik={entryFormik}
                  label="From Date"
                  name="fromDate"
                />
              </Grid>
              <Grid xs={12} sm={6} md={3} item>
                <FormDatePicker
                  formik={entryFormik}
                  label="To Date"
                  name="toDate"
                />
              </Grid>
              <Grid xs={12} sm={6} md={3} item alignSelf={"center"}>
                {keys.length ? (
                  <ShowComponent module={"Reports"} action={"view"}>
                    <LoadingButton
                      onClick={entryFormik.handleSubmit}
                      loading={loading}
                      variant="contained"
                      size="small"
                      startIcon={<FileDownload />}
                      sx={{ top: 6 }}
                    >
                      Download Excel
                    </LoadingButton>
                  </ShowComponent>
                ) : null}
              </Grid>
            </Grid>
          )}
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
              mb: 1,
              flexDirection: { xs: "row", md: "row" },
            }}
          >
            <Grid
              item
              xs={6}
              md={6}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-start" },
              }}
            >
              <ShowComponent module={"Reports"} action={"view"}>
                <LoadingButton
                  onClick={handleDownloadReportOrderWise}
                  loading={downloading}
                  variant="contained"
                  size="small"
                  startIcon={<FileDownload />}
                >
                  Download Excel - Customer
                </LoadingButton>
              </ShowComponent>
            </Grid>

            <Grid
              item
              xs={6}
              md={6}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-end" },
                alignItems: "center",
                gap: 2,
              }}
            >
              <ShowComponent module={"Reports"} action={"update"}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkAll}
                      onChange={handleCheckChange}
                      size="small"
                      sx={{ color: "000" }}
                    />
                  }
                  label={checkAll ? "Uncheck All" : "Check All"}
                />
              </ShowComponent>
              <TextField
                size="small"
                value={search}
                onChange={handleSearch}
                placeholder="Search here..."
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Divider />
          {search && !searchFilter.length && (
            <Typography sx={{ mt: 2, textAlign: "center" }}>
              No results for "{search}"
            </Typography>
          )}

          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
            }}
          >
            <Grid
              container
              sx={{
                overflowY: "auto",
                height: "65vh",
                scrollbarWidth: "none",
              }}
            >
              {(search ? searchFilter : keys).map((value, index) => {
                const labelId = `checkbox-list-label-${value.key}`;

                return (
                  <Grid
                    key={value.key + index.toString()}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                  >
                    <ListItem disablePadding>
                      <ListItemButton
                        role={undefined}
                        onClick={() => handleToggle(value.key)}
                      >
                        <Checkbox
                          edge="start"
                          checked={value.checked}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                        {/* </ListItemIcon> */}
                        <ListItemText id={labelId} primary={value.label} />
                      </ListItemButton>
                    </ListItem>
                  </Grid>
                );
              })}
            </Grid>
          </List>
        </>
      )}
    </>
  );
}
