import { Grid, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import FileSelect from "../../forms/FileSelect";
import { LoadingButton } from "@mui/lab";
import { get, post } from "../../services/apiMethods";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import URLS from "../../services/urlConstants";
import { downloadExcel } from "../../utils/utilities";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import TabList from "../../components/Tabs/TabList";
import FormSelect from "../../forms/FormSelect";
import PageHeader from "../../components/PageHeader";

const Item_Option = [
  {
    label: "All",
    value: "All",
  },

  {
    label: "Storage",
    value: "Storage",
  },
  {
    label: "In-Process",
    value: "In-Process",
  },
  {
    label: "Processed",
    value: "Processed",
  },
];

export default function BulkUpdateEntryOrItem({
  setOpenBulkUpdate = () => {},
}) {
  const [file, setFile] = useState([]);
  const [gettingSample, setGettingSample] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [bulkUpdateLoader, setBulkUpdateLoader] = useState(false);
  const [value, setSelectValue] = useState(0);
  const [warehouseData, setWarehouseData] = useState([]);
  const [ownerCode, setOwnerCode] = useState([]);
  const [ownerGroup, setOwnerGroup] = useState([]);

  const handleTabChange = (e, newValue) => {
    setSelectValue(newValue);
    setShowFile(false);
    setFile([]);
    setBulkUpdateLoader(false);
    entryFormik.resetForm();
  };

  const getWarehouseDetails = async () => {
    try {
      const { data } = await get(URLS.warehouse.list);
      setWarehouseData(
        data?.result.map((w) => ({
          ...w,
          label: w.code,
          value: w._id,
        }))
      );

      if (data?.result?.length === 1) {
        entryFormik.setFieldValue("warehouseCode", data.result[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOwnerGroups = async () => {
    try {
      entryFormik.setFieldValue("ownerGroup", "");
      const { data } = await get(URLS.ownerGroup.list, {
        params: { warehouse: entryFormik.values.warehouseCode },
      });
      setOwnerGroup(
        data?.result.map((c) => ({
          ...c,
          label: c.name,
          value: c._id,
        }))
      );

      if (data?.result?.length === 1) {
        entryFormik.setFieldValue("ownerGroup", data.result[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOwnerCodes = async () => {
    try {
      entryFormik.setFieldValue("ownerCode", "");
      const { data } = await get(URLS.ownerCode.list, {
        params: { ownerGroup: entryFormik.values.ownerGroup },
      });
      setOwnerCode(
        data?.result.map((c) => ({
          ...c,
          label: c.name,
          value: c._id,
        }))
      );

      if (data?.result?.length === 1) {
        entryFormik.setFieldValue("ownerCode", data.result[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getWarehouseDetails();
  }, []);

  const handleClose = () => {
    setOpenBulkUpdate(false);
    setFile([]);
    entryFormik.resetForm();
    setShowFile(false);
  };

  const downloadSampleFile = async () => {
    try {
      if (!entryFormik.values.warehouseCode) {
        return toast.error("Please select the warehouse");
      }

      setGettingSample(true);
      if (value === 0) {
        const { data, status } = await get(
          URLS.inboundEntry.downloadBulkEntryUpdateSampleFile,
          {
            params: {
              warehouseId: entryFormik.values.warehouseCode,
              ownerGroupId: entryFormik.values.ownerGroup,
              ownerCodeId: entryFormik.values.ownerCode,
            },
            validateStatus: (status) => status < 500,
            responseType: "blob",
          }
        );
        if (status === 200) {
          downloadExcel(data, "Bulk Update Entry.xlsx");

          setShowFile(true);
        } else {
          const errorText = await new Response(data).text();
          toast.error(JSON.parse(errorText)?.message);
        }
      } else {
        const { data, status } = await get(
          URLS.inboundEntry.downloadBulkItemUpdateSampleFile,
          {
            params: {
              warehouseId: entryFormik.values.warehouseCode,
              ownerGroupId: entryFormik.values.ownerGroup,
              ownerCodeId: entryFormik.values.ownerCode,
              itemType: entryFormik.values.itemType,
            },
            validateStatus: (status) => status < 500,
            responseType: "blob",
          }
        );
        if (status === 200) {
          downloadExcel(data, "Bulk Update Item.xlsx");

          setShowFile(true);
        } else {
          const errorText = await new Response(data).text();
          toast.error(JSON.parse(errorText)?.message);
        }
      }

      setGettingSample(false);
    } catch (error) {
      console.log(error);
      setGettingSample(false);
    }
  };

  const entryFormik = useFormik({
    initialValues: {
      warehouseCode: "",
      ownerGroup: "",
      ownerCode: "",
      itemType: "",
    },
    onSubmit: downloadSampleFile,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (entryFormik.values.warehouseCode) {
      getOwnerGroups();
    }
  }, [entryFormik.values.warehouseCode]);

  useEffect(() => {
    if (entryFormik.values.ownerGroup) {
      getOwnerCodes();
    } else {
      setOwnerCode([]);
      entryFormik.setFieldValue("ownerCode", "");
    }
  }, [entryFormik.values.ownerGroup]);

  const handledBulkUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("warehouseId", entryFormik.values.warehouseCode);

    file.forEach((file) => {
      formData.append("file", file);
    });

    try {
      if (value === 0) {
        setBulkUpdateLoader(true);
        const { data } = await post(
          URLS.inboundEntry.bulkUpdateEntry,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        entryFormik.resetForm();
        handleClose();
        setBulkUpdateLoader(false);
      } else {
        setBulkUpdateLoader(true);
        const { data } = await post(
          URLS.inboundEntry.bulkUpdateItems,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        entryFormik.resetForm();
        handleClose();
        setBulkUpdateLoader(false);
      }
    } catch (error) {
      console.error(error);
      setBulkUpdateLoader(false);
    }
  };

  return (
    <>
      <PageHeader
        title="Bulk Update Entry/Item"
        addButtonDisabled={true}
        showAdd={false}
        showAddField={false}
      />

      <TabList
        onChange={handleTabChange}
        value={value}
        labels={["Bulk Update Entry", "Bulk Update Item"]}
      />

      <Paper sx={{ p: 3, maxWidth: 800, mx: "auto", mt: 4 }}>
        <Grid
          container
          spacing={2}
          // sx={{ maxWidth: 600, mx: "auto" }}
          component="form"
          onSubmit={handledBulkUpload}
        >
          <Grid item xs={value === 1 ? 3 : 4}>
            <FormSelect
              formik={entryFormik}
              label="Select Warehouse Code"
              name="warehouseCode"
              options={warehouseData}
              required
            />
          </Grid>

          <Grid item xs={value === 1 ? 3 : 4}>
            <FormSelect
              formik={entryFormik}
              label="Select Owner Group"
              name="ownerGroup"
              options={ownerGroup}
              required
            />
          </Grid>

          <Grid item xs={value === 1 ? 3 : 4}>
            <FormSelect
              formik={entryFormik}
              label="Select Owner Code"
              name="ownerCode"
              options={ownerCode}
              required
            />
          </Grid>
          {value === 1 && (
            <Grid item xs={value === 1 ? 3 : 4}>
              <FormSelect
                formik={entryFormik}
                label="Select Item Type"
                name="itemType"
                options={Item_Option}
                required
              />
            </Grid>
          )}

          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <LoadingButton
              loading={gettingSample}
              onClick={downloadSampleFile}
              variant="contained"
              size="small"
              disabled={
                !entryFormik.values.warehouseCode ||
                !entryFormik.values.ownerCode ||
                !entryFormik.values.ownerGroup
              }
              startIcon={<DownloadRoundedIcon />}
            >
              Get Sample File
            </LoadingButton>
          </Grid>

          {showFile && (
            <Grid item xs={12}>
              <FileSelect
                name="file"
                disabled={!entryFormik.values}
                selectedFiles={file}
                setSelectedFiles={setFile}
                accept=".xlsx, .xls, .xlsm, .xlsb, .csv, .ods"
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}
          >
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              type="submit"
              disabled={file.length === 0}
              loading={bulkUpdateLoader}
            >
              Update
            </LoadingButton>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
