const URLS = {
  auth: {
    login: "/account/login",
    getCurrentUser: "/account/me",
    updatePassword: "/account/updatePassword",
    update: "/account/update",
    create: "/account/create",
    list: "/account/list",
    updateSuper: "/account/updateSuper",
  },

  forms: {
    create: "/form/createAndUpdate",
    update: "/form/update",
    read: "/form/read",
    list: "/form/list",
  },
  itemCategory: {
    create: "/itemCategory/create",
    list: "/itemCategory/list",
    update: "/itemCategory/update",
    delete: "/itemCategory/delete",
  },
  stockType: {
    create: "/stockType/create",
    update: "/stockType/update",
    details: "/stockType/read",
    list: "/stockType/list",
    delete: "/stockType/delete",
  },
  damageCode: {
    create: "/damageCode/create",
    update: "/damageCode/update",
    details: "/damageCode/read",
    list: "/damageCode/list",
    delete: "/damageCode/delete",
  },
  workFlowCode: {
    create: "/workFlowCode/create",
    update: "/workFlowCode/update",
    list: "/workFlowCode/list",
    delete: "/workFlowCode/delete",
  },
  currentActivityCode: {
    create: "/currentActivityCode/create",
    update: "/currentActivityCode/update",
    list: "/currentActivityCode/list",
    delete: "/currentActivityCode/delete",
  },
  storageCode: {
    create: "/storageCode/create",
    update: "/storageCode/update",
    list: "/storageCode/list",
    delete: "/storageCode/delete",
  },
  palletCode: {
    create: "/palletCode/create",
    update: "/palletCode/update",
    list: "/palletCode/list",
    delete: "/palletCode/delete",
  },
  gradingScale: {
    create: "/gradeScale/create",
    update: "/gradeScale/update",
    list: "/gradeScale/list",
    delete: "/gradeScale/delete",
  },
  ownerCode: {
    create: "/ownerCode/create",
    update: "/ownerCode/update",
    list: "/ownerCode/list",
    delete: "/ownerCode/delete",
    getAllOwnerCodes: "/ownerCode/getAllOwnerCodes",
  },
  ownerGroup: {
    create: "/ownerGroup/create",
    update: "/ownerGroup/update",
    list: "/ownerGroup/list",
    delete: "/ownerGroup/delete",
  },
  roles: {
    create: "/role/create",
    update: "/role/update",
    list: "/role/list",
    getKeys: "/role/getRoleKeys",
    delete: "/role/delete",
  },
  family: {
    create: "/family/create",
    update: "/family/update",
    list: "/family/list",
    delete: "/family/delete",
  },
  subFamily: {
    create: "/subFamily/create",
    update: "/subFamily/update",
    list: "/subFamily/list",
    delete: "/subFamily/delete",
  },
  updateStatus: {
    create: "/updateStatus/create",
    update: "/updateStatus/update",
    list: "/updateStatus/list",
    delete: "/updateStatus/delete",
  },
  courierPartner: {
    create: "/courierPartner/create",
    update: "/courierPartner/update",
    list: "/courierPartner/list",
    delete: "/courierPartner/delete",
  },
  courierMode: {
    create: "/courierMode/create",
    update: "/courierMode/update",
    list: "/courierMode/list",
    delete: "/courierMode/delete",
  },
  employee: {
    create: "/employee/create",
    update: "/employee/update",
    list: "/employee/list",
    delete: "/employee/delete",
    toggleActiveStatus: "/employee/toggleActiveStatus",
    createDashboardFilter: "/employee/createDashboardFilter",
    updateDashboardFilter: "/employee/updateDashboardFilter",
    deleteDashboardFilter: "/employee/deleteDashboardFilter",
    listUsers: "/employee/listUsers",
    updateUser: "/employee/updateUser",
  },
  warehouse: {
    create: "/warehouse/create",
    update: "/warehouse/update",
    list: "/warehouse/list",
    delete: "/warehouse/delete",
  },
  conditionCode: {
    create: "/conditionCode/create",
    update: "/conditionCode/update",
    list: "/conditionCode/list",
    delete: "/conditionCode/delete",
  },
  brand: {
    create: "/brand/create",
    update: "/brand/update",
    list: "/brand/list",
    delete: "/brand/delete",
  },
  itemHistory: {
    update: "/itemHistory/update",
    list: "/itemHistory/list",
    delete: "/itemHistory/delete",
    read: "/itemHistory/read",
    downloadHistory: "/itemHistory/downloadHistory",
  },
  packaging: {
    create: "/packaging/create",
    update: "/packaging/update",
    list: "/packaging/list",
    delete: "/packaging/delete",
  },
  bundleType: {
    create: "/bundleType/create",
    update: "/bundleType/update",
    list: "/bundleType/list",
    delete: "/bundleType/delete",
  },
  inboundEntry: {
    create: "/inboundEntry/create",
    update: "/inboundEntry/update",
    list: "/inboundEntry/list",
    delete: "/inboundEntry/delete",
    addFiles: "/inboundEntry/addFiles",
    removeFile: "/inboundEntry/removeFile",
    read: "/inboundEntry/read",
    entryDetails: "/inboundEntry/entryDetails",
    downloadSampleFile: "/inboundEntry/downloadSampleFile",
    bulkUploadFile: "/inboundEntry/bulkUploadFile",
    getAllReferenceCodes: "/inboundEntry/getAllReferenceCodes",

    downloadBulkEntryUpdateSampleFile:
      "/inboundEntry/downloadBulkEntryUpdateSampleFile",
    bulkUpdateEntry: "/inboundEntry/bulkUpdateEntry",
    downloadBulkItemUpdateSampleFile:
      "/inboundEntry/downloadBulkItemUpdateSampleFile",
    bulkUpdateItems: "/inboundEntry/bulkUpdateItems",
  },
  items: {
    update: "/item/update",
    list: "/item/list",
    delete: "/item/delete",
    getKeys: "/item/getKeys",
    downloadReport: "/item/downloadReport",
    listSavedStock: "/item/getSavedStock",
    addToStock: "/item/addToStock",
    downloadSavedStockReport: "/item/downloadSavedStockReport",
    updateAll: "/item/updateList",
    addFiles: "/item/addFiles",
    removeFile: "/item/removeFile",
    downloadTotalStock: "/item/downloadTotalStock",
    downloadSelectFields: "/item/downloadSelectFields",
    donwloadBundledStock: "/item/downloadBundledStock",
    donwloadItemHistory: "/item/donwloadItemHistory",
    donwloadMultipleItemHistory: "/item/donwloadMultipleItemHistory",
    getDashboardSelectedDateStats: "/item/getDashboardSelectedDateStats",
    getOutboundStats: "/item/getOutboundStats",
    getItemStats: "/item/getItemStats",
    getItemViewReportsNewAndUsedFaulty:
      "/item/getItemViewReportsNewAndUsedFaulty",
    getItemsForBundling: "/item/getItemsForBundling",
    getExcelForExpiryDates: "/item/getExcelForExpiryDates",
    updateExpiryDates: "/item/updateExpiryDates",
    listToUpdate: "/item/listToUpdate",
    getBundledStock: "/item/getBundledStock",
    getItemsForHistory: "/item/getItemsForHistory",
    toggleFreezeStatus: "/item/toggleFreezeStatus",
    getAllFreezedItems: "/item/getAllFreezedItems",
    getGroupedItems: "/item/getGroupedItems",
    getShippedItems: "/item/getShippedItems",
    downloadStorageReport: "/item/downloadStorageReport",
    getItemViewReportsFaulty: "/item/getItemViewReportsFaulty",
    downloadFaultySelectFields: "/item/downloadFaultySelectFields",
    getShippedItemDetails: "/item/getShippedItemDetails",
    getBulkUpdateSampleSheet: "/item/getBulkUpdateSampleSheet",
    bulkUpdateItems: "/item/bulkUpdateItems",
    donwloadStorageReportOrderWise: "/item/donwloadStorageReportOrderWise/",
    downloadInProcessOrderWise: "/item/downloadInProcessOrderWise",
    downloadProcessedOrderWise: "/item/downloadProcessedOrderWise",
    downloadTotalStockOrderWise: "/item/downloadTotalStockOrderWise",
    downloadBulkSampleHold: "/item/downloadBulkSampleHold",
    downloadBulkSampleRelease: "/item/downloadBulkSampleRelease",
    bulkHoldItems: "/item/bulkHoldItems",
    bulkReleaseItems: "/item/bulkReleaseItems",
    downloadTotalStockWithHistory: "/item/downloadTotalStockWithHistory",
    downloadTotalStockOrderWiseWithHistory:
      "/item/downloadTotalStockOrderWiseWithHistory",
    downloadFaultySelectFieldsWithHistory:
      "/item/downloadFaultySelectFieldsWithHistory",
    downloadProcessedOrderWiseWithHistory:
      "/item/downloadProcessedOrderWiseWithHistory",
  },

  bundle: {
    create: "/bundle/create",
    update: "/bundle/update",
    list: "/bundle/list",
    delete: "/bundle/delete",
    unbundle: "/bundle/unbundle",
  },
  pickList: {
    create: "/pickList/create",
    update: "/pickList/update",
    list: "/pickList/list",
    delete: "/pickList/delete",
    downloadPickList: "/pickList/downloadPickList",
    updateMultiple: "/pickList/updateMultiple",
    removeItem: "/pickList/removeItem",
    getAllPickListWithoutDc: "/pickList/getAllPickListWithoutDc",
    downloadBulkPicklist: "/pickList/downloadBulkPicklist",
    bulkOutboundPickList: "/pickList/bulkOutboundPickList",
  },
  outbound: {
    create: "/outbound/create",
    update: "/outbound/update",
    list: "/outbound/list",
    delete: "/outbound/delete",
    downloadShipmentReport: "/outbound/downloadShipmentReport",
    downloadShippedStock: "/outbound/downloadShippedStock",
    getAllKeys: "/outbound/getKeys",
    showList: "/outbound/showList",
    getListToUpdateStatus: "/outbound/getListToUpdateStatus",
    updateStatusBulk: "/outbound/updateStatusBulk",
    getSampleFileForStatusUpdate: "/outbound/getSampleFileForStatusUpdate",
    downloadShipmentReportWithAllKeys:
      "/outbound/downloadShipmentReportWithAllKeys",
    downloadBulkUpdateSample: "/outbound/downloadBulkUpdateSample",
    bulkUpdateOutbound: "/outbound/bulkUpdateOutbound",
    downloadShipmentReportOrderWise:
      "/outbound/downloadShipmentReportOrderWise",
  },
  itemHistoryConfig: {
    create: "/itemHistoryConfig/create",
    update: "/itemHistoryConfig/update",
    list: "/itemHistoryConfig/list",
    delete: "/itemHistoryConfig/delete",
    getData: "/itemHistoryConfig/getData",
  },
  itemDescription: {
    create: "/itemDescription/create",
    update: "/itemDescription/update",
    list: "/itemDescription/list",
    delete: "/itemDescription/delete",
    getItemDescription: "/itemDescription/getItemDescription",
    getItemDescriptionSampleFile:
      "/itemDescription/getItemDescriptionSampleFile",
    updateItemDescriptionBulk: "/itemDescription/updateItemDescriptionBulk",

    getBulkAddSampleFile: "/itemDescription/getBulkAddSampleFile",
    bulkAddItemDescription: "/itemDescription/bulkAddItemDescription",
  },

  dc: {
    create: "/dc/create",
    list: "/dc/list",
    details: "/dc/details",
    delete: "/dc/delete",
    downloadDc: "/dc/downloadDc",
    getItemDetails: "/dc/getItemDetails",
    findOne: "/dc/findOne",
  },
  archivedDoc: {
    list: "/archivedDoc/list",
    restore: "/archivedDoc/restore",
    delete: "/archivedDoc/delete",
  },
  orderType: {
    create: "/orderType/create",
    update: "/orderType/update",
    list: "/orderType/list",
    delete: "/orderType/delete",
  },
  directory: {
    create: "/directory/create",
    list: "/directory/list",
    update: "/directory/updateDirectory",
    getPinCodes: "/directory/getPinCodes",
    getAddressesOfPincode: "/directory/getAddressesOfPincode",
    addAddress: "/directory/addAddress",
    removeAddress: "/directory/removeAddress",
    updateAddress: "/directory/updateAddress",
    deleteDirectory: "/directory/deleteDirectory",
  },
  reverseDc: {
    create: "/reverseDc/create",
    list: "/reverseDc/list",
    details: "/reverseDc/details",
    delete: "/reverseDc/delete",
    downloadDc: "/reverseDc/downloadDc",
  },
};
export default URLS;
