import { useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";

import CustomSelect from "../../components/SecondaryForms/CustomSelect";
import CustomInput from "../../components/SecondaryForms/CustomInput";

// services
import { get, put } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";
import { LoadingButton } from "@mui/lab";
import ViewOrUpdateItemFiles from "../../components/ViewOrUpdateItemFiles";
import { Search } from "@mui/icons-material";
import ConfirmationModal from "../../forms/ConfirmationModal";
import WarehouseContext from "../../context/WarehouseContext";
import { toast } from "react-toastify";
import ShowComponent from "../ShowComponent";
import { PuffLoader } from "react-spinners";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { ROWS_PER_PAGE } from "../../utils/utilities";

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "200px",
}));

export default function UpdateFaultyStock({
  msn = "",
  showSearch = true,
  showBack = true,
  onItemUpdate = () => {},
}) {
  const { selectedWarehouse, selectedOwnerCode, selectedOwnerGroup } =
    useContext(WarehouseContext);
  const [formData, setFormData] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [families, setFamilies] = useState([]);
  const [subFamilies, setSubFamilies] = useState([]);
  const [storageCodes, setStorageCodes] = useState([]);
  const [palletCodes, setPalletCodes] = useState([]);
  const [activityCodes, setActivityCodes] = useState([]);
  const [packaging, setPackaging] = useState([]);
  const [currentConditionCodes, setCurrentConditionCodes] = useState([]);
  const [damageCode, setDamageCode] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [itemCategory, setItemCategory] = useState([]);
  const [addingSave, setAddingSave] = useState(false);
  const [addingUpdate, setAddingUpdate] = useState(false);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [itemFormFields, setItemFormFields] = useState([]);
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [itemDescription, setItemDescription] = useState([]);
  const [orderTypeData, setOrderTypeData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getItemFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.list, {
        params: {
          collectionName: "item",
          warehouse: { $in: selectedWarehouse },
        },
      });

      setItemFormFields(data.result ? data.result : []);
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const faultyStockData = location.state && location.state.itemData;

  const getData = async () => {
    try {
      const stockPromise = get(URLS.stockType.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const subFamilyPromise = get(URLS.subFamily.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const itemDescriptionPromise = get(
        URLS.itemDescription.getItemDescription,
        {
          params: { search: { warehouse: { $in: selectedWarehouse } } },
        }
      );
      const brandNamePromise = get(URLS.brand.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const storageCodePromise = get(URLS.storageCode.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const palletCodeCodePromise = get(URLS.palletCode.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const activityCodePromise = get(URLS.currentActivityCode.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const conditionCodePromise = get(URLS.conditionCode.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const damageCodePromise = get(URLS.damageCode.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const packagingPromise = get(URLS.packaging.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const itemCategoryPromise = get(URLS.itemCategory.list, {
        params: { search: { warehouse: { $in: selectedWarehouse } } },
      });
      const orderTypeDataPromise = get(URLS.orderType.list, {
        params: { warehouse: { $in: selectedWarehouse } },
      });

      const [
        stockTypeData,
        subFamilyData,
        itemDescriptionData,
        brandData,
        storageCodeData,
        palletCodeData,
        activityCodeData,
        conditionCodeData,
        damageCodeData,
        packagingData,
        itemCategoryData,
        orderTypeData,
      ] = await Promise.all([
        stockPromise,
        subFamilyPromise,
        itemDescriptionPromise,
        brandNamePromise,
        storageCodePromise,
        palletCodeCodePromise,
        activityCodePromise,
        conditionCodePromise,
        damageCodePromise,
        packagingPromise,
        itemCategoryPromise,
        orderTypeDataPromise,
      ]);
      setStockTypes(
        stockTypeData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );

      setSubFamilies(
        subFamilyData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setItemDescription(
        itemDescriptionData.data.result.map((s) => ({
          ...s,
          label: s.description,
          value: s.description,
        }))
      );
      setBrandData(
        brandData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setStorageCodes(
        storageCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setPalletCodes(
        palletCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setActivityCodes(
        activityCodeData.data.result.map((s) => ({
          ...s,
          label: `${s.code}-${s.description}`,
          value: s._id,
        }))
      );
      setCurrentConditionCodes(
        conditionCodeData.data.result.map((s) => ({
          ...s,
          label: `${s.code}-${s.description}`,
          value: s._id,
        }))
      );
      setDamageCode(
        damageCodeData.data.result.map((s) => ({
          ...s,
          label: `${s.code}-${s.description}`,
          value: s._id,
        }))
      );

      setPackaging(
        packagingData.data.result.map((s) => ({
          ...s,
          label: s.title,
          value: s._id,
        }))
      );
      setItemCategory(
        itemCategoryData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setOrderTypeData(
        orderTypeData?.data?.result.map((o) => ({
          ...o,
          label: o.name,
          value: o._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    getItemFormDetails();
  }, [selectedWarehouse]);

  const handleChange = (e, index) => {
    let name, value;

    if (e && e.target) {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = "expiryDate";
      value = e;
    }
    setFormData(
      formData.map((fd, i) => (i === index ? { ...fd, [name]: value } : fd))
    );

    setSearchFilter(
      formData.map((fd, i) => (i === index ? { ...fd, [name]: value } : fd))
    );
  };

  const getItems = async () => {
    setLoading(true);

    try {
      let filter = {
        inStock: false,
        inBundle: false,
        isSaved: false,
        subFamily: faultyStockData.subFamilyId,
        itemCategory: "faulty",
      };

      if (search?.trim()?.length) {
        filter["msn"] = search;
      }

      const { data } = await get(URLS.items.listToUpdate, {
        params: {
          warehouses: selectedWarehouse,
          ownerCodes: selectedOwnerCode,
          ownerGroups: selectedOwnerGroup,
          page: page + 1,
          limit: rowsPerPage,
          search: filter,
        },
      });

      if (data.result) {
        setTotalItems(data.result.totalItems);
        const newFormData = data.result.items.map((item) => ({
          _id: item._id,
          itemCategory: "faulty",
          warehouse: item.warehouse?._id,
          expiryDate: item.expiryDate,
          stockType: item.stockType._id,
          orderType: item.orderType?._id,
          msn: item.msn,
          subFamily: item.subFamily._id,
          itemDescription: item.itemDescription,
          itemCode: item.itemCode,
          manufacturerPartNumber: item.manufacturerPartNumber,
          brandName: item.brandName._id,
          storageCode: item.storageCode._id,
          palletCode: item.palletCode._id,
          conditionCode: item.conditionCode._id,
          damageCode: item.damageCode ? item.damageCode._id : null,
          activityCode: item.activityCode ? item.activityCode._id : null,
          packaging: item.packaging ? item.packaging._id : null,
          configurationOs: item.configurationOs,
          oemSupportRemarks: item.oemSupportRemarks,
          images: item.images,
          meta: itemFormFields
            ?.find((ff) => ff.warehouse?._id === item.warehouse?._id)
            ?.fields?.map((f) => ({
              ...f,
              value:
                item.meta?.find((fi) => fi.inputLabel === f.inputLabel)
                  ?.value || "",
            })),
        }));
        setFormData(newFormData);
        setSearchFilter(newFormData);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (faultyStockData) {
      getItems();
    }
  }, [itemFormFields, page, rowsPerPage, search]);

  const handleUpdate = async (id, fd, updateHistory) => {
    try {
      if ([fd].some((fd) => fd.meta?.some((f) => f.isRequired && !f.value)))
        return toast.error("Please fill required fields of this item");

      setAddingSave((prv) => ({
        ...prv,
        [id]: true,
      }));
      const payload = {
        ...fd,
        updateHistory: updateHistory || null,
        entryId: "",
      };
      const { data } = await put(URLS.items.update + "/" + id, {
        ...payload,
        itemCategory: payload?.itemCategory?.toLowerCase(),
      });
      onItemUpdate();
      getItems();
    } catch (error) {
      console.log(error);
    }
    setAddingSave((prv) => ({
      ...prv,
      [id]: false,
    }));
  };

  const onUpdateItem = (item) => {
    getItems();
    setFormData(
      formData.map((d) =>
        d._id === item._id
          ? {
              _id: item._id,
              itemCategory: "faulty",
              expiryDate: item.expiryDate,
              warehouse: item.warehouse?._id,
              stockType: item.stockType._id,
              orderType: item.orderType?._id,
              msn: item.msn,
              subFamily: item.subFamily._id,
              itemDescription: item.itemDescription,
              itemCode: item.itemCode,
              manufacturerPartNumber: item.manufacturerPartNumber,
              brandName: item.brandName._id,
              storageCode: item.storageCode._id,
              palletCode: item.palletCode._id,
              conditionCode: item.conditionCode._id,
              damageCode: item.damageCode ? item.damageCode._id : null,
              activityCode: item.activityCode ? item.activityCode._id : null,
              packaging: item.packaging ? item.packaging._id : null,
              configurationOs: item.configurationOs,
              oemSupportRemarks: item.oemSupportRemarks,
              images: item.images,
              meta: itemFormFields
                .find((f) => f.warehouse?._id === item.warehouse?._id)
                ?.fields?.map((f) => ({
                  ...f,
                  value:
                    item.meta?.find((fi) => fi.inputLabel === f.inputLabel)
                      ?.value || "",
                })),
            }
          : d
      )
    );
    setSearchFilter(
      formData.map((d) =>
        d._id === item._id
          ? {
              _id: item._id,
              itemCategory: "faulty",
              expiryDate: item.expiryDate,
              warehouse: item.warehouse?._id,
              stockType: item.stockType._id,
              orderType: item.orderType?._id,
              msn: item.msn,
              subFamily: item.subFamily._id,
              itemDescription: item.itemDescription,
              itemCode: item.itemCode,
              manufacturerPartNumber: item.manufacturerPartNumber,
              brandName: item.brandName._id,
              storageCode: item.storageCode._id,
              palletCode: item.palletCode._id,
              conditionCode: item.conditionCode._id,
              damageCode: item.damageCode ? item.damageCode._id : null,
              activityCode: item.activityCode ? item.activityCode._id : null,
              packaging: item.packaging ? item.packaging._id : null,
              configurationOs: item.configurationOs,
              oemSupportRemarks: item.oemSupportRemarks,
              images: item.images,
              meta: itemFormFields
                .find((f) => f.warehouse?._id === item.warehouse?._id)
                ?.fields?.map((f) => ({
                  ...f,
                  value:
                    item.meta?.find((fi) => fi.inputLabel === f.inputLabel)
                      ?.value || "",
                })),
            }
          : d
      )
    );
  };
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setPage(0);
    // if (value.trim() !== "") {
    //   formData.length > 0 &&
    //     setSearchFilter(
    //       formData.filter((ele) =>
    //         ele.msn.toLowerCase().includes(value.toLowerCase().trim())
    //       )
    //     );
    // } else {
    //   setSearchFilter([]);
    // }
  };

  const [stockItem, setStockItem] = useState({});

  const submitStock = (item) => {
    setOpen(true);
    setStockItem(item);
  };

  const handleOnAddToStock = () => {
    getItems();
    setOpen(false);
    setStockItem(null);
  };

  const handleCustomInputChangeItem = (e, i) => {
    const { name, value } = e.target;

    setFormData(
      formData.map((f, index) =>
        index === i
          ? {
              ...f,
              meta: f.meta.map((m) =>
                m.inputLabel === name ? { ...m, value } : m
              ),
            }
          : f
      )
    );

    setSearchFilter(
      formData.map((f, index) =>
        index === i
          ? {
              ...f,
              meta: f.meta.map((m) =>
                m.inputLabel === name ? { ...m, value } : m
              ),
            }
          : f
      )
    );
  };

  return (
    <>
      {showSearch ? (
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Grid item xs={12} md={9} mb={2}>
            {showBack ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
                <TablePagination
                  component="div"
                  count={totalItems}
                  page={page}
                  rowsPerPageOptions={ROWS_PER_PAGE}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            ) : null}
          </Grid>

          <Grid item xs={12} md={3} mb={2}>
            <TextField
              size="small"
              value={search}
              fullWidth
              onChange={handleSearch}
              label="Search by MSN"
              placeholder="Enter msn"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" type="submit">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      ) : null}
      {loading && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: "20px",
          }}
        >
          <PuffLoader size={30} color="#14532d" />
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.primary.dark
                  : theme.palette.primary.light,
            }}
          >
            <TableRow>
              <TableCell sx={{ minWidth: "80px" }} align="center">
                S.No
              </TableCell>
              <StyledTableCell align="center">Item Category</StyledTableCell>
              <StyledTableCell align="center">Expiry Date</StyledTableCell>
              <StyledTableCell align="center">Stock Type</StyledTableCell>
              <StyledTableCell align="center">Order Type</StyledTableCell>
              <StyledTableCell align="center">MSN</StyledTableCell>
              <StyledTableCell align="center">Sub-family name</StyledTableCell>
              <StyledTableCell align="center">Item description</StyledTableCell>
              <StyledTableCell align="center">Item code</StyledTableCell>
              <StyledTableCell align="center">
                Manufacturer part number
              </StyledTableCell>
              <StyledTableCell align="center">Brand name</StyledTableCell>
              <StyledTableCell align="center">Storage code</StyledTableCell>
              <StyledTableCell align="center">Pallet code</StyledTableCell>
              <StyledTableCell align="center">
                Current condition code
              </StyledTableCell>
              <StyledTableCell align="center">Damage code</StyledTableCell>
              <StyledTableCell align="center">
                Current activity code
              </StyledTableCell>
              <StyledTableCell align="center">Packaging</StyledTableCell>
              <StyledTableCell align="center">Configuration OS</StyledTableCell>
              {itemFormFields
                ?.flatMap((f) => f.fields)
                ?.map((i, index) => (
                  <StyledTableCell align="center" key={index}>
                    {i.inputLabel}
                  </StyledTableCell>
                ))}
              <StyledTableCell align="center">
                {" "}
                OEM Support Remarks
              </StyledTableCell>
              <TableCell align="center" sx={{ minWidth: "80px" }}>
                Image
              </TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {searchFilter.map((fd, i) => (
              <TableRow key={i}>
                <TableCell align="center" sx={{ minWidth: "80px" }}>
                  {page * rowsPerPage + i + 1}
                </TableCell>
                <StyledTableCell align="center">
                  {fd.itemCategory.toUpperCase()}
                </StyledTableCell>

                <StyledTableCell align="center">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{
                        "& .MuiInputBase-input": {
                          height: "8px",
                        },
                        marginTop: "16px",
                        width: "100%",
                      }}
                      name="expiryDate"
                      variant="outlined"
                      size="small"
                      label="Expiry Date"
                      slotProps={{
                        textField: {
                          InputLabelProps: { shrink: true },
                        },
                      }}
                      views={["year", "month", "day"]}
                      format="DD-MM-YYYY"
                      value={fd.expiryDate ? dayjs(fd.expiryDate) : null}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </LocalizationProvider>
                </StyledTableCell>

                <StyledTableCell align="center">
                  <CustomSelect
                    options={stockTypes.filter(
                      (s) => s.warehouse?._id == fd.warehouse
                    )}
                    name="stockType"
                    label="Stock Type"
                    value={fd.stockType}
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    options={orderTypeData.filter(
                      (s) => s.warehouse?._id === fd.warehouse
                    )}
                    name="orderType"
                    label="Order Type"
                    value={fd.orderType}
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomInput
                    name="msn"
                    value={fd.msn?.toUpperCase()}
                    label="MSN"
                    required={true}
                    disabled={fd.msn}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    onChange={(e) => handleChange(e, i)}
                    options={subFamilies.filter(
                      (s) => s.warehouse?._id == fd.warehouse
                    )}
                    name="subFamily"
                    label="Sub-Family Name"
                    value={fd.subFamily}
                    required={true}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    name="itemDescription"
                    value={fd.itemDescription}
                    options={itemDescription.filter(
                      (s) => s.warehouse === fd.warehouse
                    )}
                    label="Item Description"
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomInput
                    name="itemCode"
                    value={fd.itemCode}
                    label="Item Code"
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomInput
                    name="manufacturerPartNumber"
                    value={fd.manufacturerPartNumber}
                    label="Manufacturer Part Number"
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>

                <StyledTableCell align="center">
                  <CustomSelect
                    options={brandData.filter(
                      (s) => s.warehouse?._id == fd.warehouse
                    )}
                    name="brandName"
                    label="Brand Name"
                    value={fd.brandName}
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    options={storageCodes.filter(
                      (s) => s.warehouse?._id == fd.warehouse
                    )}
                    name="storageCode"
                    label="Storage Code"
                    value={fd.storageCode}
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    options={palletCodes.filter(
                      (s) => s.warehouse?._id == fd.warehouse
                    )}
                    name="palletCode"
                    label="Pallet Code"
                    value={fd.palletCode}
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    options={currentConditionCodes.filter(
                      (s) => s.warehouse?._id == fd.warehouse
                    )}
                    name="conditionCode"
                    label="Current Condition Code"
                    value={fd.conditionCode}
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    options={damageCode.filter(
                      (s) => s.warehouse?._id == fd.warehouse
                    )}
                    name="damageCode"
                    label="Damage Code"
                    value={fd.damageCode}
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    options={activityCodes.filter(
                      (s) => s.warehouse?._id == fd.warehouse
                    )}
                    name="activityCode"
                    label="Activity Code"
                    value={fd.activityCode}
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomSelect
                    options={packaging.filter(
                      (s) => s.warehouse?._id == fd.warehouse
                    )}
                    name="packaging"
                    label="Packaging"
                    value={fd.packaging}
                    required={true}
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <CustomInput
                    name="configurationOs"
                    value={fd.configurationOs}
                    label="Configuration OS"
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                {/* {itemFormFields.flatMap((f, index) =>
                  f.warehouse?._id === fd.warehouse ? (
                    fd.meta?.map((f, index) => (
                      <StyledTableCell align="center" key={index}>
                        <CustomInput
                          onChange={(e) => handleCustomInputChangeItem(e, i)}
                          name={f.inputLabel}
                          value={f.value}
                          required={f.isRequired}
                          label={f.inputLabel}
                          type={f.inputType}
                        />
                      </StyledTableCell>
                    ))
                  ) : (
                    <StyledTableCell align="center">NA</StyledTableCell>
                  )
                )} */}

                {itemFormFields.flatMap((fm, index) =>
                  fm.fields?.map((f, index) =>
                    fd.meta?.find(
                      (field) => f.inputLabel === field.inputLabel
                    ) ? (
                      <StyledTableCell align="center" key={index}>
                        <CustomInput
                          onChange={(e) => handleCustomInputChangeItem(e, i)}
                          name={f.inputLabel}
                          value={
                            fd.meta?.find(
                              (field) => f.inputLabel === field.inputLabel
                            )?.value
                          }
                          required={f.isRequired}
                          label={f.inputLabel}
                          type={f.inputType}
                        />
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell align="center">NA</StyledTableCell>
                    )
                  )
                )}
                <StyledTableCell align="center">
                  <CustomInput
                    name="oemSupportRemarks"
                    value={fd.oemSupportRemarks}
                    label="OEM Support Remarks"
                    onChange={(e) => handleChange(e, i)}
                  />
                </StyledTableCell>
                <TableCell align="center" sx={{ minWidth: "80px" }}>
                  <ShowComponent module={"Inventory"} action={"view"}>
                    <ViewOrUpdateItemFiles
                      module={"Inventory"}
                      item={fd}
                      onUpdate={onUpdateItem}
                    />
                  </ShowComponent>
                </TableCell>
                <TableCell align="center">
                  <ShowComponent module={"Inventory"} action={"update"}>
                    <Box
                      display="flex"
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <LoadingButton
                        loading={addingSave[fd._id]}
                        size="small"
                        variant="contained"
                        sx={{ width: 200, marginRight: "10px" }}
                        onClick={() =>
                          handleUpdate(fd._id, fd, "updateHistory")
                        }
                      >
                        Save
                      </LoadingButton>
                      {/* <LoadingButton
                      loading={addingSave[fd._id]}
                      size="small"
                      variant="contained"
                      sx={{ width: 150 }}
                      onClick={() => submitStock(fd)}
                    >
                      Add To Stock
                    </LoadingButton> */}
                    </Box>
                  </ShowComponent>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* {!formData.length && !search.trim() && !loading && (
          <Typography variant="h6" my={2} textAlign={"center"}>
            No data found
          </Typography>
        )} */}
        {search.trim() && !searchFilter.length && !loading && (
          <Typography variant="h6" my={2} textAlign={"center"}>
            No data found
          </Typography>
        )}
      </TableContainer>

      <ConfirmationModal
        open={open}
        setOpen={setOpen}
        title="add this item to stock"
        description=""
        item={stockItem}
        onAddToStock={handleOnAddToStock}
      />
    </>
  );
}
