import React, { useContext, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import dayjs from "dayjs";
import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// custom components
import FormInput from "../../forms/FormInput";
import FormDatePicker from "../../forms/FormDatePicker";
import FormSelect from "../../forms/FormSelect";
import PageHeader from "../../components/PageHeader";
import TabList from "../../components/Tabs/TabList";
import TabPanel from "../../components/Tabs/TabPanel";
import CustomTable from "../../components/Tables/CustomTable";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";
import CustomInput from "../../components/SecondaryForms/CustomInput";
import FileSelect from "../../forms/FileSelect";
import UpdateFaultyItem from "./UpdateFaultytem";
import { PuffLoader } from "react-spinners";
import { Dialog, DialogTitle } from "@mui/material";
// services
import { get, post, del } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";

//data
import { inboundEntriesTableKeys } from "../../data/TableData/inboundEntries";

// utilities
import {
  downloadExcel,
  generateRandomNumber,
  hasAllValues,
  hasDuplicates,
  ROWS_PER_PAGE,
} from "../../utils/utilities";
import { toast } from "react-toastify";
import WarehouseContext from "../../context/WarehouseContext";
import DashBoardPageHeaderPage from "../../components/DashBoard/DashBoardHeaderPage";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import AddIcon from "@mui/icons-material/Add";
import CustomSearch from "../../components/Tables/CustomSearch";
import BulkUpdate from "./BulkUpdate";

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "200px",
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",

  p: 4,
};

export default function Faulty() {
  const {
    selectedWarehouse,
    selectedOwnerCode,
    selectedOwnerGroup,
    allWarehouses,
  } = useContext(WarehouseContext);

  const [inboundEntries, setInboundEntries] = useState([]);
  const [addingEntry, setAddingEntry] = useState(false);
  const [entryToEdit, setEntryToEdit] = useState(null);
  const [value, setSelectValue] = useState(0);
  const [loading, setLoading] = useState(false);

  const [entryDone, setEntryDone] = useState(false);
  const [formData, setFormData] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [ownerData, setOwnerData] = useState([]);
  const [ownerCodeData, setOwnerCodeData] = useState([]);
  const [stockTypes, setStockTypes] = useState([]);
  const [subFamilies, setSubFamilies] = useState([]);
  const [storageCodes, setStorageCodes] = useState([]);
  const [palletCodes, setPalletCodes] = useState([]);
  const [activityCodes, setActivityCodes] = useState([]);
  const [packaging, setPackaging] = useState([]);
  const [currentConditionCodes, setCurrentConditionCodes] = useState([]);
  const [damageCode, setDamageCode] = useState([]);
  const [workFlowData, setWorkFlowData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [entryFiles, setEntryFiles] = useState([]);
  const [itemFormFields, setItemFormFields] = useState(null);
  const [formFields, setFormFields] = useState(null);

  const [page, setPage] = React.useState(0);
  const [orderTypeData, setOrderTypeData] = useState([]);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openBulk, setOpenBulk] = useState(false);
  const [gettingSample, setGettingSample] = useState(false);
  const [bulkUploadLoader, setBulkUploadLoader] = useState(false);
  const [file, setFile] = useState([]);
  const [itemDescription, setItemDescription] = useState([]);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [openBulkUpdate, setOpenBulkUpdate] = useState(false);

  const msnRefs = useRef([]);

  const handleKeyDown = (event, index) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default Enter behavior
      if (msnRefs.current[index + 1]) {
        msnRefs.current[index + 1].focus(); // Focus on the next input
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getWarehouseDetails = async () => {
    try {
      const { data } = await get(URLS.warehouse.list);
      setWarehouseList(
        data?.result.map((w) => ({
          ...w,
          label: w.code,
          value: w._id,
        }))
      );
      if (allWarehouses?.length === 1) {
        entryFormik.setFieldValue("warehouseCode", data.result[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getWarehouseDetails();
  }, []);

  const getInboundEntries = async () => {
    try {
      if (loading) return;
      setLoading(true);
      const { data } = await get(URLS.inboundEntry.list, {
        params: {
          search: { entryMenu: "Faulty" },
          warehouses: selectedWarehouse,
          ownerCodes: selectedOwnerCode,
          ownerGroups: selectedOwnerGroup,
        },
      });
      setInboundEntries(
        data.result.map((e) => ({ ...e, totalItems: e.items?.length }))
      );
      setSearch("");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getInboundEntries();
  }, [selectedOwnerCode, selectedWarehouse, selectedOwnerGroup]);

  const handleNextClick = () => {
    if (
      !hasAllValues(entryFormik.values, [
        "images",
        "remarks",
        "ownerCategory",
        "receivedDate",
      ])
    )
      return;

    if (formFields) {
      for (let field of formFields) {
        if (field.isRequired && !field.value) {
          return toast.error(
            `Please fill the required field : ${field.inputLabel}`
          );
        }
      }
    }

    setEntryDone(true);
  };

  const handleSubmit = async (values) => {
    if (
      !hasAllValues(entryFormik.values, [
        "images",
        "remarks",
        "ownerCategory",
        "receivedDate",
      ])
    )
      return;

    for (let item of formData) {
      if (
        !hasAllValues(item, [
          "images",
          "remarks",
          "configurationOs",
          "expiryDate",
        ])
      )
        return;
    }

    const msnList = formData.map((fd) => fd.msn);
    if (hasDuplicates(msnList))
      return toast.error("No two items can have the same MSN value!");

    setAddingEntry(true);
    const newFormData = new FormData();
    // const receivedDate = dayjs(new Date(values.receivedDate)).format(
    //   "YYYY-MM-DD HH:mm:ss"
    // );
    // const incomingDate = dayjs(new Date(Date.now())).format(
    //   "YYYY-MM-DD HH:mm:ss"
    // );

    const receivedDate = dayjs(values.receivedDate).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const incomingDate = dayjs(values.incomingDate).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    newFormData.append(
      "inboundEntry",
      JSON.stringify({
        ...values,
        receivedDate: values.receivedDate ? receivedDate : null,
        incomingDate: incomingDate,
        meta: formFields,
      })
    );
    newFormData.append(
      "items",
      JSON.stringify(formData.map((d) => ({ ...d, msn: d.msn?.toUpperCase() })))
    );
    formData.forEach((item) => {
      item.images.forEach((file, index) => {
        newFormData.append(`${item.msn}`, file);
      });
    });

    // Append each image file
    entryFiles.forEach((file, index) => {
      newFormData.append(`entryFiles`, file);
    });

    try {
      const { data } = await post(URLS.inboundEntry.create, newFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setSelectValue(1);
      setEntryDone(false);
      entryFormik.resetForm();
      entryFormik.setFieldValue(
        "gmsReferenceCode",
        `GMS${generateRandomNumber(10)}`
      );
      getInboundEntries();
    } catch (error) {
      console.log(error);
    }
    setAddingEntry(false);
  };

  const entryFormik = useFormik({
    initialValues: {
      gmsReferenceCode: `GMS${generateRandomNumber(10)}`,
      entryMenu: "Faulty", // Used ||  Faulty
      receivedDate: null,
      warehouseCode: "",
      warehouseName: "",
      incomingDate: dayjs(new Date(Date.now())),
      receivedDocketNumber: "",
      eWayBillNumber: "",
      incomingInvoiceNumber: "",
      dcNumber: "",
      poNumber: "",
      numberOfBoxes: "",
      sku: "",
      ownerGroup: "",
      ownerCode: "",
      ownerCategory: "",
      workflowCode: "",
      remarks: "",
    },
    onSubmit: handleSubmit,
    validateOnBlur: false,
  });

  useEffect(() => {
    const formData = [];

    const faultyCategoryId = "faulty";

    let id = 1;

    for (let index = 0; index < entryFormik.values.sku; index++) {
      formData.push({
        itemCategory: faultyCategoryId,
        id: id,
        stockType: "",
        orderType: "",
        msn: "",
        expiryDate: null,
        subFamily: "",
        itemDescription: "",
        itemCode: "",
        manufacturerPartNumber: "",
        brandName: "",
        storageCode: "",
        palletCode: "",
        conditionCode: "",
        damageCode: "",
        activityCode: "",
        packaging: "",
        configurationOs: "",
        remarks: "",
        images: [],
        meta: itemFormFields || [],
      });

      id += 1;
    }
    setPage(0);
    setFormData(formData);
  }, [entryFormik.values.sku, itemFormFields]);

  const warehouseOptions = warehouseList?.map((code) => ({
    label: code.code,
    value: code._id,
  }));

  //custom fields for entry
  const getFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: {
          collectionName: "inboundEntry",
          warehouse: entryFormik.values.warehouseCode,
        },
      });
      setFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };

  //custom fields for items
  const getItemFormDetails = async () => {
    try {
      const { data } = await get(URLS.forms.read, {
        params: {
          collectionName: "item",
          warehouse: entryFormik.values.warehouseCode,
        },
      });
      setItemFormFields(data.result ? data.result.fields : null);
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    try {
      const stockPromise = get(URLS.stockType.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const subFamilyPromise = get(URLS.subFamily.list, {
        params: { warehouse: entryFormik.values.warehouseCode },
      });
      const itemDescriptionPromise = get(
        URLS.itemDescription.getItemDescription,
        {
          params: { search: { warehouse: entryFormik.values.warehouseCode } },
        }
      );
      const storageCodePromise = get(URLS.storageCode.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const palletCodeCodePromise = get(URLS.palletCode.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const currentConditionCodePromise = get(URLS.conditionCode.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const ownerDataPromise = get(URLS.ownerGroup.list, {
        params: { warehouse: entryFormik.values.warehouseCode },
      });

      const brandDataPromise = get(URLS.brand.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const workflowDataPromise = get(URLS.workFlowCode.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });

      const packagingPromise = get(URLS.packaging.list, {
        params: { warehouse: entryFormik.values.warehouseCode },
      });
      const damageCodePromise = get(URLS.damageCode.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const activityCodePromise = get(URLS.currentActivityCode.list, {
        params: { search: { warehouse: entryFormik.values.warehouseCode } },
      });
      const orderTypeDataPromise = get(URLS.orderType.list, {
        params: { warehouse: entryFormik.values.warehouseCode },
      });

      const [
        stockTypeData,
        subFamilyData,
        itemDescriptionData,
        brandData,
        storageCodeData,
        palletCodeData,
        currentConditionCodeData,
        damageCodeData,
        activityCodeData,
        packagingData,
        ownerData,
        workflowData,
        orderTypeData,
      ] = await Promise.all([
        stockPromise,
        subFamilyPromise,
        itemDescriptionPromise,
        brandDataPromise,
        storageCodePromise,
        palletCodeCodePromise,
        currentConditionCodePromise,
        damageCodePromise,
        activityCodePromise,
        packagingPromise,
        ownerDataPromise,
        workflowDataPromise,
        orderTypeDataPromise,
      ]);

      setActivityCodes(
        activityCodeData?.data?.result.map((w) => ({
          ...w,
          label: `${w.code}-${w.description}`,
          value: w._id,
        }))
      );

      setWorkFlowData(
        workflowData?.data?.result.map((w) => ({
          ...w,
          label: `${w.code}-${w.description}`,
          value: w._id,
        }))
      );

      setBrandData(
        brandData?.data?.result.map((w) => ({
          ...w,
          label: w.name,
          value: w._id,
        }))
      );

      setOwnerData(
        ownerData?.data?.result.map((l) => ({
          ...l,
          label: l.name,
          value: l._id,
        }))
      );

      entryFormik.setFieldValue("ownerGroup", "");
      if (ownerData?.data?.result?.length === 1) {
        entryFormik.setFieldValue(
          "ownerGroup",
          ownerData?.data?.result[0]?._id
        );
      }

      setStockTypes(
        stockTypeData?.data?.result?.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );

      setSubFamilies(
        subFamilyData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setItemDescription(
        itemDescriptionData.data.result.map((s) => ({
          ...s,
          label: s.description,
          value: s.description,
        }))
      );
      setStorageCodes(
        storageCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setPalletCodes(
        palletCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setCurrentConditionCodes(
        currentConditionCodeData.data.result.map((s) => ({
          ...s,
          label: `${s.code}-${s.description}`,
          value: s._id,
        }))
      );

      setPackaging(
        packagingData.data.result.map((s) => ({
          ...s,
          label: s.title,
          value: s._id,
        }))
      );

      setDamageCode(
        damageCodeData.data.result.map((s) => ({
          ...s,
          label: `${s.code}-${s.description}`,
          value: s._id,
        }))
      );
      setOrderTypeData(
        orderTypeData?.data?.result.map((o) => ({
          ...o,
          label: o.name,
          value: o._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getOwnerCodes = async () => {
    try {
      const { data } = await get(URLS.ownerCode.list, {
        params: { ownerGroup: entryFormik.values.ownerGroup },
      });
      setOwnerCodeData(
        data?.result.map((c) => ({
          ...c,
          label: c.name,
          value: c._id,
        }))
      );

      if (data.result?.length === 1) {
        entryFormik.setFieldValue("ownerCode", data.result[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (entryFormik.values.warehouseCode) {
      getData();
    }
  }, [entryFormik.values.warehouseCode]);

  useEffect(() => {
    const selectedWarehouse = warehouseList.find(
      (w) => w.value === entryFormik.values.warehouseCode
    );
    entryFormik.setFieldValue("warehouseName", selectedWarehouse?.name);
  }, [entryFormik.values.warehouseCode, warehouseList]);

  useEffect(() => {
    if (entryFormik.values.ownerGroup) {
      entryFormik.setFieldValue("ownerCode", "");
      getOwnerCodes();
    } else {
      setOwnerCodeData([]);
      entryFormik.setFieldValue("ownerCode", "");
    }
  }, [entryFormik.values.ownerGroup]);

  useEffect(() => {
    if (entryFormik.values.warehouseCode) {
      getFormDetails();
      getItemFormDetails();
    }
  }, [entryFormik.values.warehouseCode]);

  const handleOnFieldAdd = () => {
    if (entryFormik.values.warehouseCode) {
      getFormDetails();
    }
  };

  const handleTabChange = (e, newValue) => setSelectValue(newValue);

  const handleChange = (e, id, repeat) => {
    let name, value;

    if (e && e.target) {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = "expiryDate";
      value = e;
    }

    if (repeat) {
      setFormData(
        formData.map((fd, i) => (fd.id >= id ? { ...fd, [name]: value } : fd))
      );
    } else {
      setFormData(
        formData.map((fd, i) => (fd.id === id ? { ...fd, [name]: value } : fd))
      );
    }
  };

  // edit data actions
  const handleCustomInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields(
      formFields?.map((f) => (f.inputLabel === name ? { ...f, value } : f))
    );
  };

  // added new function
  const handleRemoveFile = (fileName, id) => {
    let form = formData.find((fd) => fd.id === id);
    let files = form.images.filter((f) => f.name !== fileName);
    setFormData(
      formData.map((fd, i) => (fd.id === id ? { ...fd, images: files } : fd))
    );
  };

  // const handleChangeFiles = (e, id) => {
  //   const { files } = e.target;

  //   let fileList = [];

  //   if (files.length > 0) {
  //     for (let i = 0; i < files.length; i++) {
  //       const file = files[i];

  //       fileList.push(file);
  //     }

  //     setFormData(
  //       formData.map((fd, i) =>
  //         fd.id === id ? { ...fd, images: fileList } : fd
  //       )
  //     );
  //   } else {
  //     console.log("No files selected");
  //   }
  // };

  const handleChangeFiles = (e, id) => {
    const { files } = e.target;

    let newFiles = Array.from(files);

    setFormData(
      formData.map((fd) => {
        if (fd.id === id) {
          const updatedFiles = fd.images.map((img) => img.name);
          const uniqueFiles = newFiles.filter(
            (file) => !updatedFiles.includes(file.name)
          );

          return { ...fd, images: [...fd.images, ...uniqueFiles] };
        }
        return fd;
      })
    );
    e.target.value = null;
  };

  const handleCustomInputChangeItem = (e, id) => {
    const { name, value } = e.target;
    setFormData(
      formData.map((f, index) =>
        f.id >= id
          ? {
              ...f,
              meta: f.meta.map((m) =>
                m.inputLabel === name ? { ...m, value } : m
              ),
            }
          : f
      )
    );
  };
  const handleOnFieldAddItem = () => {
    if (entryFormik.values.warehouseCode) {
      getItemFormDetails();
    }
  };

  const handleViewClick = (data) => {
    setEntryToEdit({ ...data, warehouseCode: data.warehouse });
    setSelectValue(2);
  };
  const handleDelete = async (id, reason) => {
    try {
      const res = await del(URLS.inboundEntry.delete + "/" + id, {
        params: {
          reasonForDelete: reason,
        },
      });
      const filtered = inboundEntries.filter((item) => item._id !== id);
      setInboundEntries(filtered);
    } catch (error) {
      console.error(error);
    }
  };
  const downloadSampleFile = async () => {
    try {
      setGettingSample(true);
      const { data } = await post(
        URLS.inboundEntry.downloadSampleFile,
        {
          category: "faulty",
        },
        {
          responseType: "blob",
        }
      );

      downloadExcel(data, "Bulk Item");
      setGettingSample(false);
    } catch (error) {
      console.log(error);
      setGettingSample(false);
    }
  };

  const handledBulkUpload = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    const receivedDate = dayjs(
      new Date(entryFormik.values.receivedDate)
    ).format("YYYY-MM-DD HH:mm:ss");
    const incomingDate = dayjs(
      new Date(entryFormik.values.incomingDate)
    ).format("YYYY-MM-DD HH:mm:ss");
    formData.append(
      "receivedDate",
      entryFormik.values.receivedDate ? receivedDate : null
    );
    formData.append(
      "incomingDate",
      entryFormik.values.incomingDate ? incomingDate : null
    );
    formData.append("gmsReferenceCode", entryFormik.values.gmsReferenceCode);
    formData.append("entryMenu", entryFormik.values.entryMenu);
    formData.append("warehouseCode", entryFormik.values.warehouseCode);
    formData.append("warehouseName", entryFormik.values.warehouseName);
    formData.append(
      "receivedDocketNumber",
      entryFormik.values.receivedDocketNumber
    );
    formData.append("eWayBillNumber", entryFormik.values.eWayBillNumber);
    formData.append(
      "incomingInvoiceNumber",
      entryFormik.values.incomingInvoiceNumber
    );
    formData.append("dcNumber", entryFormik.values.dcNumber);
    formData.append("poNumber", entryFormik.values.poNumber);
    formData.append("numberOfBoxes", entryFormik.values.numberOfBoxes);
    formData.append("sku", entryFormik.values.sku);
    formData.append("ownerGroup", entryFormik.values.ownerGroup);
    formData.append("ownerCode", entryFormik.values.ownerCode);
    formData.append("ownerCategory", entryFormik.values.ownerCategory);
    formData.append("workflowCode", entryFormik.values.workflowCode);
    formData.append("remarks", entryFormik.values.remarks);
    formData.append("meta", JSON.stringify(formFields));
    file.forEach((file) => {
      formData.append("file", file);
    });

    try {
      setBulkUploadLoader(true);
      const { data } = await post(URLS.inboundEntry.bulkUploadFile, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (data) {
        setInboundEntries(
          data.result.map((e) => ({ ...e, totalItems: e.length }))
        );
        getInboundEntries();
        entryFormik.resetForm();
        entryFormik.setFieldValue(
          "gmsReferenceCode",
          `GMS${generateRandomNumber(10)}`
        );
        handleClose();
        setBulkUploadLoader(false);
      }
      setBulkUploadLoader(false);
    } catch (error) {
      console.error(error);
      setBulkUploadLoader(false);
    }
  };
  const handleOpenBulkClick = () => {
    setOpenBulk(true);
  };
  const handleClose = () => {
    setOpenBulk(false);
    setFile([]);
  };
  const hasAllRequiredValues = (values) => {
    const requiredKeys = [
      "gmsReferenceCode",
      "entryMenu",
      "warehouseCode",
      "warehouseName",
      "incomingDate",
      "receivedDocketNumber",
      "eWayBillNumber",
      "incomingInvoiceNumber",
      "dcNumber",
      "poNumber",
      "numberOfBoxes",
      "sku",
      "ownerGroup",
      "ownerCode",
      // "ownerCategory",
      "workflowCode",
    ];

    return requiredKeys.every((key) => values[key]);
  };
  return (
    <>
      {value === 1 && <DashBoardPageHeaderPage title="Inbound Entry" />}

      {entryDone ? (
        <PageHeader
          title="Add Items"
          showAdd={false}
          collection="New item"
          modelName="item"
          onFieldAdd={handleOnFieldAddItem}
          module="Inbound process"
          showAddField={value != 2}
          showCustomButtonTitle={true}
        />
      ) : (
        <PageHeader
          title="Faulty Entry"
          showAdd={false}
          collection="Faulty item"
          modelName="inboundEntry"
          onFieldAdd={handleOnFieldAdd}
          module="Inbound process"
          showAddField={value != 2}
          showCustomButtonTitle={true}
        />
      )}

      <TabList
        onChange={handleTabChange}
        value={value}
        labels={
          entryToEdit && value === 2
            ? ["Add", "Entries", "Edit"]
            : ["Add", "Entries"]
        }
      />
      <TabPanel index={0} value={value}>
        {!entryDone ? (
          <>
            <Box component="form">
              <Grid rowSpacing={1} columnSpacing={2} container>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="gmsReferenceCode"
                    label="Gms Reference Code"
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormDatePicker
                    formik={entryFormik}
                    label="Received Date"
                    name="receivedDate"
                    required={true}
                  />
                </Grid>

                <Grid xs={12} sm={6} md={4} item>
                  <FormSelect
                    formik={entryFormik}
                    label="Warehouse code"
                    name="warehouseCode"
                    options={warehouseList}
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="warehouseName"
                    disabled={true}
                    label="Warehouse name"
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormDatePicker
                    formik={entryFormik}
                    label="Incoming Date"
                    name="incomingDate"
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="receivedDocketNumber"
                    label="Received docket number"
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="eWayBillNumber"
                    label="E-Way Bill number"
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="incomingInvoiceNumber"
                    label="Incoming invoice number"
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="dcNumber"
                    label="DC number"
                    required={true}
                  />
                </Grid>

                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="poNumber"
                    label="PO number"
                    required={true}
                  />
                </Grid>

                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="number"
                    formik={entryFormik}
                    name="numberOfBoxes"
                    label="Number of boxes"
                    required={true}
                  />
                </Grid>

                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="number"
                    formik={entryFormik}
                    name="sku"
                    label="SKU"
                    required={true}
                  />
                </Grid>

                <Grid xs={12} sm={6} md={4} item>
                  <FormSelect
                    formik={entryFormik}
                    name="ownerGroup"
                    label="Owner group"
                    options={ownerData}
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormSelect
                    type="text"
                    formik={entryFormik}
                    name="ownerCode"
                    label="Owner code"
                    options={ownerCodeData}
                    required={true}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="ownerCategory"
                    label="Owner category"
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormSelect
                    type="text"
                    formik={entryFormik}
                    name="workflowCode"
                    label="Workflow code"
                    required={true}
                    options={workFlowData}
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                  <FormInput
                    type="text"
                    formik={entryFormik}
                    name="remarks"
                    label="Remarks"
                  />
                </Grid>
                {formFields
                  ? formFields?.map((f, i) => (
                      <Grid key={i} item xs={12} sm={6} md={4}>
                        <CustomInput
                          onChange={handleCustomInputChange}
                          name={f.inputLabel}
                          value={f.value}
                          required={f.isRequired}
                          label={f.inputLabel}
                          type={f.inputType}
                        />
                      </Grid>
                    ))
                  : null}
                <Grid xs={12} sm={6} md={4} item>
                  <FileSelect
                    selectedFiles={entryFiles}
                    setSelectedFiles={setEntryFiles}
                    name="entryFiles"
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: 2,
                  margin: "20px 0",
                }}
              >
                <LoadingButton
                  loading={gettingSample}
                  onClick={downloadSampleFile}
                  variant="contained"
                  size="small"
                  startIcon={<DownloadRoundedIcon />}
                >
                  Get Sample File
                </LoadingButton>

                {entryFormik.values &&
                  hasAllRequiredValues(entryFormik.values) && (
                    <Button
                      onClick={handleOpenBulkClick}
                      variant="contained"
                      size="small"
                      startIcon={<AddIcon />}
                    >
                      Bulk Upload
                    </Button>
                  )}

                <Button
                  onClick={handleNextClick}
                  variant="contained"
                  size="small"
                >
                  Next
                </Button>
              </Box>
            </Box>

            <Dialog
              open={openBulk}
              onClose={handleClose}
              maxWidth={false}
              sx={{
                "& .MuiDialog-paper": {
                  width: "400px",
                  maxWidth: "100%",
                },
              }}
            >
              <DialogTitle>Add Bulk</DialogTitle>
              <Divider />

              <Grid
                rowSpacing={1}
                columnSpacing={2}
                container
                component={"form"}
                onSubmit={handledBulkUpload}
                sx={{ p: 1 }}
              >
                <Grid xs={12} md={12} lg={12} item>
                  <FileSelect
                    name={"file"}
                    selectedFiles={file}
                    setSelectedFiles={setFile}
                    accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
                  />
                </Grid>
                <Grid
                  xs={12}
                  md={12}
                  lg={12}
                  item
                  sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    size="small"
                    type="submit"
                    loading={bulkUploadLoader}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </Dialog>
          </>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark"
                        ? theme.palette.primary.dark
                        : theme.palette.primary.light,
                  }}
                >
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell align="center">Item Category</TableCell>
                    <TableCell align="center">Stock Type</TableCell>
                    <StyledTableCell align="center">Order Type</StyledTableCell>
                    <TableCell align="center">MSN</TableCell>
                    <TableCell align="center">Expiry Date</TableCell>
                    <TableCell align="center">Sub-family name</TableCell>
                    <TableCell align="center">Item description</TableCell>
                    <TableCell align="center">Item code</TableCell>
                    <TableCell align="center">
                      Manufacturer part number
                    </TableCell>
                    <TableCell align="center">Brand name</TableCell>
                    <TableCell align="center">Storage code</TableCell>
                    <TableCell align="center">Pallet code</TableCell>
                    <TableCell align="center">Current condition code</TableCell>
                    <TableCell align="center">Damage code</TableCell>
                    <TableCell align="center">Current activity code</TableCell>

                    <StyledTableCell align="center">Packaging</StyledTableCell>
                    <TableCell align="center">Configuration OS</TableCell>
                    {itemFormFields?.map((d, i) => (
                      <StyledTableCell key={i} align="center">
                        {d?.inputLabel}
                      </StyledTableCell>
                    ))}
                    <TableCell align="center">Remarks</TableCell>
                    <TableCell sx={{ minWidth: "200px" }} align="center">
                      Image
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...formData]
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((fd, i) => (
                      <TableRow key={fd.id}>
                        <TableCell align="center" sx={{ minWidth: "80px" }}>
                          {fd.id}
                        </TableCell>
                        <StyledTableCell align="center">
                          <Typography>Faulty</Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomSelect
                            options={stockTypes}
                            name="stockType"
                            label="Stock Type"
                            value={fd.stockType}
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomSelect
                            options={orderTypeData}
                            name="orderType"
                            label="Order Type"
                            value={fd.orderType}
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomInput
                            name="msn"
                            value={fd.msn?.toUpperCase()}
                            label="MSN"
                            required={true}
                            ref={(el) => (msnRefs.current[i] = el)}
                            onKeyDown={(e) => handleKeyDown(e, i)}
                            onChange={(e) => handleChange(e, fd.id)}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              sx={{
                                "& .MuiInputBase-input": {
                                  height: "8px",
                                },
                                marginTop: "16px",
                                width: "100%",
                              }}
                              name="expiryDate"
                              variant="outlined"
                              size="small"
                              label="Expiry Date"
                              slotProps={{
                                textField: {
                                  InputLabelProps: { shrink: true },
                                },
                              }}
                              views={["year", "month", "day"]}
                              format="DD-MM-YYYY"
                              value={
                                fd.expiryDate ? dayjs(fd.expiryDate) : null
                              }
                              onChange={(e) => handleChange(e, fd.id, "repeat")}
                            />
                          </LocalizationProvider>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomSelect
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                            options={subFamilies}
                            name="subFamily"
                            label="Sub-Family Name"
                            value={fd.subFamily}
                            required={true}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomSelect
                            name="itemDescription"
                            value={fd.itemDescription}
                            options={itemDescription}
                            label="Item Description"
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomInput
                            name="itemCode"
                            value={fd.itemCode}
                            label="Item Code"
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomInput
                            name="manufacturerPartNumber"
                            value={fd.manufacturerPartNumber}
                            label="Manufacturer Part Number"
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <CustomSelect
                            options={brandData}
                            name="brandName"
                            label="Brand Name"
                            value={fd.brandName}
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomSelect
                            options={storageCodes}
                            name="storageCode"
                            label="Storage Code"
                            value={fd.storageCode}
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomSelect
                            options={palletCodes}
                            name="palletCode"
                            label="Pallet Code"
                            value={fd.palletCode}
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomSelect
                            options={currentConditionCodes}
                            name="conditionCode"
                            label="Current Condition Code"
                            value={fd.conditionCode}
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomSelect
                            options={damageCode}
                            name="damageCode"
                            label="Damage Code"
                            value={fd.damageCode}
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomSelect
                            options={activityCodes?.filter(
                              (a) => !a.canBeMovedToStock
                            )}
                            name="activityCode"
                            label="Activity Code"
                            value={fd.activityCode}
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <CustomSelect
                            options={packaging}
                            name="packaging"
                            label="Packaging"
                            value={fd.packaging}
                            required={true}
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CustomInput
                            name="configurationOs"
                            value={fd.configurationOs}
                            label="Configuration OS"
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>

                        {itemFormFields
                          ? fd.meta?.map((f, index) => (
                              <StyledTableCell align="center" key={index}>
                                <CustomInput
                                  onChange={(e) =>
                                    handleCustomInputChangeItem(e, fd.id)
                                  }
                                  name={f.inputLabel}
                                  value={f.value}
                                  required={f.isRequired}
                                  label={f.inputLabel}
                                  type={f.inputType}
                                />
                              </StyledTableCell>
                            ))
                          : null}
                        <StyledTableCell align="center">
                          <CustomInput
                            name="remarks"
                            value={fd.remarks}
                            label="Remarks"
                            onChange={(e) => handleChange(e, fd.id, "repeat")}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <FileSelect
                            name={`images${i}`}
                            onChange={(e) => handleChangeFiles(e, fd.id)}
                            customOnChange={true}
                            selectedFiles={fd.images}
                            onRemove={(fileName) =>
                              handleRemoveFile(fileName, fd.id)
                            }
                          />
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={formData ? (formData.length ? formData.length : 0) : 0}
              page={page}
              rowsPerPageOptions={ROWS_PER_PAGE}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                my: 2,
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Stack sx={{ alignSelf: "flex-end" }} direction="row" spacing={2}>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => setEntryDone(false)}
                >
                  Back
                </Button>
                <LoadingButton
                  loading={addingEntry}
                  size="small"
                  variant="contained"
                  onClick={entryFormik.handleSubmit}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Box>
          </>
        )}
      </TabPanel>
      <TabPanel index={1} value={value}>
        {loading ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "60px",
            }}
          >
            <PuffLoader size={40} color="#14532d" />
          </Box>
        ) : (
          <>
            {" "}
            <Grid
              container
              spacing={2}
              my={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {/* <BulkUpdate
                openBulkUpdate={openBulkUpdate}
                setOpenBulkUpdate={setOpenBulkUpdate}
                warehouseData={warehouseList}
                setInboundEntries={setInboundEntries}
                getInboundEntries={getInboundEntries}
                category="faulty"
              /> */}
              <Grid item xs={12} md={6} lg={3}>
                <CustomSearch
                  data={inboundEntries}
                  setSearch={setSearch}
                  setSearchFilter={setSearchFilter}
                  search={search}
                  placeholder="Reference Code"
                  keys={["gmsReferenceCode"]}
                />
              </Grid>
            </Grid>{" "}
            <CustomTable
              actions={["view", "delete"]}
              bodyDataModal="entries"
              tableKeys={inboundEntriesTableKeys}
              isDataReady={true}
              bodyData={search ? searchFilter : inboundEntries}
              onViewClick={handleViewClick}
              onDeleteClick={handleDelete}
              module="Inbound process"
            />
          </>
        )}
      </TabPanel>
      <TabPanel index={2} value={value}>
        <UpdateFaultyItem
          dataToEdit={entryToEdit}
          setEntryToEdit={setEntryToEdit}
          setSelectValue={setSelectValue}
          onUpdate={getInboundEntries}
        />
      </TabPanel>
    </>
  );
}
