import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Box,
  Grid,
  Table,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  TableRow,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  TablePagination,
  Tooltip,
  Divider,
  Stack,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import FormDatePicker from "../../forms/FormDatePicker";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { toast } from "react-toastify";

// custom imports
import FormSelect from "../../forms/FormSelect";
import CustomInput from "../../components/SecondaryForms/CustomInput";
import CustomSelect from "../../components/SecondaryForms/CustomSelect";
import ViewOrUpdateInboundEntryFiles from "../../components/ViewOrUpdateInboundEntryFiles";
import FormInput from "../../forms/FormInput";
import { ROWS_PER_PAGE } from "../../utils/utilities";

// services
import { get, put } from "../../services/apiMethods";
import URLS from "../../services/urlConstants";

// utilities

import { Close, Search } from "@mui/icons-material";
import ShowComponent from "../../components/ShowComponent";
import { PuffLoader } from "react-spinners";
import FormDialog from "../../forms/FormDialog";
import ExpiryDateDialog from "./ExpiryDateDialog";

import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";

const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    width: "600px",
    maxWidth: "100%",
    color: "#000",
  },
});

const StyledTableCell = styled(TableCell)(() => ({
  minWidth: "200px",
}));

const MuiPaper = styled(Paper)(() => ({
  padding: "20px",
}));

export default function UpdateNewItem({
  dataToEdit,
  onUpdate = () => {},
  setSelectValue = () => {},
  setEntryToEdit = () => {},
}) {
  const [addingEntry, setAddingEntry] = useState(false);
  const [inboundToEdit, setInboundToEdit] = useState(dataToEdit);

  const [formData, setFormData] = useState([]);
  const [formFields, setFormFields] = useState(null);
  const [itemFormFields, setItemFormFields] = useState(null);
  const [searchFilter, setSearchFilter] = useState([]);
  const [search, setSearch] = useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // master Data
  const [stockTypes, setStockTypes] = useState([]);
  const [subFamilies, setSubFamilies] = useState([]);
  const [orderTypeData, setOrderTypeData] = useState([]);
  const [storageCodes, setStorageCodes] = useState([]);
  const [palletCodes, setPalletCodes] = useState([]);
  const [currentConditionCodes, setCurrentConditionCodes] = useState([]);
  const [ownerData, setOwnerData] = useState([]);
  const [ownerCodeData, setOwnerCodeData] = useState([]);
  const [warehouseData, setWarehouseData] = useState([]);
  const [workFlowData, setWorkFlowData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDateModal, setOpenDateModal] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);

  const theme = useTheme();
  let fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = (images) => {
    setImages(images);

    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const getWarehouseDetails = async () => {
    try {
      const { data } = await get(URLS.warehouse.list);
      setWarehouseData(
        data?.result.map((w) => ({
          ...w,
          label: w.name,
          value: w._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getWarehouseDetails();
  }, []);

  const getMasterData = async () => {
    try {
      const stockPromise = get(URLS.stockType.list);
      const subFamilyPromise = get(URLS.subFamily.list);
      const storageCodePromise = get(URLS.storageCode.list);
      const palletCodeCodePromise = get(URLS.palletCode.list);
      const currentConditionCodePromise = get(URLS.conditionCode.list);
      const ownerDataPromise = get(URLS.ownerGroup.list);

      const brandDataPromise = get(URLS.brand.list);
      const workflowDataPromise = get(URLS.workFlowCode.list);
      const orderTypeDataPromise = get(URLS.orderType.list);

      const [
        stockTypeData,
        subFamilyData,
        storageCodeData,
        palletCodeData,
        currentConditionCodeData,
        ownerData,
        brandData,
        workflowData,
        orderTypeData,
      ] = await Promise.all([
        stockPromise,
        subFamilyPromise,
        storageCodePromise,
        palletCodeCodePromise,
        currentConditionCodePromise,
        ownerDataPromise,
        brandDataPromise,
        workflowDataPromise,
        orderTypeDataPromise,
      ]);

      setOrderTypeData(
        orderTypeData?.data?.result.map((o) => ({
          ...o,
          label: o.name,
          value: o._id,
        }))
      );

      setWorkFlowData(
        workflowData?.data?.result.map((w) => ({
          ...w,
          label: `${w.code}-${w.description}`,
          value: w._id,
        }))
      );

      setBrandData(
        brandData?.data?.result.map((w) => ({
          ...w,
          label: w.name,
          value: w._id,
        }))
      );

      setOwnerData(
        ownerData?.data?.result.map((l) => ({
          ...l,
          label: l.name,
          value: l._id,
        }))
      );

      setStockTypes(
        stockTypeData?.data?.result?.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );

      setSubFamilies(
        subFamilyData.data.result.map((s) => ({
          ...s,
          label: s.name,
          value: s._id,
        }))
      );
      setStorageCodes(
        storageCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setPalletCodes(
        palletCodeData.data.result.map((s) => ({
          ...s,
          label: s.code,
          value: s._id,
        }))
      );
      setCurrentConditionCodes(
        currentConditionCodeData.data.result.map((s) => ({
          ...s,
          label: `${s.code}-${s.description}`,
          value: s._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getOwnerCodes = async () => {
    try {
      const { data } = await get(URLS.ownerCode.list, {
        params: { ownerGroup: entryFormik.values.ownerGroup },
      });
      setOwnerCodeData(
        data?.result.map((c) => ({
          ...c,
          label: c.name,
          value: c._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const [itemFormFields, formDetails, entryData] = await Promise.all([
        get(URLS.forms.read, {
          params: {
            collectionName: "item",
            warehouse: dataToEdit?.warehouseCode?._id,
          },
        }),
        get(URLS.forms.read, {
          params: {
            collectionName: "inboundEntry",
            warehouse: dataToEdit?.warehouseCode?._id,
          },
        }),
        get(URLS.inboundEntry.read + "/" + dataToEdit?._id),
      ]);

      setItemFormFields(
        itemFormFields.data.result ? itemFormFields.data.result.fields : null
      );
      setFormFields(
        formDetails.data.result
          ? formDetails.data.result.fields.map((f) => ({
              ...f,
              value:
                dataToEdit.meta?.find((m) => m.inputLabel === f.inputLabel)
                  ?.value || "",
            }))
          : null
      );
      setLoading(false);

      setInboundToEdit(entryData.data.result);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dataToEdit) {
      getData();
    }
  }, [dataToEdit]);

  const handleSubmit = async (values) => {
    if (formFields) {
      for (let field of formFields) {
        if (field.isRequired && !field.value) {
          return toast.error(
            `Please fill the required field : ${field.inputLabel}`
          );
        }
      }
    }

    try {
      setAddingEntry(true);

      const receivedDate = dayjs(values.receivedDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      const incomingDate = dayjs(values.incomingDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      const payload = {
        ...values,
        meta: formFields || [],
        receivedDate: values.receivedDate ? receivedDate : null,
        incomingDate: incomingDate,
      };

      // delete payload.incomingDate;

      const { data } = await put(
        URLS.inboundEntry.update + "/" + dataToEdit._id,
        payload
      );
      setSelectValue(1);
      onUpdate();
      entryFormik.resetForm();
    } catch (error) {
      console.log(error);
    }
    setAddingEntry(false);
  };

  const entryFormik = useFormik({
    initialValues: {
      gmsReferenceCode: inboundToEdit?.gmsReferenceCode,
      entryMenu: inboundToEdit?.entryMenu,
      receivedDate: inboundToEdit
        ? inboundToEdit.receivedDate
          ? dayjs(inboundToEdit.receivedDate)
          : null
        : null,
      incomingDate: inboundToEdit
        ? inboundToEdit.incomingDate
          ? dayjs(inboundToEdit.incomingDate)
          : null
        : null,

      warehouseCode: inboundToEdit ? inboundToEdit.warehouseCode?._id : "",
      warehouseName: inboundToEdit ? inboundToEdit.warehouseCode?.name : "",

      receivedDocketNumber: inboundToEdit
        ? inboundToEdit.receivedDocketNumber
        : "",
      eWayBillNumber: inboundToEdit ? inboundToEdit.eWayBillNumber : "",
      incomingInvoiceNumber: inboundToEdit
        ? inboundToEdit.incomingInvoiceNumber
        : "",
      dcNumber: inboundToEdit?.dcNumber,
      poNumber: inboundToEdit?.poNumber,
      numberOfBoxes: inboundToEdit ? inboundToEdit.numberOfBoxes : "",
      sku: inboundToEdit ? inboundToEdit.sku : formData.length,
      ownerGroup: inboundToEdit ? inboundToEdit.ownerGroup?._id : "",
      ownerCode: inboundToEdit ? inboundToEdit.ownerCode?._id : "",
      ownerCategory: inboundToEdit ? inboundToEdit.ownerCategory : "",
      workflowCode: inboundToEdit ? inboundToEdit.workflowCode?._id : "",
      remarks: inboundToEdit ? inboundToEdit.remarks : "",
    },
    onSubmit: (values) => handleSubmit(values),
    enableReinitialize: true,
  });

  useEffect(() => {
    getMasterData();
  }, []);

  useEffect(() => {
    if (entryFormik.values.ownerGroup) {
      getOwnerCodes();
    }
  }, [entryFormik.values.ownerGroup]);

  useEffect(() => {
    const formData = [];

    if (inboundToEdit?.items?.length) {
      for (let item of inboundToEdit.items) {
        formData.push({
          _id: item._id,
          itemCategory: item.itemCategory,
          stockType: item.stockType,
          orderType: item.orderType,
          msn: item.msn,
          subFamily: item.subFamily,
          itemDescription: item.itemDescription,
          itemCode: item.itemCode,
          manufacturerPartNumber: item.manufacturerPartNumber,
          brandName: item.brandName,
          storageCode: item.storageCode,
          palletCode: item.palletCode,
          conditionCode: item.conditionCode,
          configurationOs: item.configurationOs,
          remarks: item.remarks,
          expiryDate: item.expiryDate,
          images: item.images,
          meta: itemFormFields?.map((f) => ({
            ...f,
            value:
              item.meta?.find((fi) => fi.inputLabel === f.inputLabel)?.value ||
              "",
          })),
        });
      }
    }

    setFormData(formData);
    setSearchFilter(formData);
  }, [inboundToEdit, itemFormFields]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    setFormData(
      formData.map((fd, i) => (i === index ? { ...fd, [name]: value } : fd))
    );
  };

  // edit data actions
  const handleCustomInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields(
      formFields?.map((f) => (f.inputLabel === name ? { ...f, value } : f))
    );
  };

  const handleCustomInputChangeItem = (e, i) => {
    const { name, value } = e.target;
    setFormData(
      formData.map((f, index) =>
        index === i
          ? {
              ...f,
              meta: f.meta.map((m) =>
                m.inputLabel === name ? { ...m, value } : m
              ),
            }
          : f
      )
    );
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setPage(0);
    if (value.trim() !== "") {
      formData.length > 0 &&
        setSearchFilter(
          formData.filter((ele) =>
            ele.msn.toLowerCase().includes(value.toLowerCase().trim())
          )
        );
    } else {
      setSearchFilter(formData);
    }
  };

  const formik = useFormik({
    initialValues: {},
  });

  console.log(searchFilter, "================");

  return (
    <>
      {loading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "60px",
          }}
        >
          <PuffLoader size={40} color="#14532d" />
        </Box>
      ) : (
        <>
          <MuiPaper component="form">
            <Grid rowSpacing={1} columnSpacing={2} container>
              <Grid xs={12} sm={6} md={4} item>
                <FormInput
                  type="text"
                  formik={entryFormik}
                  name="gmsReferenceCode"
                  disabled={true}
                  label="Gms Reference Code"
                  required={true}
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormDatePicker
                  formik={entryFormik}
                  label="Received Date"
                  name="receivedDate"
                />
              </Grid>

              <Grid xs={12} sm={6} md={4} item>
                <FormSelect
                  formik={entryFormik}
                  label="Warehouse code"
                  name="warehouseCode"
                  options={warehouseData.map((warehouse) => ({
                    label: warehouse.code,
                    value: warehouse._id,
                  }))}
                  disabled={true}
                  required={true}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    const selectedWarehouse = warehouseData.find(
                      (warehouse) => warehouse._id === selectedValue
                    );
                    if (selectedWarehouse) {
                      entryFormik.setFieldValue(
                        "warehouseCode",
                        selectedWarehouse._id
                      );
                      entryFormik.setFieldValue(
                        "warehouseName",
                        selectedWarehouse.name
                      );
                    }
                  }}
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormInput
                  type="text"
                  formik={entryFormik}
                  name="warehouseName"
                  disabled={true}
                  label="Warehouse name"
                  required={true}
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormDatePicker
                  required={true}
                  formik={entryFormik}
                  label="Incoming Date"
                  name="incomingDate"
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormInput
                  type="text"
                  formik={entryFormik}
                  name="receivedDocketNumber"
                  label="Received docket number"
                  required={true}
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormInput
                  type="text"
                  formik={entryFormik}
                  name="eWayBillNumber"
                  label="E-Way Bill number"
                  required={true}
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormInput
                  type="text"
                  formik={entryFormik}
                  name="incomingInvoiceNumber"
                  label="Incoming invoice number"
                  required={true}
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormInput
                  type="text"
                  formik={entryFormik}
                  name="dcNumber"
                  label="DC number"
                  required={true}
                />
              </Grid>

              <Grid xs={12} sm={6} md={4} item>
                <FormInput
                  type="text"
                  formik={entryFormik}
                  name="poNumber"
                  label="PO number"
                  required={true}
                />
              </Grid>

              <Grid xs={12} sm={6} md={4} item>
                <FormInput
                  type="number"
                  formik={entryFormik}
                  disabled={true}
                  name="numberOfBoxes"
                  label="Number of boxes"
                  required={true}
                />
              </Grid>

              <Grid xs={12} sm={6} md={4} item>
                <FormInput
                  type="number"
                  formik={entryFormik}
                  name="sku"
                  disabled={true}
                  label="SKU"
                  required={true}
                />
              </Grid>

              <Grid xs={12} sm={6} md={4} item>
                <FormSelect
                  formik={entryFormik}
                  name="ownerGroup"
                  disabled={true}
                  label="Owner Group"
                  options={ownerData}
                  required={true}
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormSelect
                  type="text"
                  formik={entryFormik}
                  name="ownerCode"
                  disabled={true}
                  label="Owner code"
                  options={ownerCodeData}
                  required={true}
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormInput
                  type="text"
                  formik={entryFormik}
                  name="ownerCategory"
                  label="Owner category"
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormSelect
                  type="text"
                  formik={entryFormik}
                  name="workflowCode"
                  label="Workflow code"
                  required={true}
                  options={workFlowData}
                />
              </Grid>
              <Grid xs={12} sm={6} md={4} item>
                <FormInput
                  type="text"
                  formik={entryFormik}
                  name="remarks"
                  label="Remarks"
                />
              </Grid>
              {formFields
                ? formFields?.map((f, i) => (
                    <Grid key={i} item xs={12} sm={6} md={4}>
                      <CustomInput
                        onChange={handleCustomInputChange}
                        name={f.inputLabel}
                        value={f.value}
                        required={f.isRequired}
                        label={f.inputLabel}
                        type={f.inputType}
                      />
                    </Grid>
                  ))
                : null}
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                xs={12}
                sm={6}
                md={4}
                item
              >
                <ViewOrUpdateInboundEntryFiles
                  inboundEntry={dataToEdit}
                  onUpdate={onUpdate}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 2,
                mt: 1,
              }}
            >
              <Button
                onClick={() => {
                  setEntryToEdit(null);
                  setSelectValue(1);
                }}
                size="small"
                variant="outlined"
              >
                Back
              </Button>
              {/* <ShowComponent module="Inbound Process" action={"update"}>
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  onClick={(e) => setOpenDateModal(true)}
                >
                  Update Expiry Date
                </Button>
              </ShowComponent> */}
              <ShowComponent module="Inbound process" action={"update"}>
                <LoadingButton
                  loading={addingEntry}
                  size="small"
                  variant="contained"
                  onClick={entryFormik.handleSubmit}
                >
                  Update Entry
                </LoadingButton>
              </ShowComponent>
            </Box>
          </MuiPaper>
          {/* ===== expiry date model */}
          <ExpiryDateDialog
            open={openDateModal}
            onClose={() => setOpenDateModal(false)}
            inboundId={inboundToEdit._id}
          />
          <Grid
            container
            spacing={2}
            my={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Grid item xs="auto">
              <TextField
                size="small"
                value={search}
                fullWidth
                onChange={handleSearch}
                label="Search by MSN"
                placeholder="Enter msn"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" type="submit">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <TableContainer sx={{ zoom: 0.8 }} component={Paper}>
            <Table>
              <TableHead
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.primary.dark
                      : theme.palette.primary.light,
                }}
              >
                <TableRow>
                  <StyledTableCell sx={{ minWidth: "80px" }} align="center">
                    S.No
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    Item Category
                  </StyledTableCell>

                  <StyledTableCell align="center">Expiry Date</StyledTableCell>
                  <StyledTableCell align="center">Stock Type</StyledTableCell>
                  <StyledTableCell align="center">Order Type</StyledTableCell>
                  <StyledTableCell align="center">MSN</StyledTableCell>
                  <StyledTableCell align="center">
                    Sub-family Name
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Item Description
                  </StyledTableCell>
                  <StyledTableCell align="center">Item Code</StyledTableCell>
                  <StyledTableCell align="center">
                    Manufacturer Part Number
                  </StyledTableCell>
                  <StyledTableCell align="center">Brand Name</StyledTableCell>
                  <StyledTableCell align="center">Storage Code</StyledTableCell>
                  <StyledTableCell align="center">Pallet Code</StyledTableCell>
                  <StyledTableCell align="center">
                    Current Condition Code
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    Configuration OS
                  </StyledTableCell>
                  {itemFormFields?.map((f, i) => (
                    <StyledTableCell key={i} align="center">
                      {f.inputLabel}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell align="center">
                    Inbound Remarks
                  </StyledTableCell>
                  <StyledTableCell align="center">Image</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchFilter
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((fd, i) => (
                    <TableRow key={i}>
                      <TableCell sx={{ minWidth: "80px" }} align="center">
                        {page * rowsPerPage + i + 1}
                      </TableCell>

                      <StyledTableCell align="center">
                        <Typography>
                          {fd?.itemCategory?.toUpperCase()}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {fd?.expiryDate
                          ? new Date(fd.expiryDate).toLocaleString("en-IN", {
                              timeZone: "Asia/Kolkata",
                            })
                          : "N/A"}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {fd.stockType
                          ? stockTypes.find((s) => s.value === fd.stockType)
                              ?.label
                          : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {fd.orderType
                          ? orderTypeData.find((s) => s.value === fd.orderType)
                              ?.label
                          : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {fd.msn?.toUpperCase() || "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {fd.subFamily
                          ? subFamilies.find((s) => s.value === fd.subFamily)
                              ?.label
                          : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {fd.itemDescription || "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {fd.itemCode || "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {fd.manufacturerPartNumber || "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {fd.brandName
                          ? brandData.find((s) => s.value === fd.brandName)
                              ?.label
                          : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {fd.storageCode
                          ? storageCodes.find((s) => s.value === fd.storageCode)
                              ?.label
                          : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {fd.palletCode
                          ? palletCodes.find((s) => s.value === fd.palletCode)
                              ?.label
                          : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {fd.conditionCode
                          ? currentConditionCodes.find(
                              (s) => s.value === fd.conditionCode
                            )?.label
                          : "N/A"}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {fd.configurationOs || "N/A"}
                      </StyledTableCell>

                      {itemFormFields
                        ? fd.meta?.map((f, index) => (
                            <StyledTableCell align="center" key={index}>
                              {f.value || "N/A"}
                            </StyledTableCell>
                          ))
                        : null}
                      <StyledTableCell align="center">
                        {fd.remarks || "N/A"}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <Tooltip title="View">
                          <IconButton
                            size="small"
                            onClick={() => handleOpen(fd?.images)}
                          >
                            <RemoveRedEyeRoundedIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>

                      {/* <StyledTableCell sx={{ minWidth: "80px" }} align="center">
                  <ViewOrUpdateItemFiles item={fd} onUpdate={onUpdateItem} />
                </StyledTableCell> */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {/* {!formData.length && !search.trim() && (
              <Typography variant="h6" my={2} textAlign={"center"}>
                No data found
              </Typography>
            )} */}
            {search.trim() && !searchFilter.length && (
              <Typography variant="h6" my={2} textAlign={"center"}>
                No data found
              </Typography>
            )}
          </TableContainer>
          <TablePagination
            component="div"
            count={searchFilter?.length}
            page={page}
            rowsPerPageOptions={ROWS_PER_PAGE}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <CustomDialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
          >
            <DialogTitle
              sx={{
                fontSize: "15px",
              }}
            >
              {images.length > 1 ? "View Images" : "View Image"}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 12,
                top: 8,
              }}
            >
              <Close />
            </IconButton>

            <Divider sx={{ mb: 2 }} />

            <DialogContent>
              <Grid container spacing={1}>
                {images.length === 0 ? (
                  <Grid item xs={12}>
                    <Typography align="center">
                      No data available at the moment
                    </Typography>
                  </Grid>
                ) : (
                  images.map((image, index) => (
                    <Grid item xs={12} sm={12} md={12} key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "8px",
                          borderRadius: "5px",
                          marginTop: "5px",
                          background: "#5fa5f661",
                          cursor: "pointer",
                        }}
                        onClick={() => window.open(image, "_blank")}
                      >
                        <Typography>{`File ${index + 1}`}</Typography>
                        <Stack direction="row" spacing={2}>
                          <IconButton
                            onClick={() => window.open(image, "_blank")}
                          >
                            <RemoveRedEyeRoundedIcon fontSize="small" />
                          </IconButton>
                        </Stack>
                      </Box>
                    </Grid>
                  ))
                )}
              </Grid>
              <Box sx={{ textAlign: "right", mt: 2 }}>
                <Button
                  size="small"
                  onClick={handleClose}
                  variant="contained"
                  color="error"
                >
                  Cancel
                </Button>
              </Box>
            </DialogContent>
          </CustomDialog>
        </>
      )}
    </>
  );
}
