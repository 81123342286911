import { Box, Button, Divider, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import { Dialog, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FileSelect from "../forms/FileSelect";
import { useFormik } from "formik";
import FormSelect from "../forms/FormSelect";
import URLS from "../services/urlConstants";
import { get, post } from "../services/apiMethods";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { toast } from "react-toastify";
import { downloadExcel } from "../utils/utilities";
import WarehouseContext from "../context/WarehouseContext";

export default function BulkHoldRelease({
  openBulkUpdate = false,
  setOpenBulkUpdate = () => {},
  setInboundEntries = () => {},
  getInboundEntries = () => {},
  category = "new",
  hold = true,
}) {
  const {
    selectedWarehouse,
    selectedOwnerCode,
    selectedOwnerGroup,
    allWarehouses,
    allOwnerCodes,
    allOwnerGroups,
  } = useContext(WarehouseContext);
  const [file, setFile] = useState([]);
  const [ownerGroup, setOwnerGroup] = useState([]);
  const [ownerCode, setOwnerCode] = useState([]);
  const [gettingSample, setGettingSample] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [bulkUpdateLoader, setBulkUpdateLoader] = useState(false);
  const [warehouseData, setWarehouseData] = useState([]);
  const getWarehouseDetails = async () => {
    try {
      const { data } = await get(URLS.warehouse.list);
      setWarehouseData(
        data?.result.map((w) => ({
          ...w,
          label: w.code,
          value: w._id,
        }))
      );

      if (allWarehouses?.length === 1) {
        entryFormik.setFieldValue("warehouseCode", data.result[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getWarehouseDetails();
  }, []);

  const getOwnerGroup = async () => {
    try {
      entryFormik.setFieldValue("ownerGroup", "");
      const { data } = await get(URLS.ownerGroup.list, {
        params: { warehouse: entryFormik.values.warehouseCode },
      });
      setOwnerGroup(
        data?.result.map((c) => ({
          ...c,
          label: c.name,
          value: c._id,
        }))
      );
      if (data.result?.length === 1) {
        entryFormik.setFieldValue("ownerGroup", data.result[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOwnerCodes = async () => {
    try {
      entryFormik.setFieldValue("ownerCode", "");
      const { data } = await get(URLS.ownerCode.list, {
        params: { ownerGroup: entryFormik.values.ownerGroup },
      });
      setOwnerCode(
        data?.result.map((c) => ({
          ...c,
          label: c.name,
          value: c._id,
        }))
      );

      if (data.result?.length === 1) {
        entryFormik.setFieldValue("ownerCode", data.result[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenBulkClick = () => {
    setOpenBulkUpdate(true);
  };
  const handleClose = () => {
    setOpenBulkUpdate(false);
    setFile([]);
    entryFormik.resetForm();
    setShowFile(false);
  };

  const downloadSampleFile = async () => {
    try {
      if (!entryFormik.values.warehouseCode) {
        return toast.error("Please select the warehouse");
      }

      if (!entryFormik.values.ownerGroup) {
        return toast.error("Please select the ownerGroup");
      }

      if (!entryFormik.values.ownerCode) {
        return toast.error("Please select the ownerCode");
      }
      setGettingSample(true);
      const { data, status } = await get(
        hold
          ? URLS.items.downloadBulkSampleHold
          : URLS.items.downloadBulkSampleRelease,
        {
          params: {
            warehouseId: entryFormik.values.warehouseCode,
            ownerCodeId: entryFormik.values.ownerCode,
            ownerGroupId: entryFormik.values.ownerGroup,
          },
          validateStatus: (status) => status < 500,
          responseType: "blob",
        }
      );

      if (status === 200) {
        downloadExcel(
          data,
          hold ? "Bulk Update Hold.xlsx" : "Bulk Release items.xlsx"
        );

        setShowFile(true);
      } else {
        const errorText = await new Response(data).text();
        toast.error(JSON.parse(errorText)?.message);
      }

      setGettingSample(false);
    } catch (error) {
      console.log(error);
      setGettingSample(false);
    }
  };

  const entryFormik = useFormik({
    initialValues: {
      warehouseCode: "",
      ownerGroup: "",
      ownerCode: "",
    },
    onSubmit: downloadSampleFile,
    validateOnBlur: false,
  });

  const handledBulkUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    file.forEach((file) => {
      formData.append("file", file);
    });

    try {
      setBulkUpdateLoader(true);
      const { data } = await post(
        hold ? URLS.items.bulkHoldItems : URLS.items.bulkReleaseItems,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (data) {
        setInboundEntries(
          data.result.map((e) => ({ ...e, totalItems: e.length }))
        );
        getInboundEntries();
      }
      entryFormik.resetForm();
      handleClose();
      setBulkUpdateLoader(false);
    } catch (error) {
      console.error(error);
      setBulkUpdateLoader(false);
    }
  };

  useEffect(() => {
    if (entryFormik.values.warehouseCode) {
      getOwnerGroup();
    }
  }, [entryFormik.values.warehouseCode]);

  useEffect(() => {
    if (entryFormik.values.ownerGroup) {
      entryFormik.setFieldValue("ownerCode", "");
      getOwnerCodes();
    } else {
      setOwnerCode([]);
      entryFormik.setFieldValue("ownerCode", "");
    }
  }, [entryFormik.values.ownerGroup]);

  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpenBulkClick}
        size="small"
        startIcon={<Add />}
      >
        {hold ? "Bulk Hold" : "Bulk Release"}
      </Button>

      <Dialog
        open={openBulkUpdate}
        onClose={handleClose}
        maxWidth={false}
        sx={{
          "& .MuiDialog-paper": {
            width: "500px",
            maxWidth: "100%",
          },
        }}
      >
        <DialogTitle> {hold ? "Bulk Hold" : "Bulk Release"}</DialogTitle>
        <Divider />

        <Grid
          rowSpacing={0}
          columnSpacing={1}
          container
          component={"form"}
          onSubmit={handledBulkUpload}
          sx={{ p: 1 }}
        >
          <Grid xs={12} sm={6} md={4} item>
            <FormSelect
              formik={entryFormik}
              label="Warehouse code"
              name="warehouseCode"
              options={warehouseData}
              required={true}
            />
          </Grid>

          <Grid xs={12} sm={6} md={4} item>
            <FormSelect
              formik={entryFormik}
              name="ownerGroup"
              label="Owner group"
              options={ownerGroup}
              required={true}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4} item>
            <FormSelect
              formik={entryFormik}
              name="ownerCode"
              label="Owner code"
              options={ownerCode}
              required={true}
            />
          </Grid>

          <Grid
            xs={12}
            md={12}
            lg={12}
            item
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {" "}
            <LoadingButton
              loading={gettingSample}
              onClick={downloadSampleFile}
              variant="contained"
              sx={{ mt: 2 }}
              disabled={
                !entryFormik.values.warehouseCode ||
                !entryFormik.values.ownerCode ||
                !entryFormik.values.ownerGroup
              }
              size="small"
              startIcon={<DownloadRoundedIcon />}
            >
              Get Sample File
            </LoadingButton>
          </Grid>
          {showFile && (
            <Grid xs={12} md={12} lg={12} item sx={{ width: "400px" }}>
              <FileSelect
                name={"file"}
                disabled={!entryFormik.values}
                selectedFiles={file}
                setSelectedFiles={setFile}
                accept=".xlsx, .xls, xlsm, .xlsb, .csv, .ods"
              />
            </Grid>
          )}

          <Grid
            xs={12}
            md={12}
            lg={12}
            item
            sx={{ display: "flex", justifyContent: "flex-end", gap: 1, mt: 2 }}
          >
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={handleClose}
            >
              Close
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              type="submit"
              disabled={file.length === 0}
              loading={bulkUpdateLoader}
            >
              Update
            </LoadingButton>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
